import React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { Table, TableRow, TableCell, TableHead } from '@material-ui/core';
import moment from 'moment';
import RealtimeDetailsForOrg from './RealtimeDetailsForOrg';
import { RealtimeRunningStreamsOrg } from './RealtimeRunningStreamsOrg';

interface Props {
  groupId: string;
}

interface RealtimeStream {
  _id: string;
  trintId: string;
  userId: string;
  status: string;
  config: {
    name: string;
    type: string;
    feedUrl: string;
  };
  controllerId: string;
  created: Date;
}

const REALTIME_ORG_QUERY = gql`
  query realtimeStreamsOrg($groupId: String!) {
    realtimeStreamsOrg(groupId: $groupId) {
      _id
      trintId
      userId
      status
      config {
        name
        type
        feedUrl
      }
      controllerId
      created
    }
    group(groupId: $groupId) {
      owner {
        _id
      }
    }
  }
`;

export function RealtimeStreamsList(props: Props) {
  const { groupId } = props;

  const { data, loading } = useQuery(REALTIME_ORG_QUERY, {
    variables: {
      groupId,
    },
    pollInterval: 20000,
  });

  if (loading) return <div>Loading...</div>;
  if (!data) return <div>no data, try reloading</div>;

  const { realtimeStreamsOrg, group } = data;

  return (
    <>
      <h1> Enabled features</h1>
      <RealtimeDetailsForOrg ownerId={group.owner._id} />

      <h2> Running Streams: </h2>
      <div>
        <RealtimeRunningStreamsOrg groupId={groupId} />
      </div>
      <h3> Old Streams: </h3>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Stream Id</TableCell>
            <TableCell align="right">Stream Type</TableCell>
            <TableCell align="right">Created By</TableCell>
            <TableCell align="right">Created</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Trint Id</TableCell>
          </TableRow>
        </TableHead>
        {realtimeStreamsOrg.map((stream: RealtimeStream) => {
          return (
            <TableRow key={stream._id}>
              <TableCell>{stream.config.name}</TableCell>
              <TableCell align="right">{stream._id}</TableCell>
              <TableCell align="right">{stream.config.type}</TableCell>
              <TableCell align="right">{stream.userId}</TableCell>
              <TableCell align="right">
                {moment(stream.created).format('dd, MMM Do YYYY, H:mm:ss')}
              </TableCell>
              <TableCell align="right">{stream.status}</TableCell>
              <TableCell align="right">{stream.trintId}</TableCell>
            </TableRow>
          );
        })}
      </Table>
    </>
  );
}

export default RealtimeStreamsList;
