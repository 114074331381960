import * as React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import VoucherStats from './VouchersStats';
import CouponsTable from './CouponsTable';

export const SINGLE_COUPON_LIST_QUERY = gql`
  query {
    couponBatches {
      count
      other {
        count
        lastCouponId
        coupons {
          id
          name
          valid
          percent_off
        }
      }
    }
  }
`;

export default function SingleVoucherList() {
  const queryResult = useQuery(SINGLE_COUPON_LIST_QUERY);

  return (
    <>
      <VoucherStats batch={false} />
      <CouponsTable
        queryResult={queryResult}
        mapDataToCoupons={(data) => data.couponBatches.other.coupons}
      />
    </>
  );
}
