export const realtimeFeatureFlags = {
  realtime: 'realtime',
  realtimePush: 'feature.realtime.push',
  realtimeWorkspaces: 'rollout.feature.realtime-workspaces',
  realtimeConcurrentStreams: 'realtime-concurrent-streams',
};

export const organisationFeatureFlags = {
  gdprDelete: 'rollout.unicorn.gdpr-delete',
};

export const featureFlagKeys = {
  ...organisationFeatureFlags,
  ...realtimeFeatureFlags,
};
