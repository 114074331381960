import React from 'react';
import { Grid, TableCell, TableRow, CircularProgress } from '@material-ui/core';
import Table, * as create from '../../../shared/components/Table';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import moment from 'moment';
interface Props {
  userId: string;
}
interface Story {
  _id: string;
  name: string;
  created: number;
  updated: number;
}
interface GetStoriesRes {
  stories: Story[];
}

const headers = [
  create.header('ID'),
  create.header('Name'),
  create.header('Date Created'),
  create.header('Date Updated'),
];

const colWidths = ['25%', '25%', '25%', '25%'];

const GET_STORIES = gql`
  query getStories($userId: String!) {
    stories(userId: $userId) {
      _id
      name
      created
      updated
    }
  }
`;

function StoryListView(props: Props) {
  const { userId } = props;
  const { loading, error, data } = useQuery<GetStoriesRes>(GET_STORIES, {
    variables: {
      userId,
    },
  });
  if (error) {
    return <div>{error.message}</div>;
  }
  if (loading && !data) {
    return <CircularProgress />;
  }
  return (
    <>
      <Grid
        container
        alignItems="center"
        justify="space-around"
        direction="row"
      >
        <h3>User Stories</h3>
      </Grid>
      <Grid container>
        <Table headers={headers} colWidths={colWidths}>
          {data!.stories.map((story) => (
            <TableRow data-testid="story-row" key={story._id}>
              <TableCell>{story._id}</TableCell>
              <TableCell>{story.name}</TableCell>

              <TableCell>
                {moment(story.created).format('ddd DD MMM YYYY')}
              </TableCell>
              <TableCell>
                {moment(story.updated).format('ddd DD MMM YYYY')}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Grid>
    </>
  );
}

export default StoryListView;
