import * as React from 'react';
import withStyles, {
  WithStylesOptions,
  StyleRulesCallback,
  StyleRules,
  WithStyles,
} from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

// To anyone interested:
// This is a useful helper to get around some issues TypeScript has with Typing Higher Order Components
// This is the preferred way to get type safety while using Material UI because
// 1. It means fewer imports in your files
//    - No need to import { WithStyles, withSyles, createStyles } from '@material-ui/core/styles/withStyles' as that is all done in this file
// 2. No need to interface Props extends WithStyles<typeof styles> { ... }
// 3. No wrestling with generic types on your HoCs

/**
 * Creates a simple component where `children` is a function containing the Material UI classes defined by @param `styles`
 * @param styles the StyleRules that will be used to create the `classes` passed to the children callback
 * @param options Style options
 */
export default function createStyled<
  ClassKey extends string,
  Options extends WithStylesOptions<ClassKey> = {}
>(
  styles: StyleRulesCallback<ClassKey> | StyleRules<ClassKey>,
  options?: Options,
) {
  interface InnerProps {
    children: (
      props: WithStyles<typeof styles> & { theme?: Theme },
    ) => React.ReactNode;
  }

  class Inner extends React.Component<
    InnerProps & WithStyles<typeof styles> & { theme?: Theme }
  > {
    render() {
      const { children, ...rest } = this.props;
      return children(rest);
    }
  }

  // there is an issue with types here
  return (withStyles<ClassKey, Options>(styles, options) as any)(
    Inner,
  ) as React.ComponentClass<InnerProps>;
}
