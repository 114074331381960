import * as React from 'react';
import { FormConfig, createFormContext, FormProps } from 'yafl';

interface Props<F extends object> extends FormConfig<F> {
  loading?: boolean;
  context: ReturnType<typeof createFormContext>;
}

type ChildrenFn = (props: FormProps<any>) => React.ReactNode;
type FormChildren = React.ReactNode | ChildrenFn;
function checkChildren(children: FormChildren): children is ChildrenFn {
  return typeof children === 'function';
}

class FormKit<F extends object> extends React.Component<Props<F>> {
  render() {
    const { children, context, loading, disabled, ...props } = this.props;
    const { Form, ForwardProps } = context;

    return (
      <Form {...props}>
        {yafl => (
          <form
            onSubmit={e => {
              yafl.submit();
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <ForwardProps loading={loading} disabled={disabled}>
              {checkChildren(children)
                ? children(yafl as FormProps<F>)
                : children}
            </ForwardProps>
          </form>
        )}
      </Form>
    );
  }
}

export default FormKit;
