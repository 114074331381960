import React from 'react';
import { Query } from 'react-apollo';
import { CircularProgress } from '@material-ui/core';

import { ErrorDisplay } from 'shared/components';
import AllowanceForm from 'users/components/AllowanceForm';
import {
  ORGANISATION_QUERY,
  OrganisationQueryResult,
} from 'organisations/queries';

interface OrganisationQueryRes {
  group: OrganisationQueryResult;
}

interface OrganisationQueryVars {
  groupId: string;
}

interface Props {
  groupId: string;
}
export default function OrganisationAllowance(props: Props) {
  const { groupId } = props;

  return (
    <Query<OrganisationQueryRes, OrganisationQueryVars>
      query={ORGANISATION_QUERY}
      variables={{ groupId }}
      fetchPolicy="cache-and-network"
    >
      {({ data, loading, error }) => {
        if (error) return <ErrorDisplay>{error}</ErrorDisplay>;
        if (loading && (!data || !data.group)) return <CircularProgress />;
        const { group } = data!;
        const { _id: userId } = group.owner;
        return <AllowanceForm userId={userId} />;
      }}
    </Query>
  );
}
