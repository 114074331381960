export function internalContactsValidator(value: string) {
  const emails = value.trim().split(' ');
  // eslint-disable-next-line
  const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const validationArray = emails.map(email => {
    const blankEmail = email === '';
    const invalidEmail = !regex.test(email.replace(/\s/g, ''));
    if (blankEmail || invalidEmail) return false;
    return true;
  }, []);

  if (validationArray.includes(false)) {
    return 'Invalid emails. Enter valid space separated emails.';
  }

  return undefined;
}
