export enum SecureDeletionState {
  Pending = 'pending', // 5 mins.. can cancel
  Processing = 'processing', // picked up, there's no cancelling
  Completed = 'completed', // either compelted - everything suceeeded
  Partial = 'partial', // partially completed - neither
  Error = 'error', // error completed - everything failed
}

export interface Log {
  _id: string;
  created: string;
  executedBy: {
    userId: string;
    username: string;
  };
  request: {
    spanId: string;
    type: string;
    linkToRequest: string;
    organisationId: string;
    userIds: string[];
    fileIds: string[];
  };
  outcome: {
    status: SecureDeletionState;
    artefacts: LogArtefact[];
  };
}

export interface LogArtefact {
  type: string;
  id: string;
  state: SecureDeletionState;
}
