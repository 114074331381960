import * as React from 'react';
import { ApolloError } from 'apollo-boost';
import { TableCell, TableRow as Tr } from '@material-ui/core';
import { TableCellProps } from '@material-ui/core/TableCell';

interface Props<T> {
  refetch?: () => Promise<any>;
  onRowClick?: (e: React.MouseEvent<HTMLTableRowElement>, item: T) => void;
  item: T;
  component?: React.ComponentType<{ item: T }>;
  transform?: (item: T) => TableRowItem;
  options?: { isShowingDeleted?: boolean };
  onMutationCompleted?: (data: any) => void;
  onMutationError?: (error: ApolloError) => void;
}

export type TableRowItem = TableCellItem[];

export interface TableCellItem {
  content: React.ReactNode;
  cellProps: TableCellProps;
}

export default class TableRow<T> extends React.Component<Props<T>> {
  onRowClick = (e: React.MouseEvent<HTMLTableRowElement>) => {
    const { item, onRowClick = () => {} } = this.props;
    onRowClick(e, item);
  };

  render() {
    const { transform, item, component: Component, ...props } = this.props;
    if (typeof Component === 'function') {
      return <Component {...props} item={item} />;
    }

    if (typeof transform !== 'function') {
      return null;
    }

    return (
      <Tr onClick={this.onRowClick}>
        {transform(item).map((c, i) => (
          <TableCell key={i}>{c.content}</TableCell>
        ))}
      </Tr>
    );
  }
}
