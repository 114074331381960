import React from 'react';
import {
  Button,
  Step,
  StepContent,
  StepLabel,
  Typography,
} from '@material-ui/core';

interface Props {
  onNext: () => void;
  active: boolean;
}

function StepOne(props: Props) {
  const { onNext } = props;

  return (
    <Step {...props}>
      <StepLabel data-testid="step-label">
        Are you sure you want to delete
      </StepLabel>
      <StepContent {...props} data-testid="step-content">
        <Typography data-testid="step-one-body">
          You're about to delete all the organisation's soft deleted files. Once
          deleted files cannot be recovered. If you would like to continue click
          'next' to proceed
        </Typography>

        <br />
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={onNext}
          data-testid={'forward-button-1'}
        >
          Next
        </Button>
      </StepContent>
    </Step>
  );
}

export default StepOne;
