import React from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { createFormContext } from 'yafl';
import FormTextInput from 'shared/formKit/FormTextInput';
import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { FormKit } from 'shared/formKit';
import { ModalTrigger } from 'contexts/ModalTrigger';
import CustomToast from 'shared/components/CustomToast';
import { SeatsQueryResult, SeatsQueryVars, SEATS_QUERY } from '../queries';
import {
  UpdateSeatsResult,
  UpdateSeatsVars,
  UPDATE_SEATS_MUTATION,
} from '../mutations';
import { enterpriseSeatsValidator } from '../formFieldValidators';

interface FormData {
  seats: number;
}

interface EnterpriseUpdateSeatsFormProps {
  handleClose: () => void;
  userId: string;
}

function EnterpriseUpdateSeatsForm(props: EnterpriseUpdateSeatsFormProps) {
  const context = createFormContext<FormData>();
  const { Field } = context;
  const { userId, handleClose } = props;

  const [
    updateSeatsMutation,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation<UpdateSeatsResult, UpdateSeatsVars>(UPDATE_SEATS_MUTATION);

  const { data, loading, error } = useQuery<SeatsQueryResult, SeatsQueryVars>(
    SEATS_QUERY,
    {
      variables: {
        userId,
      },
    },
  );

  if (loading && (!data || !data.user)) {
    return <p>Loading...</p>;
  }

  if (error || !data) return <p>Error</p>;

  const seats = data.user.billing.v2.subscriptionSeatCount ?? 1;
  const dto = {
    userId,
    seats,
  };

  return (
    <div>
      <FormKit<FormData>
        context={context}
        initialValue={dto}
        defaultValue={dto}
      >
        {({ formValue, formIsValid }) => {
          return (
            <>
              <DialogTitle id="form-dialog-title">
                Update Enterprise Seats
              </DialogTitle>
              <DialogContent>
                <Grid container style={{ marginTop: '10px' }}>
                  <Field
                    fullWidth
                    name="seats"
                    label="Seat count"
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 11,
                      },
                      shrink: true,
                    }}
                    component={FormTextInput}
                    variant="outlined"
                    validate={enterpriseSeatsValidator}
                  />
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <ModalTrigger<'ConfirmDialog'>
                  modal="ConfirmDialog"
                  modalProps={{
                    onConfirm: () => {
                      updateSeatsMutation({
                        variables: {
                          userId,
                          seats: formValue.seats,
                        },
                      })
                        .then(() => {
                          window.location.reload();
                        })
                        .catch(() => {});
                    },
                    title: 'Are you sure you want to change the seat count?',
                  }}
                >
                  {({ openModal }) => (
                    <Button
                      disabled={!formIsValid || mutationLoading}
                      onClick={openModal}
                      color="primary"
                      variant="contained"
                    >
                      {mutationLoading ? 'Updating seats...' : 'Update seats'}
                    </Button>
                  )}
                </ModalTrigger>
              </DialogActions>
            </>
          );
        }}
      </FormKit>
      {mutationError && <CustomToast message={mutationError.message} isOpen />}
    </div>
  );
}

interface EnterpriseUpdateSeatsModalProps {
  handleClose: () => void;
  isOpen: boolean;
  userId: string;
}

function EnterpriseUpdateSeatsModal(props: EnterpriseUpdateSeatsModalProps) {
  const { handleClose, isOpen, userId } = props;

  return (
    <Dialog onClose={handleClose} open={isOpen} fullWidth>
      <EnterpriseUpdateSeatsForm handleClose={handleClose} userId={userId} />
    </Dialog>
  );
}

export default EnterpriseUpdateSeatsModal;
