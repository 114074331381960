import * as React from 'react';
import { Mutation } from 'react-apollo';
import { ApolloError } from 'apollo-boost';
import {
  CircularProgress,
  TableRow,
  TableCell,
  IconButton,
  Theme,
  createStyles,
  Tooltip,
} from '@material-ui/core';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Restore from '@material-ui/icons/Restore';
import RestoreFromTrash from '@material-ui/icons/RestoreFromTrash';
import { FormatDuration, FormatDate } from 'shared/components';
import { createStyled } from 'shared/hocs';
import { ModalTrigger } from 'contexts/ModalTrigger';
import { TrintFile } from 'types';
import { DELETE_FILE_MUTATION, RESTORE_FILE_MUTATION } from '../mutations';

const noop = () => {};

interface DeleteMutationVars {
  fileId: string;
  refund?: boolean;
}

interface DeleteMutationResult {
  deleted: boolean;
  refunded: boolean;
}

interface RestoreMutationVars {
  fileId: string;
}

interface RestoreMutationResult {
  deleted: boolean;
}

interface Props {
  userId: string;
  item: TrintFile;
  onRowClick: (
    e: React.MouseEvent<HTMLTableRowElement>,
    item: TrintFile,
  ) => void;
  options: { isShowingDeleted?: boolean };
  onMutationCompleted?: (data: any) => void;
  onMutationError?: (error: ApolloError) => void;
}

interface State {
  isMutating: boolean;
}

class UserListItem extends React.Component<Props> {
  state: State = { isMutating: false };

  onMutationBegin = (isMutating: boolean = true): void => {
    this.setState({ isMutating });
  };

  onMutationCompleted = (
    data: DeleteMutationResult | RestoreMutationResult,
  ): void => {
    const { onMutationCompleted = noop } = this.props;
    onMutationCompleted(data);
    this.setState({ isMutating: false });
  };
  onMutationError = (error: ApolloError): void => {
    const { onMutationError = noop } = this.props;
    onMutationError(error);
    this.setState({ isMutating: false });
  };

  render() {
    const { item, options } = this.props;
    const { isMutating } = this.state;
    const { isShowingDeleted = false } = options;
    const {
      _id: fileId,
      algorithm,
      duration,
      status,
      title,
      created,
      updated,
      uploadSource,
    } = item;

    return (
      <Styled>
        {({ classes }) => (
          <TableRow>
            <TableCell className={classes.title}>
              <Tooltip title={title}>
                <span>{title}</span>
              </Tooltip>
            </TableCell>
            <TableCell>{fileId}</TableCell>
            <TableCell>{uploadSource}</TableCell>
            <TableCell>{`[${algorithm}] ${status}`}</TableCell>
            <TableCell>
              <FormatDuration duration={duration} />
            </TableCell>
            <TableCell>
              <FormatDate date={created} />
            </TableCell>
            <TableCell>
              <FormatDate date={updated} />
            </TableCell>
            <TableCell className={classes.actions}>
              {isShowingDeleted && (
                <Mutation<RestoreMutationResult, RestoreMutationVars>
                  mutation={RESTORE_FILE_MUTATION}
                  onCompleted={data => {
                    this.onMutationCompleted(data);
                  }}
                  onError={error => {
                    this.onMutationError(error);
                  }}
                >
                  {mutation => {
                    if (isMutating) return <CircularProgress />;

                    return (
                      <ModalTrigger
                        modal="ConfirmDialog"
                        modalProps={{
                          title: `RESTORE ${title}?`,
                          onConfirm: () => {
                            mutation({ variables: { fileId } });
                            this.onMutationBegin();
                          },
                        }}
                      >
                        {({ openModal }) => (
                          <Tooltip title="Restore">
                            <IconButton onClick={openModal}>
                              <RestoreFromTrash />
                            </IconButton>
                          </Tooltip>
                        )}
                      </ModalTrigger>
                    );
                  }}
                </Mutation>
              )}
              {!isShowingDeleted && (
                <Mutation<DeleteMutationResult, DeleteMutationVars>
                  mutation={DELETE_FILE_MUTATION}
                  onCompleted={data => {
                    this.onMutationCompleted(data);
                  }}
                  onError={error => {
                    this.onMutationError(error);
                  }}
                >
                  {mutation => {
                    if (isMutating) return <CircularProgress />;

                    return (
                      <>
                        <ModalTrigger
                          modal="ConfirmDialog"
                          modalProps={{
                            title: `DELETE ${title}?`,
                            onConfirm: () => {
                              mutation({ variables: { fileId } });
                              this.onMutationBegin();
                            },
                          }}
                        >
                          {({ openModal }) => (
                            <Tooltip title="Delete">
                              <IconButton onClick={openModal}>
                                <DeleteOutline />
                              </IconButton>
                            </Tooltip>
                          )}
                        </ModalTrigger>
                        <ModalTrigger
                          modal="ConfirmDialog"
                          modalProps={{
                            title: `DELETE ${title} AND REFUND?`,
                            onConfirm: () => {
                              mutation({ variables: { fileId, refund: true } });
                              this.onMutationBegin();
                            },
                          }}
                        >
                          {({ openModal }) => (
                            <Tooltip title="Delete & refund">
                              <IconButton onClick={openModal}>
                                <Restore />
                              </IconButton>
                            </Tooltip>
                          )}
                        </ModalTrigger>
                      </>
                    );
                  }}
                </Mutation>
              )}
            </TableCell>
          </TableRow>
        )}
      </Styled>
    );
  }
}

const Styled = createStyled((theme: Theme) =>
  createStyles({
    title: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    actions: {
      whiteSpace: 'nowrap',
    },
  }),
);

export default UserListItem;
