import * as React from 'react';
import { isString } from '../utils';

interface CustomProps {
  ellipsis?: string;
  maxLength?: number;
  component: keyof React.ReactHTML;
}

class TruncateText<T extends keyof React.ReactHTML> extends React.Component<
  CustomProps & React.HTMLProps<T>
> {
  render() {
    const { maxLength, component, ellipsis, children, ...rest } = this.props;
    if (
      isString(children) &&
      typeof maxLength === 'number' &&
      children.length > maxLength
    ) {
      const addEllipsis = isString(ellipsis);
      const text = children.substring(0, maxLength);
      return React.createElement(
        component,
        rest,
        addEllipsis ? `${text}${ellipsis}` : text,
      );
    }
    return React.createElement(component, rest, children);
  }
}

export default TruncateText;
