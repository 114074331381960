import * as React from 'react';
import withUserContext from 'shared/hocs/withUserContext';
import { AuthContextState } from 'shared/components/UserContext';
import WelcomeView from 'views/components/welcome';
import GuestView from 'views/components/guest';

interface Props {
  [key: string]: any;
  auth: AuthContextState;
}

function Home(props: Props) {
  const { auth } = props;
  if (!auth.isAuthorised) return <GuestView />;
  return <WelcomeView />;
}

export default withUserContext(Home);
