import { GeneralSearchQueryResult } from 'users/queries';

const clean = (truthy: string) => (truthy ? truthy : '');

export default function getUsersCsv(
  members: GeneralSearchQueryResult[],
): string {
  return members.reduce((acc, member) => {
    const { _id, username, profile } = member;
    const { firstName, lastName, jobTitle } = profile;
    const userBase = `${username},${_id}`;
    const userProfile = `${clean(firstName)},${clean(lastName)},${clean(
      jobTitle,
    )}`;
    return `${acc}${userBase},${userProfile}\n`;
  }, 'Username,UserId,First Name,Last Name,Job Title\n');
}
