// TODO: Create graphql resolver for these.
// Currently they are duplicated on the server
// NB: 14 and above are new and only defined here fttb.
const reasons = {
  POOR_ACCURACY: 1,
  STALLED_UPLOAD: 2,
  USER_ERROR: 3,
  LANGUAGE_SELECTION: 4,
  SPEECHMATICS_ERROR: 5,
  BUSINESS_DEVELOPMENT: 6,
  KNOWN_BUG: 7,
  UNKNOWN_BUG: 8,
  MANUAL_INVOICING: 9,
  REFUNDED_PAYMENT: 10,
  INTERNAL_REASON: 11,
  OTHER_REASON: 12,
  PRODUCT_DEVELOPMENT: 13,
  REFERRAL: 14, // RESERVED - NOT INCLUDED BELOW - Used from 30/11/2018 to 26/04/2019
  PROMOTION: 15,
  PAID_DELINQUENT: 16,
};

export default reasons;

export const reasonOptions = [
  { value: reasons.POOR_ACCURACY, label: 'Poor Accuracy' },
  { value: reasons.STALLED_UPLOAD, label: 'Stalled upload' },
  { value: reasons.USER_ERROR, label: 'User error' },
  { value: reasons.LANGUAGE_SELECTION, label: 'Langauge selection' },
  { value: reasons.SPEECHMATICS_ERROR, label: 'Speechmatics error' },
  { value: reasons.BUSINESS_DEVELOPMENT, label: 'Business Development' },
  { value: reasons.KNOWN_BUG, label: 'Known bug' },
  { value: reasons.UNKNOWN_BUG, label: 'Unknown bug' },
  { value: reasons.MANUAL_INVOICING, label: 'Manual invoicing' },
  { value: reasons.REFUNDED_PAYMENT, label: 'Refund' },
  { value: reasons.INTERNAL_REASON, label: 'Internal reason' },
  { value: reasons.PRODUCT_DEVELOPMENT, label: 'Product development' },
  { value: reasons.PROMOTION, label: 'Promotion' },
  { value: reasons.PAID_DELINQUENT, label: 'Paid delinquent' },
  { value: reasons.OTHER_REASON, label: 'Other' },
];

// Full list, including obsolete reasons that are no longer used.
export const reasonOptionsFull = [
  ...reasonOptions,
  { value: reasons.REFERRAL, label: 'Referral' },
];
