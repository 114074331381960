/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { LaunchTemplateResult } from 'ec2launcher/queries';
import { Query } from 'react-apollo';
import { CircularProgress, MenuItem, Select } from '@material-ui/core';
import CustomToast from 'shared/components/CustomToast';
import { LIST_LAUNCH_TEMPLATES } from 'ec2launcher/queries';

interface Args {
  launchTemplate?: string;
  onSelectionChanged(userId: string): void;
}

function LaunchTemplateSelect(props: Args) {
  const { onSelectionChanged, launchTemplate, } = props;

  return (
    <Query<LaunchTemplateResult>
      query={LIST_LAUNCH_TEMPLATES}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data, refetch }) => {
        if (error) return <CustomToast message={error.message} isOpen />;
        if (loading && (!data || !data.listLaunchTemplates))
          return <CircularProgress />;
        const sortedTemplates = (
          data?.listLaunchTemplates?.launchTemplates ?? []
        ).sort();

        return (
          <Select
            value={launchTemplate}
            onChange={(e) => onSelectionChanged(e.target.value)}
            name="Owner"
            displayEmpty
          >
            <MenuItem value="" disabled>
              Launch template
            </MenuItem>
            {sortedTemplates.map((lt) => (
              <MenuItem key={lt} value={lt}>
                {lt}
              </MenuItem>
            ))}
          </Select>
        );
      }}
    </Query>
  );
}

export default LaunchTemplateSelect;
