import React from 'react';
import { useMutation } from 'react-apollo';
import moment from 'moment';
import { createFormContext } from 'yafl';
import FormTextInput from 'shared/formKit/FormTextInput';
import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { FormKit } from 'shared/formKit';
import { ModalTrigger } from 'contexts/ModalTrigger';
import PagedUserSelect from 'users/components/PagedUserSelect';
import CustomToast from 'shared/components/CustomToast';
import {
  CREATE_ENTERPRISE_MUTATION,
  CreateEnterpriseResult,
  CreateEnterpriseVars,
} from '../mutations';
import {
  enterpriseSeatsValidator,
  enterpriseNameValidator,
  internalContactsValidator,
} from '../formFieldValidators';

interface FormData {
  enterpriseName: string;
  internalContacts: string;
  seats: number;
  userId: string;
  trialEndDate: string;
  contractEndDate: string;
}

const context = createFormContext<FormData>();
const { Field } = context;

function EnterpriseCreateModal(props: any) {
  const { onClose, isOpen, userId, enterpriseName } = props;

  const dto = {
    enterpriseName: enterpriseName || '',
    internalContacts: '',
    seats: 11,
    userId: userId || '',
    trialEndDate: moment().format('YYYY-MM-DD'),
    contractEndDate: moment().format('YYYY-MM-DD'),
  };

  const [
    createEnterpriseMutation,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation<CreateEnterpriseResult, CreateEnterpriseVars>(
    CREATE_ENTERPRISE_MUTATION,
  );

  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth>
      <FormKit<FormData>
        context={context}
        initialValue={dto}
        defaultValue={dto}
      >
        {({ formValue, formIsValid, setFormValue }) => {
          return (
            <>
              <DialogTitle id="form-dialog-title">
                Upgrade to Enterprise
              </DialogTitle>
              <DialogContent>
                {!userId && (
                  <Grid container spacing={24}>
                    <Grid item xs={6} sm={12} style={{ marginTop: '10px' }}>
                      <Typography
                        variant="h6"
                        style={{
                          fontSize: '15px',
                          fontWeight: 500,
                        }}
                      >
                        Select an individual user or owner of a pro team to
                        upgrade to enterprise
                      </Typography>
                    </Grid>

                    <Grid item xs={6} sm={12}>
                      <PagedUserSelect
                        userId={formValue.userId}
                        filterResults={(user) =>
                          user.group === null ||
                          user.group.owner._id === user._id
                        }
                        onSelectionChanged={(value) =>
                          setFormValue(() => ({
                            ...formValue,
                            userId: value,
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                )}
                {formValue.userId && (
                  <Grid container spacing={24}>
                    <Grid item xs={6} sm={12}>
                      <Typography
                        variant="h6"
                        style={{
                          fontSize: '15px',
                          fontWeight: 500,
                        }}
                      >
                        Configure enterprise account settings
                      </Typography>
                    </Grid>

                    <p>
                      <br />
                    </p>

                    <Grid item xs={6} sm={12}>
                      <Field
                        fullWidth
                        name="enterpriseName"
                        label="Enterprise Name"
                        component={FormTextInput}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        validate={enterpriseNameValidator}
                      />
                    </Grid>

                    <Grid item xs={6} sm={12}>
                      <Field
                        fullWidth
                        name="trialEndDate"
                        label="Trial End"
                        type="date"
                        component={FormTextInput}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={6} sm={12}>
                      <Field
                        fullWidth
                        name="contractEndDate"
                        label="Contract End"
                        type="date"
                        component={FormTextInput}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={6} sm={12}>
                      <Field
                        fullWidth
                        name="seats"
                        label="Seat count"
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 11,
                          },
                          shrink: true,
                        }}
                        component={FormTextInput}
                        variant="outlined"
                        validate={enterpriseSeatsValidator}
                      />
                    </Grid>

                    <Grid item xs={6} sm={12}>
                      <Field
                        fullWidth
                        name="internalContacts"
                        label="Internal Contacts"
                        component={FormTextInput}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        validate={internalContactsValidator}
                      />
                    </Grid>
                  </Grid>
                )}
              </DialogContent>

              <DialogActions>
                <Button onClick={onClose} color="primary">
                  Cancel
                </Button>
                <ModalTrigger<'ConfirmDialog'>
                  modal="ConfirmDialog"
                  modalProps={{
                    onConfirm: () => {
                      createEnterpriseMutation({
                        variables: {
                          userId: formValue.userId,
                          enterpriseName: formValue.enterpriseName,
                          internalContacts: formValue.internalContacts,
                          seats: formValue.seats,
                          trialEndDate: formValue.trialEndDate,
                          contractEndDate: formValue.contractEndDate,
                        },
                      })
                        .then(() => {
                          window.location.reload();
                        })
                        .catch(() => {});
                    },
                    title:
                      'Are you sure you want to create this Enterprise account?',
                  }}
                >
                  {({ openModal }) => (
                    <Button
                      disabled={!formIsValid || mutationLoading}
                      onClick={openModal}
                      color="primary"
                      variant="contained"
                    >
                      {mutationLoading
                        ? 'Creating Enterprise...'
                        : 'Create Enterprise'}
                    </Button>
                  )}
                </ModalTrigger>
              </DialogActions>
            </>
          );
        }}
      </FormKit>
      {mutationError && <CustomToast message={mutationError.message} isOpen />}
    </Dialog>
  );
}

export default EnterpriseCreateModal;
