import React from 'react';
import { Mutation } from 'react-apollo';
import { Redirect, RouteComponentProps } from 'react-router';
import gql from 'graphql-tag';
import querystring from 'querystring';
import { createStyled } from 'shared/hocs';
import { AutoExecute, ErrorDisplay } from 'shared/components';
import {
  CircularProgress,
  Paper,
  Theme,
  Typography,
  createStyles,
  Button,
} from '@material-ui/core';

const SALESFORCE_INTEGRATION_MUTATION = gql`
  mutation salesforceIntegration($code: String!) {
    salesforceIntegration(code: $code) {
      success
    }
  }
`;

interface Props extends RouteComponentProps<any> {}

function SalesforceAuthPage(props: Props) {
  return (
    <Styled>
      {({ classes }) => (
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography
              className={classes.title}
              component="h1"
              variant="h5"
              align="center"
            >
              Salesforce
            </Typography>
            <Typography
              className={classes.subtitle}
              variant="subtitle2"
              align="center"
            >
              Trint's Integrations with Salesforce
            </Typography>
            <Typography variant="body1" paragraph>
              Completing integration...
            </Typography>
            <Mutation mutation={SALESFORCE_INTEGRATION_MUTATION}>
              {(mutation, { loading, error, data, called }) => {
                if (loading) return <CircularProgress />;
                if (error) {
                  return <ErrorDisplay>{error.message}</ErrorDisplay>;
                }

                if (called) {
                  const { salesforceIntegration } = data;
                  const { success } = salesforceIntegration;
                  if (success) {
                    return <Redirect to="/integrations/salesforce" />;
                  }
                }

                const { search } = props.location;
                const query = search.slice(1);
                const { code } = querystring.parse(query);

                if (!called) {
                  return (
                    <AutoExecute
                      fn={() => {
                        mutation({ variables: { code } });
                      }}
                    />
                  );
                }

                return (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      mutation({ variables: { code } });
                    }}
                    disabled={!code}
                  >
                    Retry
                  </Button>
                );
              }}
            </Mutation>
          </Paper>
        </main>
      )}
    </Styled>
  );
}

const Styled = createStyled((theme: Theme) => {
  return createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    title: {
      marginBottom: theme.spacing.unit,
    },
    subtitle: {
      marginBottom: theme.spacing.unit * 3,
    },
    footer: {
      marginTop: theme.spacing.unit * 6,
    },
    link: {
      textDecoration: 'none',
    },
    error: {
      color: 'red',
      border: '1px solid red',
      borderRadius: '5px',
      marginBottom: '20px',
    },
  });
});

export default SalesforceAuthPage;
