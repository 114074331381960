import React, { useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import DeleteUserDialog from './DeleteUserDialog';
interface Props {
  userId: string;
  isActiveSubscriberAndOwner: boolean;
}

function DeleteUser(props: Props) {
  const [showDialog, setDialogStatus] = useState(false);
  const { userId, isActiveSubscriberAndOwner } = props;
  const [deleteUserStatus, setDeleteUserStatus] = useState(false);

  if (deleteUserStatus)
    return <Typography>This user will be deleted soon</Typography>;

  if (isActiveSubscriberAndOwner)
    return (
      <Typography>
        User cannot be securely deleted while on an active subscription
      </Typography>
    );

  return (
    <>
      <Button
        onClick={() => {
          setDialogStatus(true);
        }}
        color="secondary"
        variant="outlined"
        data-testid="delete-user"
      >
        Delete User
      </Button>

      {showDialog && (
        <DeleteUserDialog
          onClose={() => {
            setDialogStatus(false);
          }}
          userId={userId}
          handleComplete={() => {
            setDeleteUserStatus(true);
          }}
          handleError={() => {
            window.alert(
              'User cannot be deleted at this time. Please contact engineering.',
            );
          }}
        />
      )}
    </>
  );
}

export default DeleteUser;
