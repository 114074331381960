import React, { useState } from 'react';
import gql from 'graphql-tag';
import {
  DialogTitle,
  DialogContent,
  Grid,
  Dialog,
  DialogActions,
  Button,
} from '@material-ui/core';
import { useLazyQuery, useMutation } from 'react-apollo';
import Select from 'react-select';
import { ModalTrigger } from 'contexts/ModalTrigger';
import CustomToast from 'shared/components/CustomToast';

interface Props {
  groupId: string;
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
}

const SEARCH_USERS_QUERY = gql`
  query searchUsersQuery($searchText: String!) {
    users(searchText: $searchText, limit: 20, page: 0) {
      list {
        _id
        username
        profile {
          firstName
          lastName
        }
      }
    }
  }
`;

const ADD_USER_TO_ORG_MUTATION = gql`
  mutation addMemberToGroup($groupId: String!, $memberId: String!) {
    addMemberToGroup(groupId: $groupId, memberId: $memberId) {
      _id
      members {
        _id
      }
    }
  }
`;

function AddUserToOrganisationModal(props: Props) {
  const { onClose, isOpen, groupId, onSuccess } = props;
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [addUserToOrg, mutationRes] = useMutation(ADD_USER_TO_ORG_MUTATION, {
    variables: {
      groupId,
      memberId: selectedUser,
    },
    onCompleted: onSuccess,
  });

  const [userSearchQuery, searchResult] = useLazyQuery(SEARCH_USERS_QUERY);
  const dataIsReady =
    !searchResult.loading && !searchResult.error && Boolean(searchResult.data);

  const lookupResult = dataIsReady ? searchResult.data.users.list : [];
  const mappedUsers = lookupResult.map((user: any) => {
    return {
      value: user._id,
      label: `${user.profile.firstName} ${user.profile.lastName} (${
        user.username
      })`,
    };
  });
  function searchUsers(searchText?: string) {
    const shouldIssueRequest = searchText && searchText.length > 2;
    if (!shouldIssueRequest) {
      return;
    }
    userSearchQuery({
      variables: {
        searchText,
      },
    });
  }

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>Add user to organisation</DialogTitle>
      <DialogContent>
        <Grid container spacing={24}>
          <Grid lg={12} item>
            <div style={{ width: 320, height: 150 }}>
              <Select
                isClearable
                isLoading={searchResult.loading}
                onInputChange={input => {
                  searchUsers(input);
                }}
                options={mappedUsers}
                noOptionsMessage={() => 'Type to search...'}
                onChange={(selectedValue: any) =>
                  setSelectedUser(selectedValue ? selectedValue.value : null)
                }
                isSearchable
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <ModalTrigger<'ConfirmDialog'>
          modal="ConfirmDialog"
          modalProps={{
            onConfirm: () => addUserToOrg(),
            title:
              'Are you sure you want to add this user to the organisation?',
          }}
        >
          {({ openModal }) => (
            <Button
              disabled={!Boolean(selectedUser)}
              onClick={openModal}
              color="primary"
              variant="contained"
            >
              Done
            </Button>
          )}
        </ModalTrigger>
      </DialogActions>
      {mutationRes.error && (
        <CustomToast message={mutationRes.error.message} isOpen />
      )}
    </Dialog>
  );
}

export default AddUserToOrganisationModal;
