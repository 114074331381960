import { reasonOptionsFull } from 'shared/refdata';
import { formatDate, formatDuration } from 'shared/utils';
import { BillingLog } from 'types';

export default function getBonusesCsv(bonuses: BillingLog[]): string {
  return bonuses.reduce(
    (acc, log) => {
      const { from, to, reason: reasonInt } = log;
      const { balance: fromBalance } = from;
      const { balance: toBalance, planCode } = to;
      const { label = '' } =
        reasonOptionsFull.find(x => x.value === reasonInt) || {};
      const reason = label ? `${reasonInt}. ${label}` : '';

      // Note: Prodbot used the credit.bonus value, which seemed wrong
      // and may explain the zero credits being given in its CSV
      const duration = toBalance - fromBalance;
      const negative = duration < 0;

      const given = formatDuration(negative ? -duration : duration);

      const userBase = `${formatDate(log.created)},${log.user}`;
      const BillingBase = `${planCode || ''},${
        negative ? '-' : ''
      }${given},${reason}`;

      return `${acc}${userBase},${BillingBase}\n`;
    },
    bonuses.length > 0 ? 'Date,UserId,Plan,Credit Given,Reason\n' : '',
  );
}
