import { DateTime } from 'luxon';

interface Options {
  round?: boolean;
  endOfDay?: boolean;
  format?: string;
}

export default function dateToMillis(
  date: string,
  options: Options = {},
): number {
  const { round = true, endOfDay = false, format = 'yyyy-LL-dd' } = options;

  const timePoint = DateTime.fromFormat(date, format, { zone: 'utc' });

  if (round && !endOfDay) return timePoint.startOf('day').toMillis();
  if (round && endOfDay) return timePoint.endOf('day').toMillis();
  return timePoint.toMillis();
}
