import * as React from 'react';
import { Query } from 'react-apollo';
import {
  Theme,
  Grid,
  Paper,
  WithStyles,
  TextField,
  Typography,
  withStyles,
  createStyles,
  Button as Btn,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import {
  PROFILE_QUERY,
  ProfileQueryResult,
  FeatureFlagsQueryResult,
} from 'users/queries';
import { Link } from 'react-router-dom';
import { featureFlagKeys } from 'shared/constants/featureFlagKeys';

type Props = { userId: string } & WithStyles<typeof styles>;

// https://github.com/mui-org/material-ui/issues/11968
const Button: React.ComponentType<any> = Btn;

function Form(props: Props) {
  const { classes } = props;
  const { userId } = props;

  return (
    <Query<
      {
        user: ProfileQueryResult;
        getUserFeatureFlags: FeatureFlagsQueryResult;
      },
      { userId: string }
    >
      query={PROFILE_QUERY}
      fetchPolicy="cache-and-network"
      variables={{ userId }}
    >
      {({ data, error, loading }) => {
        if (error) return null;
        if (loading && (!data || !data.user)) return null;
        const { user, getUserFeatureFlags } = data!;
        const { featureFlags } = getUserFeatureFlags;
        const isRealtimeEnabled = featureFlags.includes(
          featureFlagKeys.realtime,
        );
        const isPushEnabled = featureFlags.includes(
          featureFlagKeys.realtimePush,
        );
        const streamsFromFlag = featureFlags.find((f) =>
          f.includes(featureFlagKeys.realtimeConcurrentStreams),
        );

        const streamsAllowed = streamsFromFlag!.split(':')[1];

        return (
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <Typography className={classes.title} component="h1" variant="h5">
                Profile
              </Typography>
              <Grid container spacing={24}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>User email:</TableCell>
                      <TableCell align="right">
                        <strong>{user.username}</strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="firstName"
                    name="firstName"
                    label="First name"
                    value={user.profile.firstName}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="lastName"
                    name="lastName"
                    label="Last name"
                    fullWidth
                    value={user.profile.lastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="industry"
                    name="industry"
                    label="Industry"
                    value={user.profile.industry}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="department"
                    name="department"
                    label="Department"
                    fullWidth
                    value={user.profile.department}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    id="company"
                    name="company"
                    label="Company"
                    fullWidth
                    value={user.profile.company}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="companySize"
                    name="companySize"
                    label="Company Size"
                    fullWidth
                    value={user.profile.companySize}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="jobTitle"
                    name="jobTitle"
                    label="Job Title"
                    fullWidth
                    value={user.profile.jobTitle}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="city"
                    name="city"
                    label="City"
                    fullWidth
                    value={user.profile.city}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="country"
                    name="country"
                    label="Country"
                    fullWidth
                    value={user.profile.country}
                  />
                </Grid>

                <Grid container spacing={24}>
                  <Grid item xs={12}>
                    <h4>Realtime:</h4>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    Pull: {isRealtimeEnabled ? 'Yes' : 'No'}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    Push: {isPushEnabled ? 'Yes' : 'No'}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    Streams limit: {streamsAllowed ? streamsAllowed : '3'}
                  </Grid>
                </Grid>
              </Grid>
              <div className={classes.buttons}>
                <Button component={Link} to="/users" className={classes.button}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Save
                </Button>
              </div>
            </Paper>
          </main>
        );
      }}
    </Query>
  );
}

const styles = (theme: Theme) => {
  return createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing.unit * 3,
      marginLeft: theme.spacing.unit,
    },
    title: {
      marginBottom: theme.spacing.unit * 3,
    },
  });
};

export default withStyles(styles)(Form);
