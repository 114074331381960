import { Button, Paper, Typography } from '@material-ui/core';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { Styled } from './IntegrationsPage';

const NOTIFY_USERS = gql`
  mutation notifyUsers($message: String, $action: String) {
    notifyUsers(message: $message, action: $action)
  }
`;
const NOTIFICATION_MAX_LENGTH = 160;

export default function NotificationPage() {
  const [messageValue, setMessageValue] = useState('');
  const [actionValue, setActionValue] = useState('');
  const [error, setError] = useState('');

  const [notifyUsers, { loading, error: mutationError }] = useMutation(
    NOTIFY_USERS,
    {
      variables: { message: messageValue, action: actionValue },
    },
  );

  function handleClick() {
    setError('');
    if (messageValue.length === 0) {
      setError('Cannot send an empty notification');
      return;
    }
    try {
      notifyUsers();
      if (mutationError) {
        setError('Failed to notify all users');
      }
    } catch (e) {
      setError('Failed to notify all users');
    }
  }

  function handleMessageChange(event: any) {
    setMessageValue(event.target.value);
  }

  function handleActionChange(event: any) {
    setActionValue(event.target.value);
  }

  return (
    <div>
      <Styled>
        {({ classes }) => (
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <Typography
                className={classes.title}
                component="h1"
                variant="h5"
                align="center"
              >
                Notifications
              </Typography>
              {loading && <Typography>notification is being sent</Typography>}
              <Typography variant="subtitle2">
                Here you can push notifications out to all users, e.g.
                announcing Jeff's new podcast episode.
              </Typography>
              <br />
              <Typography variant="subtitle1">Notification Message</Typography>
              <Typography>
                Include what the message of your notification will be e.g.
                'Jeff's new podcast, StoryTech out now! Click here to listen'.
              </Typography>
              <textarea
                // type="textarea"
                rows={4}
                cols={50}
                maxLength={NOTIFICATION_MAX_LENGTH}
                value={messageValue}
                onChange={handleMessageChange}
              >
                {messageValue}
              </textarea>
              <Typography>{`${messageValue.length}/${NOTIFICATION_MAX_LENGTH}`}</Typography>
              <br />

              <Typography variant="subtitle1">Action</Typography>
              <Typography>
                The Action is the redirect that happens when the notification is
                clicked. This could be a route withing the webapp like
                "/workspaces" or the trint website URL e.g.
                https://trint.com/storytech.
              </Typography>
              <input
                type="text"
                value={actionValue}
                onChange={handleActionChange}
              />
              <br />
              <br />
              <br />

              <Button
                onClick={handleClick}
                type="submit"
                variant="contained"
                color="secondary"
                disabled={loading}
              >
                Send Notification
              </Button>
              {error && (
                <Typography style={{ color: 'red' }}>{error}</Typography>
              )}
            </Paper>
          </main>
        )}
      </Styled>
    </div>
  );
}
