import React from 'react';
import { ModalTrigger } from 'contexts/ModalTrigger';

import {
  createStyles,
  Theme,
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { createStyled } from 'shared/hocs';

interface Props {
  isOpen: boolean;
  title: string;
  description: string;
  onClose(): void;
  onSubmit(): void;
}

const Styled = createStyled((theme: Theme) => {
  return createStyles({
    table: {
      width: '100%',
      textAlign: 'left',
      border: 'none',
    },
    tbody: { verticalAlign: 'top' },
    firstRow: { borderBottom: '1px solid #eee' },
  });
});

function RaiseAlertModal(props: Props) {
  const { isOpen, onClose, onSubmit, title, description } = props;

  return (
    <Styled>
      {({ classes }) => (
        <>
          <Dialog onClose={onClose} open={isOpen} fullWidth>
            <DialogTitle id="alerts-dialog-title">Raise Alert</DialogTitle>
            <DialogContent>
              <Grid container spacing={24}>
                <Grid item xs={6} sm={12}>
                  <Typography>
                    An alert will be raised with the following properties:
                  </Typography>
                  <br />
                  <table className={classes.table}>
                    <tbody className={classes.tbody}>
                      <tr>
                        <th className={classes.firstRow}>Title</th>
                        <td className={classes.firstRow}>{title}</td>
                      </tr>
                      <tr>
                        <th>Description</th>
                        <td>{description}</td>
                      </tr>
                    </tbody>
                  </table>
                  <Typography></Typography>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <ModalTrigger<'ConfirmDialog'>
                modal="ConfirmDialog"
                modalProps={{
                  onConfirm: () => onSubmit(),
                  title: <span>Are you sure?</span>,
                }}
              >
                {({ openModal }) => (
                  <Button
                    onClick={openModal}
                    color="primary"
                    variant="contained"
                  >
                    Alert Engineers
                  </Button>
                )}
              </ModalTrigger>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Styled>
  );
}

export default RaiseAlertModal;
