import gql from 'graphql-tag';

export const LAUNCH_INSTANCE = gql`
  mutation LaunchInstance(
    $launchTemplate: String!
    $userKey: String!
    $userIpAddresses: [String!]!
  ) {
    launchInstance(
      launchInput: {
        launchTemplate: $launchTemplate
        userKey: $userKey
        userIpAddresses: $userIpAddresses
      }
    ) {
      launchId
      instanceId
      instanceIpAddress
      instanceName
      expiry
    }
  }
`;

export interface LaunchInstanceArgs {
  launchTemplate: string;
  userKey: string;
  userIpAddresses: string[];
}

export interface LaunchInstanceResult {
  launchId: string;
  instanceId: string;
  instanceIpAddress: string;
  instanceName: string;
  expiry: string;
}

export const TERMINATE_INSTANCE = gql`
  mutation TerminateInstance($launchId: String!) {
    terminateInstance(launchId: $launchId)
  }
`;

export interface TerminateInstanceArgs {
  launchId: string;
}
