import gql from 'graphql-tag';
import {
  StripeCustomer,
  TrintGroup,
  TrintUser,
  PlanDetails,
  Billing,
  V2,
} from 'types';

export type EnterpriseUserQueryResult = {
  user:
    | (Pick<TrintUser, '_id' | 'username'> & {
        currentSubscription: Pick<
          PlanDetails,
          'internalContacts' | 'contractEndDate'
        >;
        customer: Pick<StripeCustomer, 'id'>;
        group: Pick<
          TrintGroup,
          | '_id'
          | 'totalMembers'
          | 'totalSeats'
          | 'totalActiveMembers'
          | 'isScimEnabled'
        >;
        billing: Pick<Billing, 'planName' | 'plan' | 'currency'> & {
          v2: Pick<V2, 'subscriptionStatus' | 'subscriptionSeatCount'>;
        };
      })
    | undefined
    | null;
};

export interface EnterpriseUserQueryVars {
  userId: string;
}

export const ENTERPRISE_USER_QUERY = gql`
  query group($userId: String!) {
    user(userId: $userId) {
      _id
      username
      currentSubscription {
        internalContacts
        contractEndDate
      }
      group {
        _id
        totalMembers
        totalActiveMembers
        totalSeats
      }
      billing {
        currency
        plan
        planName
        v2 {
          subscriptionStatus
          subscriptionSeatCount
        }
      }
      customer {
        id
      }
    }
  }
`;

export type EnterpriseSearchQueryResult = Pick<
  TrintUser,
  '_id' | 'username'
> & {
  currentSubscription: Pick<PlanDetails, 'internalContacts'>;
  customer: Pick<StripeCustomer, 'id'>;
  group: Pick<TrintGroup, '_id' | 'totalMembers' | 'totalActiveMembers'>;
  billing: Pick<Billing, 'planName'> & {
    v2: Pick<V2, 'subscriptionStatus' | 'subscriptionSeatCount'>;
  };
};

export const ENTERPRISE_SEARCH_QUERY = gql`
  query enterpriseUsers(
    $searchText: String
    $page: Int!
    $limit: Int!
    $order: String
    $sortBy: String
  ) {
    enterpriseUsers(
      searchText: $searchText
      page: $page
      limit: $limit
      order: $order
      sortBy: $sortBy
    ) {
      list {
        _id
        username
        group {
          _id
          totalMembers
          totalActiveMembers
          totalSeats
        }
        currentSubscription {
          internalContacts
        }
        billing {
          planName
          v2 {
            subscriptionStatus
            subscriptionSeatCount
          }
        }
        customer {
          id
        }
      }
      hasMore
    }
  }
`;

export interface SeatsQueryVars {
  userId: string;
}

export type SeatsQueryResult = {
  user: Pick<TrintUser, '_id'> & {
    billing: Pick<Billing, 'planName'> & {
      v2: Pick<V2, 'subscriptionSeatCount'>;
    };
  };
};

export const SEATS_QUERY = gql`
  query seatsQuery($userId: String!) {
    user(userId: $userId) {
      _id
      billing {
        planName
        v2 {
          subscriptionSeatCount
        }
      }
    }
  }
`;

export interface PlanDetailsQueryVars {
  userId: string;
}

export type PlanDetailsQueryResult = {
  user: Pick<TrintUser, '_id'> & {
    currentSubscription: Pick<
      PlanDetails,
      'internalContacts' | 'contractEndDate'
    >;
    billing: Pick<Billing, 'planName'>;
  };
};

export const PLAN_DETAILS_QUERY = gql`
  query planDetails($userId: String!) {
    user(userId: $userId) {
      _id
      billing {
        planName
      }
      currentSubscription {
        internalContacts
        contractEndDate
      }
    }
  }
`;
