import * as React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import querystring from 'querystring';
import { createStyled } from 'shared/hocs';
import { FormatDate, ErrorDisplay } from 'shared/components';
import {
  CircularProgress,
  Paper,
  Theme,
  Typography,
  createStyles,
  Button,
} from '@material-ui/core';

const SALESFORCE_QUERY = gql`
  query salesforce {
    salesforce {
      authUri
      clientId
      connected
      connectedBy
      connectedTo
      updated
      redirectUri
    }
  }
`;

function SalesforcePage() {
  return (
    <Styled>
      {({ classes }) => (
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography
              className={classes.title}
              component="h1"
              variant="h5"
              align="center"
            >
              Salesforce
            </Typography>
            <Typography
              className={classes.subtitle}
              variant="subtitle2"
              align="center"
            >
              Trint's Integrations with Salesforce
            </Typography>
            <Query query={SALESFORCE_QUERY} fetchPolicy="cache-and-network">
              {({ loading, error, data }) => {
                if (error) {
                  return <ErrorDisplay>{error.message}</ErrorDisplay>;
                }
                if (loading && (!data || !data.salesforce))
                  return <CircularProgress />;

                const { salesforce } = data;
                const {
                  authUri,
                  clientId,
                  connected,
                  connectedBy,
                  connectedTo,
                  redirectUri,
                  updated,
                } = salesforce;
                const authParams = querystring.stringify({
                  response_type: 'code',
                  client_id: clientId,
                  redirect_uri: redirectUri,
                });
                const authUrl = `${authUri}?${authParams}`;
                return (
                  <>
                    {!updated && (
                      <Typography variant="body1" paragraph>
                        Click the button below to begin.
                      </Typography>
                    )}
                    {updated && (
                      <>
                        <Typography
                          variant="body1"
                          className={classes.integration}
                        >
                          Integration detail:
                        </Typography>
                        <Typography
                          variant="body1"
                          paragraph
                          className={classes.integrationItem}
                        >
                          • Trint User: <i>{connectedBy}</i>
                          <br />• Salesforce User:{' '}
                          <i>{connected ? connectedTo : 'n/a'}</i>
                          <br />• Date:{' '}
                          <i>
                            <FormatDate date={updated} />
                          </i>
                          <br />• Verification:{' '}
                          <i>{connected ? 'Passed' : 'Failed'}</i>
                        </Typography>
                      </>
                    )}
                    {!connected && (
                      <a href={authUrl} className={classes.link}>
                        <Button variant="contained" color="primary">
                          {updated ? 'Refresh ' : 'Start '}Integration
                        </Button>
                      </a>
                    )}
                    {connected ? (
                      <Typography className={classes.footer} variant="caption">
                        Permission may be revoked by logging into Salesforce.
                      </Typography>
                    ) : (
                      <Typography className={classes.footer} variant="caption">
                        You will be directed to Salesforce to login and
                        authorise the integration.
                      </Typography>
                    )}
                  </>
                );
              }}
            </Query>
          </Paper>
        </main>
      )}
    </Styled>
  );
}

const Styled = createStyled((theme: Theme) => {
  return createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    title: {
      marginBottom: theme.spacing.unit,
    },
    subtitle: {
      marginBottom: theme.spacing.unit * 3,
    },
    integration: {
      fontWeight: 500,
    },
    integrationItem: {
      marginLeft: '10px',
    },
    footer: {
      marginTop: theme.spacing.unit * 6,
    },
    link: {
      textDecoration: 'none',
    },
  });
});

export default SalesforcePage;
