import gql from 'graphql-tag';

const User = {
  fragments: {
    userBase: gql`
      fragment UserBase on User {
        _id
        username
        userType
      }
    `,
    userGroupInfo: gql`
      fragment UserGroupInfo on User {
        group {
          _id
          metadata {
            name
          }
          owner {
            _id
            username
          }
          admins {
            _id
          }
        }
      }
    `,
  },
};

export default User;
