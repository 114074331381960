import * as React from 'react';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { createStyled } from 'shared/hocs';
import { TableCellItem } from './TableRow';
import { Paper, Theme, createStyles } from '@material-ui/core';
import TableSorter from './TableSorter';

interface Props {
  headers: TableCellItem[];
  colWidths?: string[];
}

const Styled = createStyled((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflow: 'auto',
      minHeight: 100,
    },
    table: {
      minWidth: 900,
      tableLayout: 'fixed',
    },
    header: {
      backgroundColor: theme.palette.primary.main,
    },
    th: {
      fontSize: theme.typography.fontSize,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  }),
);

export default class Table extends React.Component<Props> {
  render() {
    const { headers = [], children, colWidths } = this.props;
    return (
      <Styled>
        {({ classes }) => (
          <Paper className={classes.root}>
            <MuiTable className={classes.table}>
              {colWidths && (
                <colgroup>
                  {colWidths.map((width, i) => (
                    <col key={i} style={{ width }} />
                  ))}
                </colgroup>
              )}
              <TableHead className={classes.header}>
                <TableRow>
                  {headers.map((h, i) => (
                    <TableCell key={i} {...h.cellProps} className={classes.th}>
                      {h.content}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{children}</TableBody>
            </MuiTable>
          </Paper>
        )}
      </Styled>
    );
  }
}

export function cell(
  content: React.ReactNode | null,
  cellProps: TableCellProps = {} as TableCellProps,
): TableCellItem {
  return {
    content,
    cellProps,
  };
}

export function header(
  label: string | null,
  sortKey?: string | null,
  cellProps?: TableCellProps,
) {
  if (!sortKey || !label) return cell(label, cellProps);
  return cell(<TableSorter sortKey={sortKey} label={label} />, cellProps);
}
