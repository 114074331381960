import * as React from 'react';
import { Theme, createStyles } from '@material-ui/core';
import { FieldProps } from 'yafl';
import { FieldValidator } from 'shared/form/validators';
import { createStyled } from 'shared/hocs';
import { TextField } from 'shared/components';
import { TextFieldProps } from '@material-ui/core/TextField';

export interface Option {
  value?: string | number;
  label: string | number;
}

interface Props<T> extends FieldProps<any, T> {
  id?: string;
  label?: string;
  type?: string;
  validators?: Array<FieldValidator<T>>;
  options: Option[];
  loading?: boolean;
}

class FormSelectInput<
  T extends string | number | boolean = string
> extends React.Component<Props<T> & TextFieldProps> {
  render() {
    const {
      meta,
      input,
      label,
      type,
      loading,
      options,
      placeholder = 'Please choose...',
      ...props
    } = this.props;
    const { isValid, visited, submitCount, errors = [] } = meta;
    const showError = !isValid && (visited || submitCount > 0);

    return (
      <>
        <Styled>
          {({ classes }) => (
            <TextField
              {...input}
              {...props}
              select
              SelectProps={{ native: true }}
              type={type}
              label={label}
              error={showError}
              helperText={errors[0]}
              placeholder={placeholder}
            >
              <option disabled>{placeholder}</option>
              {options.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          )}
        </Styled>
      </>
    );
  }
}

const Styled = createStyled((theme: Theme) =>
  createStyles({
    // error: {
    //     color: theme.palette.error.main;
    //     margin-top: 0.25rem;
    // }
  }),
);

export default FormSelectInput;
