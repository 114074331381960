import React, { useState } from 'react';
import {
  Step,
  Grid,
  TextField,
  StepLabel,
  StepContent,
  Button,
} from '@material-ui/core';
import moment from 'moment';

interface Props {
  fromDate: string;
  toDate: string;
  active: boolean;
  onNext: (from: string, to: string) => void;
}

function StepTwo(props: Props) {
  const { fromDate, toDate, onNext } = props;

  const [dateForm, setDateForm] = useState({
    from: fromDate,
    to: toDate,
  });
  const { from, to } = dateForm;

  const datesAreValid = React.useMemo(() => {
    const dtFrom = moment(from)
      .startOf('day')
      .valueOf();

    const dtTo = moment(to)
      .endOf('day')
      .valueOf();

    return dtTo > dtFrom;
  }, [from, to]);

  return (
    <Step {...props}>
      <StepLabel data-testid="step-label">
        Select a date range for the files you would like to delete
      </StepLabel>
      <StepContent {...props} data-testid="step-content">
        <Grid
          container
          alignItems="flex-start"
          justify="flex-start"
          direction="column"
        >
          <Grid item xs={1}>
            <TextField
              required
              id="step-two-input-from"
              label="From"
              name="fromDate"
              type="date"
              data-testid="step-two-input-from"
              value={from}
              onChange={e => {
                setDateForm({
                  ...dateForm,
                  from: e.target.value,
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              required
              id="step-two-input-to"
              label="To"
              name="toDate"
              type="date"
              data-testid="step-two-input-to"
              value={to}
              onChange={e => {
                setDateForm({
                  ...dateForm,
                  to: e.target.value,
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <br />
        <br />
        <Button
          variant="contained"
          color="primary"
          disabled={!datesAreValid}
          onClick={() => {
            onNext(from, to);
          }}
          data-testid={'forward-button-2'}
        >
          Next
        </Button>
      </StepContent>
    </Step>
  );
}

export default StepTwo;
