import * as React from 'react';
import { Table, TableRow, Pagination } from 'shared/components';
import { TableCellItem } from 'shared/components/TableRow';
import { EnterpriseSearchQueryResult } from 'enterprise/queries';
import EnterpriseListItem from './EnterpriseListItem';

interface Props {
  refetch: () => Promise<any>;
  colWidths: string[];
  headers: TableCellItem[];
  users: EnterpriseSearchQueryResult[];
  page: number;
  perPage: number;
  hasMore: boolean;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void;
  onChangeRowsPerPage?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
}

class EnterpriseList extends React.Component<Props> {
  render() {
    const {
      users = [],
      refetch,
      page,
      hasMore,
      headers,
      perPage,
      onChangePage,
      colWidths = [],
      onChangeRowsPerPage,
    } = this.props;
    return (
      <>
        <Table headers={headers} colWidths={colWidths}>
          {users.map((user) => (
            <TableRow<EnterpriseSearchQueryResult>
              key={user._id}
              item={user}
              refetch={refetch}
              component={EnterpriseListItem}
            />
          ))}
        </Table>
        <Pagination
          page={page}
          perPage={perPage}
          hasMore={hasMore}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </>
    );
  }
}

export default EnterpriseList;
