import * as React from 'react';
import withUserContext from 'shared/hocs/withUserContext';
import { AuthContextState } from 'shared/components/UserContext';
import { AvatarOrDefault } from 'shared/components';
import { Menu, Button, MenuItem, ClickAwayListener } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

interface Props {
  auth: AuthContextState;
}

interface State {
  anchorEl: HTMLButtonElement | null;
}

class LoginButton extends React.Component<Props, State> {
  state = { anchorEl: null };

  closeProfileMenu = (): void => {
    this.setState({ anchorEl: null });
  };

  openProfileMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  render() {
    const { auth } = this.props;
    const { anchorEl } = this.state;

    if (auth.signingIn) {
      return null;
    }

    if (!auth.isAuthorised) {
      return (
        <Tooltip title="Log in using your Trint Google ID" placement="left">
          <Button variant="outlined" color="primary" onClick={auth.login}>
            Log in
          </Button>
        </Tooltip>
      );
    }

    return (
      <>
        <Menu
          id="menu-appbar"
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={this.closeProfileMenu}
          aria-label="Account Menu"
        >
          <ClickAwayListener onClickAway={this.closeProfileMenu}>
            <Tooltip title="Log out of Unicorn" placement="left">
              <MenuItem onClick={auth.logout}>Log out</MenuItem>
            </Tooltip>
          </ClickAwayListener>
        </Menu>
        <Tooltip title="Account Menu" placement="bottom">
          <AvatarOrDefault
            onClick={this.openProfileMenu}
            ariaOwns={anchorEl ? 'menu-appbar' : undefined}
            src={auth.profile ? auth.profile.picture : null}
          />
        </Tooltip>
      </>
    );
  }
}

export default withUserContext(LoginButton);
