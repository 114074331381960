import * as React from 'react';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {
  createStyles,
  IconButton,
  Theme,
  Select,
  MenuItem,
} from '@material-ui/core';
import { createStyled } from 'shared/hocs';

interface Props {
  page: number;
  hasMore: boolean;
  perPage: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void;
  onChangeRowsPerPage?: React.ChangeEventHandler<any>;
}

const Styles = createStyled((theme: Theme) =>
  createStyles({
    right: {
      float: 'right',
    },
    iconSmall: {
      fontSize: 20,
    },
  }),
);

class Pagination extends React.Component<Props> {
  handlePreviousButtonClick = (e: React.MouseEvent<any>) => {
    const { page, onChangePage } = this.props;
    if (page === 0) return;
    onChangePage(e, page - 1);
  };

  handleFirstPageButtonClick = (e: React.MouseEvent<any>) => {
    const { page, onChangePage } = this.props;
    if (page === 0) return;
    onChangePage(e, 0);
  };

  handleNextButtonClick = (e: React.MouseEvent<any>) => {
    const { page, hasMore } = this.props;
    if (!hasMore) return;
    this.props.onChangePage(e, page + 1);
  };

  render() {
    const { page, hasMore, perPage, onChangeRowsPerPage } = this.props;

    return (
      <Styles>
        {({ classes }) => (
          <>
            <IconButton
              disabled={page === 0}
              aria-label="First page"
              onClick={this.handleFirstPageButtonClick}
            >
              <FirstPageIcon className={classes.iconSmall} />
            </IconButton>
            <IconButton
              disabled={page === 0}
              aria-label="Previous Page"
              onClick={this.handlePreviousButtonClick}
            >
              <KeyboardArrowLeft className={classes.iconSmall} />
            </IconButton>
            <Select
              disableUnderline
              value={perPage}
              onChange={onChangeRowsPerPage}
            >
              {[25, 50, 100].map(x => {
                return (
                  <MenuItem key={x} value={x}>
                    {x}
                  </MenuItem>
                );
              })}
            </Select>
            <IconButton
              aria-label="Next Page"
              disabled={!hasMore}
              className={classes.right}
              onClick={this.handleNextButtonClick}
            >
              <KeyboardArrowRight className={classes.iconSmall} />
            </IconButton>
          </>
        )}
      </Styles>
    );
  }
}

export default Pagination;
