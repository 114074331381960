import * as React from 'react';
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField';

const noop = () => {};

interface Props {
  onEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

class TextField extends React.Component<Props & TextFieldProps> {
  onKeyDown = (e: React.KeyboardEvent<any>) => {
    const { onEnter = noop, onKeyDown = noop } = this.props;
    onKeyDown(e);
    if (e.keyCode === 13) {
      onEnter(e);
    }
  };

  render() {
    const { onEnter, ...rest } = this.props;
    return <MuiTextField {...rest} onKeyDown={this.onKeyDown} />;
  }
}

export default TextField;
