import * as React from 'react';
import { DialogTitle, DialogContent, Button } from '@material-ui/core';
import { BaseModalProps } from 'contexts/ModalTrigger';

interface Props extends BaseModalProps {
  onNope: () => void;
  onConfirm: () => void;
  title?: string;
  message?: string;
}

const noop = () => {};

class ConfirmDialog extends React.Component<Props> {
  handleConfirm = (e: any) => {
    const { closeModal, onConfirm = noop } = this.props;
    onConfirm();
    closeModal(e);
  };

  handleNope = (e: any) => {
    const { closeModal, onNope = noop } = this.props;
    onNope();
    closeModal(e);
  };

  render() {
    return (
      <>
        <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          {this.props.message}
          <Button onClick={this.handleConfirm} color="primary" variant="contained" style={{margin: '5px'}}>Confirm</Button>
          <Button onClick={this.handleNope} variant="contained" style={{ margin: '5px'}}>Cancel</Button>
        </DialogContent>
      </>
    );
  }
}

export default ConfirmDialog;
