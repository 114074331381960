import * as React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Warning } from '@material-ui/icons';
import { TableRow, TableCell, Theme, createStyles } from '@material-ui/core';
import { StripeCustomerButton } from 'shared/components';
import { createStyled } from 'shared/hocs';
import { EnterpriseSearchQueryResult } from 'enterprise/queries';
import EnterpriseAccountActionsMenu from './EnterpriseAccountActionsMenu';

interface Props {
  refetch: () => Promise<void>;
  userId: string;
  item: EnterpriseSearchQueryResult;
}

function EnterpriseListItem(props: Props) {
  const { item } = props;

  const userId = item._id;
  const customerId = item.customer.id;
  const username = item.username;
  const enterpriseName = item.billing.planName ?? 'Unknown';
  const subscriptionSeatCount = item.billing.v2.subscriptionSeatCount ?? 1;
  const subscriptionStatus = item.billing.v2.subscriptionStatus ?? 'No status';
  const organisationId = item.group?._id;
  const organisationActiveMembersCount = item.group?.totalActiveMembers ?? 1;

  const internalContactsArray = item.currentSubscription.internalContacts ?? [];
  const internalContacts = internalContactsArray.length
    ? internalContactsArray.join(' ')
    : 'No contacts';

  const overSeatLimit = organisationActiveMembersCount > subscriptionSeatCount;

  return (
    <Styled>
      {({ classes }) => (
        <TableRow>
          <TableCell>
            <Link
              to={`/enterprises/${item._id}/account`}
              title="Account"
              className={classnames(classes.link)}
            >
              {enterpriseName}
            </Link>
          </TableCell>
          <TableCell>
            <StripeCustomerButton customerId={customerId} />
            {username}
          </TableCell>
          <TableCell>
            <p className={classes.seatCountStatus}>
              <span className={classes.seatCountStatusLabel}>
                {organisationActiveMembersCount} of {subscriptionSeatCount}
              </span>
              {overSeatLimit && (
                <Warning className={classes.seatCountStatusIcon} />
              )}
            </p>
          </TableCell>
          <TableCell>{subscriptionStatus}</TableCell>
          <TableCell>{internalContacts}</TableCell>
          <TableCell>
            {organisationId ? (
              <Link
                to={`organisations/${organisationId}/details`}
                title="Organisation"
                className={classnames(classes.link)}
              >
                {organisationId}
              </Link>
            ) : (
              'No Organisation'
            )}
          </TableCell>
          <TableCell>
            <EnterpriseAccountActionsMenu userId={userId} />
          </TableCell>
        </TableRow>
      )}
    </Styled>
  );
}

const Styled = createStyled((theme: Theme) =>
  createStyles({
    seatCountStatus: {
      verticalAlign: 'bottom',
      lineHeight: '24px',
      whiteSpace: 'nowrap',
    },
    seatCountStatusLabel: {
      display: 'inline-block',
      minWidth: '40px',
    },
    seatCountStatusIcon: {
      verticalAlign: 'inherit',
      padding: '4px',
      color: '#fed000',
    },
    link: {
      textDecoration: 'underline',
      color: '#000',
      '&visited': {
        textDecoration: 'none',
      },
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
      },
    },
  }),
);

export default EnterpriseListItem;
