import * as React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { Mutation, MutationFunction, Query } from 'react-apollo';
import { createFormContext } from 'yafl';
import { USER_BALLANCE_QUERY, UserBalanceQueryResult } from 'users/queries';
import { USER_ALLOCATE_BONUS } from 'users/mutations';
import { reasonOptions } from 'shared/refdata';
import reasons from 'shared/refdata/refundReasons';
import AddIcon from '@material-ui/icons/AddBox';
import RemoveIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import { getTimeComponents } from 'shared/utils/time';
import { FormTextInput, FormSelectInput, FormKit } from 'shared/formKit';
import { BaseModalProps, ModalTrigger } from 'contexts/ModalTrigger';

interface ComponentProps {
  userId: string;
}

interface MutationVariables {
  userId: string;
  minutes: number;
  reason: number;
}

interface Data {
  user: UserBalanceQueryResult;
}

interface Variables {
  userId: string;
}

interface FormData {
  hours: number | '';
  minutes: number | '';
  reason?: number;
  isAddition: boolean;
}

interface InjectedProps {
  allocateBonus: MutationFunction<Data, MutationVariables>;
}

type Props = ComponentProps &
  BaseModalProps & {
    user: UserBalanceQueryResult;
  };

const toMinutes = (hours: number | '', minutes: number | '') => {
  const h = typeof hours === 'number' ? hours : 0;
  const m = typeof minutes === 'number' ? minutes : 0;
  return h * 60 + m;
};

const validateTime = ({ hours, minutes }: FormData) => {
  if (toMinutes(hours, minutes) <= 0) {
    return 'Please supply 1 or more minutes.';
  }
  return undefined;
};

const context = createFormContext<FormData>();

const { Field, Validator } = context;

const initialValue = { hours: 0, minutes: 0, isAddition: true };
const defaultValue = initialValue;

class Inner extends React.Component<Props & InjectedProps> {
  handleSubmit = (value: FormData) => {
    const { userId, allocateBonus, closeModal } = this.props;
    const mins = toMinutes(value.hours, value.minutes);
    const minutes = value.isAddition ? mins : mins * -1;
    allocateBonus({
      variables: {
        userId,
        minutes,
        reason: value.reason || reasons.OTHER_REASON,
      },
    })
      .then(closeModal)
      .catch();
  };

  onMinuteChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (value === '') return;

    const parsed = parseFloat(value);
    if (isNaN(parsed) || parsed > 59 || parsed < 0) {
      e.preventDefault();
    }
  };

  onHourChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (value === '') return;

    const parsed = parseFloat(value);
    if (isNaN(parsed) || parsed < 0) {
      e.preventDefault();
    }
  };

  render() {
    const { user, closeModal } = this.props;
    const timeRemaining = getTimeComponents(user.allowance.balance);

    return (
      <FormKit<FormData>
        context={context}
        initialValue={initialValue}
        defaultValue={defaultValue}
        onSubmit={this.handleSubmit}
      >
        {({ formValue, formIsValid, submit }) => (
          <>
            <DialogTitle id="form-dialog-title">
              Add Trinting Time ({user.username})
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={24}>
                <Grid item xs={12} sm={12}>
                  <DialogContentText>
                    Add Trinting time to a users account.
                  </DialogContentText>
                  <Typography>
                    Current balance: {timeRemaining.hours} hour(s)
                    {', '}
                    {timeRemaining.minutes} minute(s)
                    {', '}
                    {timeRemaining.seconds} second(s)
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    fullWidth
                    name="hours"
                    label="Hours"
                    type="number"
                    onChange={this.onHourChanged}
                    component={FormTextInput}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    fullWidth
                    name="minutes"
                    label="Minutes"
                    type="number"
                    onChange={this.onMinuteChanged}
                    component={FormTextInput}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field<boolean>
                    fullWidth
                    name="isAddition"
                    render={({ input, meta }) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RemoveIcon />}
                              checkedIcon={<AddIcon />}
                              value={input.name}
                              checked={input.value}
                              onChange={e => {
                                meta.setValue(checked => !checked);
                              }}
                            />
                          }
                          label={input.value ? 'Add time' : 'Subtract time'}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="reason"
                    label="Reason"
                    component={FormSelectInput}
                    options={reasonOptions}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <Validator msg={validateTime(formValue)} path={['seconds']} />
            <DialogActions>
              <Button onClick={closeModal} color="primary">
                Cancel
              </Button>
              <ModalTrigger<'ConfirmDialog'>
                modal="ConfirmDialog"
                modalProps={{
                  onConfirm: submit,
                  title: formValue.isAddition
                    ? 'Are you sure you want to ADD time to this user?'
                    : 'Are you sure you want to REMOVE time from this user?',
                }}
              >
                {({ openModal }) => (
                  <Button
                    disabled={!formIsValid}
                    onClick={openModal}
                    color="primary"
                    variant="contained"
                  >
                    Done
                  </Button>
                )}
              </ModalTrigger>
            </DialogActions>
          </>
        )}
      </FormKit>
    );
  }
}

class AllocateMinutesModal extends React.Component<Props> {
  render() {
    const { userId, openModal, closeModal } = this.props;
    return (
      <Query<Data, Variables>
        query={USER_BALLANCE_QUERY}
        fetchPolicy="cache-and-network"
        variables={{ userId }}
      >
        {({ error, loading, data }) => {
          if (error) return null;
          if (loading && (!data || !data.user)) return null;

          return (
            <Mutation<Data, MutationVariables>
              mutation={USER_ALLOCATE_BONUS}
              refetchQueries={[
                { query: USER_BALLANCE_QUERY, variables: { userId } },
              ]}
            >
              {allocateBonus => (
                <Inner
                  user={data!.user}
                  userId={userId}
                  openModal={openModal}
                  closeModal={closeModal}
                  allocateBonus={allocateBonus}
                />
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default AllocateMinutesModal;
