import React from 'react';
import { Mutation, MutationFunction } from 'react-apollo';
import { createFormContext } from 'yafl';
import { CREATE_ORGANISATION_MUTATION } from '../mutations';
import FormTextInput from 'shared/formKit/FormTextInput';
import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { FormKit } from 'shared/formKit';
import { ModalTrigger } from 'contexts/ModalTrigger';
import PagedUserSelect from 'users/components/PagedUserSelect';
import CustomToast from 'shared/components/CustomToast';

interface FormData {
  name: string;
  ownerId: string;
}

const context = createFormContext<FormData>();
const { Field } = context;

function OrganisationCreateModal(props: any) {
  const { onClose, isOpen, onCreate } = props;
  const dto = {
    name: '',
    ownerId: '',
  };
  function handleSubmit(
    value: FormData,
    createOrganisation: MutationFunction<any, FormData>,
  ) {
    createOrganisation({
      variables: {
        name: value.name,
        ownerId: value.ownerId,
      },
    }).catch();
  }

  function onCompletion() {
    onCreate();
    onClose();
  }

  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth>
      <Mutation
        onCompleted={onCompletion}
        mutation={CREATE_ORGANISATION_MUTATION}
      >
        {(mutation, { error: mutationError }) => {
          return (
            <>
              <FormKit<FormData>
                context={context}
                initialValue={dto}
                defaultValue={dto}
              >
                {({ formValue, formIsValid, setFormValue }) => {
                  return (
                    <>
                      <DialogTitle id="form-dialog-title">
                        Create an organisation
                      </DialogTitle>
                      <DialogContent>
                        <Grid container spacing={24}>
                          <Grid item xs={6} sm={12}>
                            <Field
                              fullWidth
                              name="name"
                              label="Organisation Name"
                              component={FormTextInput}
                            />
                          </Grid>
                          <Grid item xs={6} sm={12}>
                            <PagedUserSelect
                              userId={formValue.ownerId}
                              onSelectionChanged={value =>
                                setFormValue(() => ({
                                  ...formValue,
                                  ownerId: value,
                                }))
                              }
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>

                      <DialogActions>
                        <Button onClick={onClose} color="primary">
                          Cancel
                        </Button>
                        <ModalTrigger<'ConfirmDialog'>
                          modal="ConfirmDialog"
                          modalProps={{
                            onConfirm: () => handleSubmit(formValue, mutation),
                            title:
                              'Are you sure you want to create this group with specified owner?',
                          }}
                        >
                          {({ openModal }) => (
                            <Button
                              disabled={!formIsValid}
                              onClick={openModal}
                              color="primary"
                              variant="contained"
                            >
                              Done
                            </Button>
                          )}
                        </ModalTrigger>
                      </DialogActions>
                    </>
                  );
                }}
              </FormKit>
              {mutationError && (
                <CustomToast message={mutationError.message} isOpen />
              )}
            </>
          );
        }}
      </Mutation>
    </Dialog>
  );
}

export default OrganisationCreateModal;
