import React from 'react';
import { TableCell, Typography } from '@material-ui/core';
interface Props {
  content?: string;
  style?: {
    [key: string]: string;
  };
}
export const ListCell = ({ content, style }: Props) => {
  return (
    <TableCell align="left" style={style || undefined}>
      <Typography>{content}</Typography>
    </TableCell>
  );
};
