import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { createFormContext } from 'yafl';
import { FormTextInput } from 'shared/formKit';
import gql from 'graphql-tag';

import { createStyled } from 'shared/hocs';
import {
  Paper,
  Theme,
  Typography,
  createStyles,
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import RaiseAlertModal from '../incidents/RaiseAlertModal';
import CustomToast from 'shared/components/CustomToast';

const RAISE_ALERT_MUTATION = gql`
  mutation raiseAlert($title: String, $description: String, $priority: String) {
    raiseAlert(title: $title, description: $description, priority: $priority) {
      success
    }
  }
`;

interface FormData {
  title: string;
  description: string;
}

const { Form, Field } = createFormContext<FormData>();

const formData: FormData = {
  title: '',
  description: '',
};

interface RaiseAlertResponse {
  raiseAlert: {
    success: boolean;
  };
}

function IncidentsPage() {
  const [
    raiseAlert,
    { data, loading, error },
  ] = useMutation<RaiseAlertResponse>(RAISE_ALERT_MUTATION);
  const [isConfirmOpen, setConfirmOpen] = useState<boolean>(false);

  const { success } = data?.raiseAlert ?? {};

  const onSubmit = async (formValue: FormData) => {
    const { title, description } = formValue;
    setConfirmOpen(false);

    try {
      await raiseAlert({
        variables: {
          title,
          description,
          priority: 'P1',
        },
      });
    } catch (error) {
      console.error({ error });
    }
  };

  return (
    <Styled>
      {({ classes }) => (
        <main className={classes.layout}>
          <Form
            initialValue={formData}
            onSubmit={(formValue) => {
              onSubmit(formValue);
            }}
          >
            {(yafl) => (
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  yafl.submit();
                }}
              >
                <Paper className={classes.paper}>
                  <Typography
                    className={classes.title}
                    component="h1"
                    variant="h5"
                    align="center"
                  >
                    Incidents
                  </Typography>
                  <Typography
                    className={classes.subtitle}
                    variant="subtitle2"
                    align="center"
                  >
                    Raise OpsGenie Alerts when Trint is in Peril
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Complete the form below to raise an alert.
                  </Typography>
                  <Grid item xs={12} sm={6}>
                    <Field
                      fullWidth
                      name="title"
                      label="Title"
                      type="text"
                      component={FormTextInput}
                      validate={(title) => {
                        if (`${title}`.length < 5) {
                          return 'At least 5 characters';
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      fullWidth
                      name="description"
                      label="Description"
                      type="text"
                      component={FormTextInput}
                      validate={(description) => {
                        if (`${description}`.length < 20) {
                          return 'At least 20 characters';
                        }
                      }}
                    />
                  </Grid>
                  <Typography
                    variant="body1"
                    paragraph
                    className={classes.content}
                  >
                    Pressing the button below will alert the on-call engineers.
                  </Typography>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={loading || !yafl.formIsValid}
                      onClick={() => setConfirmOpen(true)}
                    >
                      Raise Alert
                    </Button>
                    {loading && (
                      <span
                        style={{ marginLeft: '16px', verticalAlign: 'bottom' }}
                      >
                        <CircularProgress
                          style={{ width: '36px', height: '36px' }}
                        />
                      </span>
                    )}
                  </Grid>
                </Paper>
                <RaiseAlertModal
                  isOpen={isConfirmOpen}
                  onClose={() => setConfirmOpen(false)}
                  onSubmit={() => {
                    yafl.submit();
                    yafl.resetForm();
                  }}
                  title={yafl.formValue.title}
                  description={yafl.formValue.description}
                />
                {success && <CustomToast message={'ALERT RAISED!'} isOpen />}
              </form>
            )}
          </Form>
          {error && <CustomToast message={error!.message ?? ''} isOpen />}
        </main>
      )}
    </Styled>
  );
}

const Styled = createStyled((theme: Theme) => {
  return createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    title: {
      marginBottom: theme.spacing.unit,
    },
    subtitle: {
      marginBottom: theme.spacing.unit * 3,
    },
    content: {
      marginTop: theme.spacing.unit * 6,
    },
  });
});

export default IncidentsPage;
