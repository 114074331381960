import React from 'react';
import { formatDate } from 'shared/utils';

interface Props {
  date: string;
  format?: string;
}

const FormatDate: React.SFC<Props> = ({
  date,
  format = 'dd LLL yyyy HH:mm',
}) => <>{formatDate(date, format)}</>;

export default FormatDate;
