import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, TableCell, TableRow, CircularProgress } from '@material-ui/core';
import Table, * as create from '../../../shared/components/Table';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const INDENT_PADDING = 20;
interface Props {
  userId: string;
}
interface Project {
  _id: string;
  projectName: string;
  parentId: string;
  isRoot: boolean;
  created: number;
  projects: string[];
  trints: string[];
}

interface ProjectWithIndent extends Project {
  indent: number;
}

interface GetProjectsRes {
  projects: Project[];
}

const headers = [create.header('Project')];

const colWidths = ['100%'];

const GET_PROJECTS = gql`
  query getProjects($userId: String!) {
    projects(userId: $userId) {
      _id
      projectName
      isRoot
      trints
      parentId
      projects
      updated
    }
  }
`;

export function generateProjectHierarchy(
  projects: Project[],
): ProjectWithIndent[] {
  const [root] = projects.filter((project) => project.isRoot);
  const projectsById: Project[] = [];

  // get ids of folders in root folder, build cheeky ById map along the way:
  root.projects = projects
    .filter((project) => {
      projectsById[project._id] = project;
      return !project.isRoot && !project.parentId;
    })
    .map((project) => project._id);

  const projectsInOrder: ProjectWithIndent[] = [];
  // recursive function to generate project tree
  const decorateWithIndent = (project: Project, indent: number) => {
    const decoratedProject = { ...project, indent };
    if (decoratedProject._id === 'home') {
      decoratedProject.projectName = 'Root folder';
    }
    projectsInOrder.push(decoratedProject);
    project.projects.forEach((subprojectId: string) => {
      const subproject = projectsById[subprojectId];
      decorateWithIndent(subproject, indent + 1);
    });
  };

  decorateWithIndent(root, 0);
  return projectsInOrder;
}

function ProjectFolderListView(props: Props) {
  const { userId } = props;
  const { loading, error, data } = useQuery<GetProjectsRes>(GET_PROJECTS, {
    variables: {
      userId,
    },
  });
  if (error) {
    return <div>{error.message}</div>;
  }
  if (loading && !data) {
    return <CircularProgress />;
  }

  let projectList: any = [];
  if (data!.projects) {
    projectList = generateProjectHierarchy(data!.projects);
  }
  return (
    <>
      <Grid
        container
        alignItems="center"
        justify="space-around"
        direction="row"
      >
        <h3>User Project Folders</h3>
      </Grid>
      <Grid container>
        <Table headers={headers} colWidths={colWidths}>
          {projectList.map((project: ProjectWithIndent) => {
            const indentPadding =
              (project.indent * INDENT_PADDING).toString() + 'px';
            const line = `${project.projectName} (${project.trints.length} files)`;

            return (
              <TableRow data-testid="story-row" key={project._id}>
                <TableCell>
                  <Link
                    to={{
                      pathname: `/users/${userId}/files`,
                      search: `?project=${project._id}`,
                    }}
                    title="Folder contents"
                  >
                    <span style={{ paddingLeft: indentPadding }}>{line}</span>
                  </Link>
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      </Grid>
    </>
  );
}

export default ProjectFolderListView;
