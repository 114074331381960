import gql from 'graphql-tag';
import { Plan } from 'types';
import Plans from 'fragments/Plans';

export type GetPlansQueryResult = Plan;
export const GET_PLANS_QUERY = gql`
  query {
    getPlans {
      ...PlansBase
    }
  }
  ${Plans.fragments.plansBase}
`;
