import * as React from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import { Button } from '@material-ui/core';
import { QueryRouteComponentProps } from 'shared/components/QueryRoute';
import { GET_PLANS_QUERY, GetPlansQueryResult } from 'plans/queries';
import ListPage, {
  ListPageRenderProps,
  SortDirection,
} from 'shared/components/ListPage';
import { TableRow } from 'shared/components';
import Table, * as create from 'shared/components/Table';
import { ListViewLayout, ListViewSearchArea } from 'shared/layout';
import PlanListItem from 'plans/components/PlansListItem';

type Props = QueryRouteComponentProps<any> & ListPageRenderProps<any>;
type PlanItem = GetPlansQueryResult;

interface QueryRes {
  getPlans: PlanItem[];
}

function sortAsc(a: PlanItem, b: PlanItem) {
  return (a.currentPlan ? 1 : 0) - (b.currentPlan ? 1 : 0);
}

function sortDesc(a: PlanItem, b: PlanItem) {
  return sortAsc(a, b) * -1;
}

function getSort(order?: SortDirection) {
  return order === 'asc' ? sortAsc : sortDesc;
}

const UPDATE_PLANS_FROM_STRIPE_MUTATION = gql`
  mutation syncPlansFromStripe {
    syncPlansFromStripe
  }
`;

class Inner extends React.Component<Props> {
  render() {
    const headers = [
      create.header('Plan ID'),
      create.header('Title'),
      create.header('Plan Type'),
      create.header('Plan Code'),
      create.header('Legacy/Current', 'plan'),
    ];
    const colWidths = ['25%', '20%', '15%', '25%', '15%'];

    return (
      <ListViewLayout>
        <ListViewSearchArea>
          <Mutation mutation={UPDATE_PLANS_FROM_STRIPE_MUTATION}>
            {(updatePLansFromStripe, { loading, error }) => {
              return (
                <Button
                  onClick={async () => {
                    await updatePLansFromStripe();
                  }}
                  variant="contained"
                  color="primary"
                >
                  {loading ? 'Syncing plans' : 'Sync plans from Stripe'}
                </Button>
              );
            }}
          </Mutation>
        </ListViewSearchArea>

        <Query<QueryRes>
          query={GET_PLANS_QUERY}
          fetchPolicy="cache-and-network"
        >
          {({ data, error, loading }) => {
            if (error) return null;
            if (loading && (!data || !data.getPlans)) return null;

            const { getPlans } = data!;

            const { order } = this.props.paging;
            const sort = getSort(order);
            const plans = order ? getPlans.sort(sort) : getPlans;
            return (
              <Table headers={headers} colWidths={colWidths}>
                {plans.map(x => (
                  <TableRow<GetPlansQueryResult>
                    key={x.planId}
                    item={x}
                    component={PlanListItem}
                  />
                ))}
              </Table>
            );
          }}
        </Query>
      </ListViewLayout>
    );
  }
}

const sortKeys = ['currentPlan'];
const PlansListPage: React.SFC<Props> = props => {
  return (
    <ListPage<never> sortKeys={sortKeys}>
      {query => <Inner {...query} {...props} />}
    </ListPage>
  );
};

export default PlansListPage;
