import * as React from 'react';
import { createStyled } from 'shared/hocs';
import {
  Grid,
  Paper,
  Theme,
  Typography,
  createStyles,
} from '@material-ui/core';
import UnicornTrivia from 'shared/components/UnicornTrivia';

function WelcomeView() {
  return (
    <Styled>
      {({ classes }) => (
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography
              className={classes.title}
              component="h1"
              variant="h5"
              align="center"
            >
              <span role="img" aria-label="Unicorn">
                🦄
              </span>{' '}
              Unicorn{' '}
              <span role="img" aria-label="Unicorn" className="mirror">
                🦄
              </span>
            </Typography>
            <Typography
              className={classes.subtitle}
              variant="subtitle2"
              align="center"
            >
              Trint's Support & Maintenance System
            </Typography>
            <Typography variant="body1" paragraph>
              Welcome to Unicorn, where you may perform a variety of support
              tasks on our Individual users & Enterprise organisations. These
              tasks are primarily maintenance based, such as adjusting a user's
              credit or identifying problems and resolving them.
            </Typography>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.tips} variant="body1">
                  ⇖ Navigate using sidebar
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  className={classes.tips}
                  variant="body1"
                  align="right"
                >
                  Search for users ⇗
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography className={classes.tips} variant="body1">
                  ⇖ Search for users, organisations & plans
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  className={classes.tips}
                  variant="body1"
                  align="right"
                >
                  Log out ⇗
                </Typography>
              </Grid>
            </Grid>
            <Typography className={classes.footer} variant="caption">
              Unicorn is not a reporting tool.
            </Typography>
          </Paper>
          <UnicornTrivia />
        </main>
      )}
    </Styled>
  );
}

const Styled = createStyled((theme: Theme) => {
  return createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    title: {
      marginBottom: theme.spacing.unit,
    },
    subtitle: {
      marginBottom: theme.spacing.unit * 3,
    },
    tips: {
      fontWeight: 500,
    },
    footer: {
      marginTop: theme.spacing.unit * 6,
    },
  });
});

export default WelcomeView;
