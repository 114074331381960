import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Theme, MuiThemeProvider } from '@material-ui/core';
import { defaultTheme } from 'shared/themes';

interface RouteTheme {
  [key: string]: Theme;
}

interface Props extends RouteComponentProps {
  themes: RouteTheme;
}

class ThemeSetter extends React.Component<Props> {
  getTheme = (): Theme => {
    const { themes, location } = this.props;
    const { pathname } = location;

    if (pathname.startsWith('/users')) {
      return themes.usersTheme;
    }

    if (pathname.startsWith('/organisations')) {
      return themes.organisationsTheme;
    }

    if (pathname.startsWith('/plans')) {
      return themes.plansTheme;
    }

    return defaultTheme;
  };

  render() {
    const { children } = this.props;
    return (
      <MuiThemeProvider theme={this.getTheme()}>{children}</MuiThemeProvider>
    );
  }
}

export default withRouter(ThemeSetter);
