import qs, { IParseOptions, IStringifyOptions } from 'qs';

export type ParseOptions = IParseOptions;
export type StringifyOptions = IStringifyOptions;

const defaultParseOptions: ParseOptions = {
  decoder: decodeQueryString,
  ignoreQueryPrefix: true,
};

const defaultStringifyOptions: StringifyOptions = {
  skipNulls: true,
  sort: sortAlphabetical,
  indices: false,
};

const keywords = {
  true: true,
  false: false,
  null: null,
  [undefined!]: undefined,
};

export function parse<T extends object>(
  search: string,
  options: ParseOptions = {},
): T {
  return qs.parse(search, { ...defaultParseOptions, ...options });
}

export function stringify<T extends object>(
  obj: T,
  options: StringifyOptions = {},
): string {
  return qs.stringify(obj, { ...defaultStringifyOptions, ...options });
}

export function getRouteWithQuery<T extends object>(
  path: string,
  queryDefinition: T,
): string {
  return `${path}?${stringify<T>(queryDefinition)}`;
}

export function mergeIntoQuery<T extends object>(
  currentSearch: string,
  queryDefinition: T,
): T {
  const current = parse<T>(currentSearch);
  // spreading generic types is something TS is working on
  return { ...(current as object), ...(queryDefinition as object) } as T;
}

function decodeQueryString(value: string) {
  // there might be an scenario where we dont want to parse a number ie phone numbers
  if (/^(?:-?\d+)?$/.test(value)) {
    return parseFloat(value);
  }

  if (value in keywords) {
    return keywords[value];
  }

  return decodeURIComponent(value);
}

function sortAlphabetical(a: string, b: string) {
  return a.localeCompare(b);
}
