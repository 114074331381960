import auth0 from 'auth0-js';
import config from 'config';
import { local } from '../utils/storage';
import { isString } from 'shared/utils';
import { UserProfile, AuthData } from './types';
import { tokenIsValid, getExpiry } from './utils';

const auth = new auth0.WebAuth({
  domain: config.AUTH0_TENANT,
  clientID: 'rHxufHyuc6gE6C8XIunCS38RiMlPg7ua',
  redirectUri: `${config.APP_URL}/${config.AUTH_REDIRECT_URL}`,
  responseType: 'token id_token',
  scope: 'openid profile',
  audience: 'unicorn',
});

export const getAuthData = () => local.getItem('authData');
export const getProfile = () => local.getItem('profile');

export const login = () => auth.authorize();

export const logout = () => {
  local.clear();
  auth.logout({ returnTo: config.APP_URL });
};

export const getUserProfile = async (): Promise<UserProfile> => {
  const authData = getAuthData();
  if (tokenIsValid(authData)) {
    const { accessToken } = authData;
    const user = await fetchAuth0UserProfile(accessToken);
    return local.setItem('profile', user);
  }
  return Promise.reject(new Error('Unauthenticated'));
};

export const handleAuthResponse = (hash: string): Promise<AuthData> => {
  return new Promise((resolve, reject) => {
    auth.parseHash({ hash }, (err, res) => {
      if (err) return reject(err);

      const authData: AuthData = {
        ...res,
        expiresAt: res && res.expiresIn ? getExpiry(res.expiresIn) : 0,
      };

      local.setItem('authData', authData);

      resolve(authData);
    });
  });
};

function fetchAuth0UserProfile(accessToken?: string): Promise<UserProfile> {
  return new Promise((resolve, reject) => {
    if (!isString(accessToken)) {
      return Promise.reject(new Error('Unauthenticated'));
    }

    return auth.client.userInfo(accessToken, (err, user) => {
      if (err) return reject(err);
      return resolve(user);
    });
  });
}

export * from './types';
