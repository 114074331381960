import * as React from 'react';
import { TableRow, TableCell, IconButton, Tooltip } from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyIcon from '@material-ui/icons/FileCopy';
import { GetPlansQueryResult } from 'plans/queries';

type PlanItem = GetPlansQueryResult;
interface Props {
  planId: string;
  item: PlanItem;
  onRowClick: (
    e: React.MouseEvent<HTMLTableRowElement>,
    item: PlanItem,
  ) => void;
}

interface State {
  open: boolean;
}

class PlanListItem extends React.Component<Props, State> {
  state = { open: false };

  onCopyPlanId = () => {
    this.setState({ open: true });
    setTimeout(() => {
      this.setState({ open: false });
    }, 1500);
  };

  render() {
    const { item } = this.props;
    const { open } = this.state;
    return (
      <TableRow>
        <TableCell>
          <CopyToClipboard text={item.planId} onCopy={this.onCopyPlanId}>
            <IconButton aria-label="Copy to Clipboard">
              <CopyIcon fontSize="small" />
            </IconButton>
          </CopyToClipboard>
          <Tooltip placement="right" open={open} title="Copied!">
            <span>{item.planId}</span>
          </Tooltip>
        </TableCell>
        <TableCell>{item.title}</TableCell>
        <TableCell>{item.planType}</TableCell>
        <TableCell>{item.planCode}</TableCell>
        <TableCell>{item.currentPlan ? 'Current' : 'Legacy'}</TableCell>
      </TableRow>
    );
  }
}
export default PlanListItem;
