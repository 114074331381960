import React from 'react';
import { Mutation, MutationFunction } from 'react-apollo';
import { createFormContext } from 'yafl';
import { Address4, Address6 } from 'ip-address';

import FormTextInput from 'shared/formKit/FormTextInput';
import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  Grid,
  Link,
} from '@material-ui/core';
import { FormKit } from 'shared/formKit';
import CustomToast from 'shared/components/CustomToast';
import {
  LaunchInstanceArgs,
  LaunchInstanceResult,
  LAUNCH_INSTANCE,
} from 'ec2launcher/mutations';
import LaunchTemplateSelect from './LaunchTemplateSelect';
import { ipAddressListValidator } from 'ec2launcher/components/validators/ipAddressList';
import { ec2LaunchInstanceUserKeyValidator } from 'ec2launcher/components/validators/ec2LaunchInstanceUserKey';
import { ModalTrigger } from 'contexts/ModalTrigger';

interface FormData {
  userKey: string;
  launchTemplate: string;
  userIpAddressList: string;
}

const context = createFormContext<FormData>();
const { Field } = context;

export function LaunchInstanceModal(props: any) {
  const { onClose, isOpen, onCreate } = props;
  const dto: FormData = {
    userKey: '',
    launchTemplate: '',
    userIpAddressList: '',
  };
  function formatIpAddresses(ipAddressList: string): string[] {
    const ipAddresses = (ipAddressList?.trim()?.split(/[ ,]+/) ?? []).filter(
      (ip) => !!ip,
    );
    return ipAddresses.map((ip) => {
      try {
        return new Address4(ip).correctForm();
      } catch (e) {
        try {
          return new Address6(ip).correctForm();
        } catch (e) {
          // Passed form validation so this should never happen
          return '';
        }
      }
    });
  }

  function handleSubmit(
    value: FormData,
    launchInstance: MutationFunction<LaunchInstanceResult, LaunchInstanceArgs>,
  ) {
    const ipAddresses = formatIpAddresses(value.userIpAddressList);
    launchInstance({
      variables: {
        userKey: value.userKey,
        launchTemplate: value.launchTemplate,
        userIpAddresses: ipAddresses,
      },
    }).catch();
  }

  function onCompletion() {
    onCreate();
    onClose();
  }

  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth>
      <Mutation
        onCompleted={onCompletion}
        mutation={LAUNCH_INSTANCE}
        >
        {(mutation, { error: mutationError, loading }) => {
          return (
            <>
              <FormKit<FormData>
                context={context}
                initialValue={dto}
                defaultValue={dto}
              >
                {({ formValue, formIsValid, setFormValue }) => {
                  return (
                    <>
                      <DialogTitle id="form-dialog-title">
                        Launch EC2 Instance
                      </DialogTitle>
                      <DialogContent>
                        <Grid container spacing={24}>
                          <Grid item xs={6} sm={12}>
                            <div>
                              The user key must be unique for each instance
                              started. It is suggested to use your name. It must
                              start with a letter and can contain only letters,
                              numbers, and hyphens. It must be at least four
                              characters long.
                            </div>
                            <Field
                              fullWidth
                              name="userKey"
                              label="Unique user instance key"
                              validate={ec2LaunchInstanceUserKeyValidator}
                              component={FormTextInput}
                            />
                          </Grid>
                          <Grid item xs={6} sm={12}>
                            <div>
                              Enter one or more IP addresses to allow access to
                              the instance. Separate multiple IP addresses with
                              commas or spaces. Get your IP address from{' '}
                              <Link
                                href="https://www.whatismyip.com/"
                                aria-label="Find you IP address"
                                target="_blank"
                              >
                                here
                              </Link>
                              .
                            </div>
                            <Field
                              fullWidth
                              name="userIpAddressList"
                              label="IP Addresses to allow access"
                              validate={ipAddressListValidator}
                              component={FormTextInput}
                            />
                          </Grid>
                          <Grid item xs={6} sm={12}>
                            <div>Choose the EC2 instance type to launch.</div>
                            <LaunchTemplateSelect
                              launchTemplate={formValue.launchTemplate}
                              onSelectionChanged={(value) =>
                                setFormValue(() => ({
                                  ...formValue,
                                  launchTemplate: value,
                                }))
                              }
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>

                      <DialogActions>
                        <Button onClick={onClose} color="primary" disabled={loading}>
                          Cancel
                        </Button>

                        <ModalTrigger<'ConfirmDialog'>
                          modal="ConfirmDialog"
                          modalProps={{
                            onConfirm: () => handleSubmit(formValue, mutation),
                            onNope: onClose,
                            title: 'Launch Instance',
                            message: (
                              <span>
                                <p>
                                  Remember EC2 instances cost money. Please
                                  terminate the instance when you are done.
                                </p>
                                <p>
                                  The instance will be forcibly terminated after
                                  10 hours.
                                </p>
                                <p>Are you ready to launch this instance?</p>
                              </span>
                            ),
                          }}
                        >
                          {({ openModal }) => (
                            <Button
                              onClick={openModal}
                              color="primary"
                              variant="contained"
                              disabled={!formIsValid || loading}
                            >
                              Launch
                            </Button>
                          )}
                        </ModalTrigger>
                      </DialogActions>
                    </>
                  );
                }}
              </FormKit>
              {mutationError && (
                <CustomToast message={mutationError.message} isOpen />
              )}
            </>
          );
        }}
      </Mutation>
    </Dialog>
  );
}
