const defaults = {
  APP_URL: window.location.origin,
  AUTH_REDIRECT_URL: process.env.REACT_APP_AUTH_REDIRECT_URL,
  LOCAL_STORAGE_KEY: '__UNICORN_STORAGE_MASTER_KEY__',
};

const dev = {
  ...defaults,
  TOP_LEVEL_DOMAIN: 'ninja',
  AUTH0_TENANT: 'auth.unicorn.trint.io',
  STRIPE_BASE_URL: process.env.REACT_APP_STRIPE_BASE_URL,
};

const prod = {
  ...defaults,
  TOP_LEVEL_DOMAIN: 'io',
  AUTH0_TENANT: 'auth.unicorn.trint.io',
  STRIPE_BASE_URL: process.env.REACT_APP_STRIPE_BASE_URL,
};

const config = process.env.NODE_ENV === 'development' ? dev : prod;

export default config;
