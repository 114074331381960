import React, { useState } from 'react';
import {
  Button,
  Snackbar,
  IconButton,
  Theme,
  WithStyles,
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return {
    close: {
      padding: theme.spacing.unit / 2,
    },
  };
};

interface Args {
  isOpen: boolean;
  message: string;
  classes?: typeof styles;
}

type StyledArgs = Args & WithStyles<typeof styles>;

function CustomToast(props: StyledArgs) {
  const { message, classes, isOpen } = props;
  const [isVisible, setVisiblility] = useState(isOpen);
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={isVisible}
      autoHideDuration={6000}
      onClose={() => setVisiblility(false)}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{message}</span>}
      action={[
        <Button
          key="undo"
          color="secondary"
          size="small"
          onClick={() => setVisiblility(false)}
        >
          OK
        </Button>,
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={() => setVisiblility(false)}
        >
          <CloseOutlined />
        </IconButton>,
      ]}
    />
  );
}
export default withStyles(styles)(CustomToast);
