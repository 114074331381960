import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { withUserContext } from 'shared/hocs';
import { AuthContextState } from 'shared/components/UserContext';
import { session } from 'shared/utils/storage';

interface Props {
  location: { hash: string };
  auth: AuthContextState;
}

interface State {
  pathname: string;
}

class Callback extends React.Component<Props, State> {
  state = { pathname: session.getItem('redirectUrl') || '/' };
  componentDidMount() {
    const {
      location: { hash },
      auth: { handleAuthResponse },
    } = this.props;

    if (hash) {
      handleAuthResponse(hash);
    }
  }

  componentWillUnmount() {
    session.unsetItem('redirectUrl');
  }

  render() {
    const { pathname } = this.state;
    return <Redirect to={pathname} />;
  }
}

export default withUserContext(Callback);
