import React, { useState } from 'react';
import gql from 'graphql-tag';
import {
  Button,
  Dialog,
  Stepper,
  Typography,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { Log } from './models';
import StepOne from './ConfirmStepOne';
import StepTwo from './ConfirmStepTwo';
import StepThree from './ConfirmStepThree';
import { sdlQuery } from './BulkDeleteTable';

interface Props {
  handleClose: () => void;
  defaultTo: string;
  defaultFrom: string;
  organisationId: string;
}

interface ConfirmationVars {
  fromDate: string;
  toDate: string;
}

const GDPR_DELETE_MUTATION = gql`
  mutation gdprDelete($input: GdprDelete!) {
    gdprDelete(input: $input) {
      _id
      executedBy {
        username
        userId
      }
      request {
        type
        linkToRequest
        organisationId
      }
      outcome {
        artefacts {
          id
          type
          state
        }
        status
        message
      }
    }
  }
`;

interface GdprDeleteVars {
  input: {
    organisationId: string;
    from: number;
    to: number;
  };
}

type DeleteFormState = ConfirmationVars & { activeStep: number };

function DeleteFormStepper(props: Props) {
  const { handleClose, defaultFrom, defaultTo, organisationId } = props;

  const [deleteFormState, setDeleteFormState] = useState<DeleteFormState>({
    activeStep: 0,
    toDate: defaultTo,
    fromDate: defaultFrom,
  });

  const onDateConfirm = (from: string, to: string) => {
    setDeleteFormState({
      fromDate: from,
      toDate: to,
      activeStep: deleteFormState.activeStep + 1,
    });
  };

  const onNext = () => {
    setDeleteFormState({
      ...deleteFormState,
      activeStep: deleteFormState.activeStep + 1,
    });
  };

  const [invokeGdprDelete, { loading }] = useMutation<Log, GdprDeleteVars>(
    GDPR_DELETE_MUTATION,
    {
      onCompleted: handleClose,
      refetchQueries: [
        {
          query: sdlQuery,
          variables: {
            organisationId,
            from: moment(defaultFrom)
              .startOf('day')
              .valueOf(),
            to: moment(defaultTo)
              .endOf('day')
              .valueOf(),
          },
        },
      ],
      onError: e => {
        alert(e.message);
      },
      variables: {
        input: {
          organisationId,
          from: moment(deleteFormState.fromDate)
            .startOf('day')
            .valueOf(),
          to: moment(deleteFormState.toDate)
            .endOf('day')
            .valueOf(),
        },
      },
    },
  );

  const formIsComplete = deleteFormState.activeStep === 3;

  return (
    <>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle>GDPR Delete</DialogTitle>
        <DialogContent>
          <Stepper
            activeStep={deleteFormState.activeStep}
            orientation="vertical"
          >
            <StepOne
              active={deleteFormState.activeStep === 0}
              onNext={onNext}
            />
            <StepTwo
              active={deleteFormState.activeStep === 1}
              toDate={deleteFormState.toDate}
              fromDate={deleteFormState.fromDate}
              onNext={onDateConfirm}
            />
            <StepThree
              organisationId={organisationId}
              active={deleteFormState.activeStep === 2}
              onNext={onNext}
            />
          </Stepper>

          {deleteFormState.activeStep === 3 && (
            <>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    All steps completed
                  </Typography>
                  <Typography variant="body2">
                    {`You are about to delete files from ${
                      deleteFormState.fromDate
                    } to ${deleteFormState.toDate}`}
                  </Typography>

                  <Typography variant="body2">
                    Please click delete to proceed.
                  </Typography>
                </Grid>

                <Grid item xs={2} />
                <Grid item xs={2} />
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={loading || !formIsComplete}
            onClick={() => invokeGdprDelete()}
            data-testid="delete-button"
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            disabled={loading}
            onClick={handleClose}
            data-testid="cancel-button"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeleteFormStepper;
