import isNullOrUndefined from './isNullOrUndefined';

function isEmptyOrSpaces(str: string | null | undefined): boolean {
  return (
    isNullOrUndefined(str) ||
    (typeof str === 'string' && str.match(/^\s*$/) !== null)
  );
}

export default isEmptyOrSpaces;
