import gql from 'graphql-tag';

const Group = {
  fragments: {
    groupBase: gql`
      fragment GroupBase on Group {
        _id
        connectionIds
        metadata {
          name
        }
        members {
          _id
          username
          teamAdmin
          organisationAdmin
          currentTeam
          teamName
          reporting {
            costCode
          }
          profile {
            firstName
            lastName
            jobTitle
          }
        }
        isEnterprise
        isScimEnabled
        owner {
          _id
        }
      }
    `,
    groupTeamsInfo: gql`
      fragment GroupTeamsInfo on Group {
        teams {
          _id
          name
          description
        }
      }
    `,
    groupOwnerInfo: gql`
      fragment GroupOwnerInfo on Group {
        ownerId
        owner {
          _id
          username
        }
      }
    `,
    groupSettings: gql`
      fragment GroupSettings on Group {
        settings {
          allowedSharingDomains
          disableHrd
          disableSso
          autoProvision
          ssoDomains
        }
      }
    `,
    usageFragment: gql`
      fragment UsageFragment on UsageItem {
        count
        total
        audio
        video
      }
    `,
    groupMembersInfo: gql`
      fragment GroupMembersInfo on Group {
        totalMembers
        totalSeats
        totalActiveMembers
      }
    `,
  },
};

export default Group;
