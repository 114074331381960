import { isNullOrUndefined, isEmptyOrSpaces } from 'shared/utils';

export type FieldValidator<T = any> = (value: T) => string | void;

export const required = (msg = 'This field is required'): FieldValidator => (
  value: any,
): string | void => {
  if (isNullOrUndefined(value) || isEmptyOrSpaces(value)) {
    return msg;
  }
};

export const minLength = (
  length: number,
  message = `This field should be at least ${length} characters.`,
): FieldValidator<string> => (value: string): string | void => {
  if (value.length < length) {
    return message;
  }
};

export const min = (
  minimum: number,
  msg = `Should be at least ${minimum}.`,
): FieldValidator<number> => (value): string | void => {
  if (value < minimum) {
    return msg;
  }
};

export const max = (
  maximum: number,
  msg = `Should be at most ${maximum}.`,
): FieldValidator<number> => (value): string | void => {
  if (value > maximum) {
    return msg;
  }
};

// eslint-disable-next-line
const EMAIL_REG_EX = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
export const email = (
  msg = 'Not a valid email address',
): FieldValidator<string> => (value: any): string | void => {
  if (!EMAIL_REG_EX.test(value)) {
    return msg;
  }
};
