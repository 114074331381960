import gql from 'graphql-tag';
import { TrintUser, V2, Billing, PlanDetails } from 'types';

export interface CreateEnterpriseVars {
  userId: string;
  enterpriseName: string;
  seats: number;
  trialEndDate: string;
  internalContacts: string;
  contractEndDate: string;
}

export type CreateEnterpriseResult = {
  createEnterprise: Pick<TrintUser, '_id'>;
};

export const CREATE_ENTERPRISE_MUTATION = gql`
  mutation createEnterprise(
    $userId: String!
    $enterpriseName: String!
    $seats: Int!
    $trialEndDate: String!
    $internalContacts: String!
    $contractEndDate: String!
  ) {
    createEnterprise(
      userId: $userId
      enterpriseName: $enterpriseName
      seats: $seats
      trialEndDate: $trialEndDate
      internalContacts: $internalContacts
      contractEndDate: $contractEndDate
    ) {
      _id
    }
  }
`;

export interface UpdateSeatsVars {
  userId: string;
  seats: number;
}

export type UpdateSeatsResult = {
  updateEnterpriseSeats: Pick<TrintUser, '_id'> & {
    billing: {
      v2: Pick<V2, 'subscriptionSeatCount'>;
    };
  };
};

export const UPDATE_SEATS_MUTATION = gql`
  mutation updateEnterpriseSeats($userId: String!, $seats: Int!) {
    updateEnterpriseSeats(userId: $userId, seats: $seats) {
      _id
      billing {
        v2 {
          subscriptionSeatCount
        }
      }
    }
  }
`;

export interface UpdateEnterprisePlanDetailsVars {
  userId: string;
  internalContacts: string;
  enterpriseName: string;
  contractEndDate: string;
}

export type UpdateEnterprisePlanDetailsResult = {
  updateEnterpriseInternalContacts: Pick<TrintUser, '_id'> & {
    currentSubscription: Pick<PlanDetails, 'internalContacts'>;
    billing: Pick<Billing, 'planName'>;
  };
};

export const UPDATE_ENTERPRISE_PLAN_DETAILS_MUTATION = gql`
  mutation updateEnterprisePlanDetails(
    $userId: String!
    $internalContacts: String!
    $enterpriseName: String!
    $contractEndDate: String!
  ) {
    updateEnterprisePlanDetails(
      userId: $userId
      internalContacts: $internalContacts
      enterpriseName: $enterpriseName
      contractEndDate: $contractEndDate
    ) {
      _id
      billing {
        planName
      }
      currentSubscription {
        internalContacts
        contractEndDate
      }
    }
  }
`;

export interface CancelEnterpriseSubscriptionVars {
  userId: string;
}

export type CancelEnterpriseSubscriptionResult = {};

export const CANCEL_ENTERPRISE_SUBSCRIPTION_MUTATION = gql`
  mutation cancelEnterpriseSubscription($userId: String!) {
    cancelEnterpriseSubscription(userId: $userId) {
      _id
    }
  }
`;
