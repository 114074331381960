import React, { useState } from 'react';
import moment from 'moment';
import { Button, TextField, Grid } from '@material-ui/core';
import LogDialog from './LogDialog';
import { Log } from './models';
import BulkDeleteTable from './BulkDeleteTable';
import DeleteFormStepper from './DeleteFormStepper';
import { Delete } from '@material-ui/icons';

interface Props {
  groupId: string;
}

type State = Log | null;

const now = moment().format('YYYY-MM-DD');

const monthAgo = moment().subtract(1, 'months').format('YYYY-MM-DD');

function BulkDelete(props: Props) {
  const { groupId: organisationId } = props;
  const [selectedLog, setSelectedLog] = useState<State>(null);
  const [deleteRequest, setDeleteRequest] = useState(false);

  const handleOpen = (log: Log) => {
    setSelectedLog(log);
  };

  const handleClose = () => {
    setSelectedLog(null);
  };

  const handleDeleteAlertOpen = () => {
    setDeleteRequest(true);
  };

  const handleDeleteAlertClose = () => {
    setDeleteRequest(false);
  };

  const isDialogOpen = Boolean(selectedLog);
  const isDeleteRequested = Boolean(deleteRequest);

  const [searchVars, setSearchVars] = useState({ from: monthAgo, to: now });

  const to = moment(searchVars.to).endOf('day').toDate();

  const from = moment(searchVars.from).startOf('day').toDate();

  return (
    <>
      <Grid
        container
        alignItems="center"
        justify="space-around"
        direction="row"
      >
        <h3>Log History </h3>
      </Grid>
      <br />
      <Grid
        container
        alignItems="center"
        justify="space-between"
        direction="row"
      >
        <Grid container xs={4}>
          <Grid item xs={6}>
            <TextField
              id="date"
              label="From"
              type="date"
              value={searchVars.from}
              onChange={(e) => {
                setSearchVars({
                  ...searchVars,
                  from: e.currentTarget.value,
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="date"
              label="To"
              type="date"
              value={searchVars.to}
              onChange={(e) => {
                setSearchVars({
                  ...searchVars,
                  to: e.currentTarget.value,
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={1}>
          <Button
            style={{ marginTop: '2%', float: 'right' }}
            color="primary"
            onClick={handleDeleteAlertOpen}
            variant="contained"
          >
            <Delete />
          </Button>
        </Grid>
      </Grid>

      <br />

      <Grid container>
        <BulkDeleteTable
          onSelect={handleOpen}
          to={to}
          organisationId={organisationId}
          from={from}
        />
      </Grid>

      <br />
      {isDeleteRequested && (
        <DeleteFormStepper
          handleClose={handleDeleteAlertClose}
          defaultFrom={searchVars.from}
          defaultTo={searchVars.to}
          organisationId={organisationId}
        />
      )}
      {isDialogOpen && (
        <LogDialog handleClose={handleClose} log={selectedLog!} />
      )}
    </>
  );
}

export default BulkDelete;
