import * as React from 'react';
import { AllOrganisationsQueryResult } from 'organisations/queries';
import { Table, TableRow, Pagination } from 'shared/components';
import OrganisationListItem from './OrganisationListItem';
import { TableCellItem } from 'shared/components/TableRow';

interface Props {
  headers: TableCellItem[];
  groups: AllOrganisationsQueryResult[];
  page: number;
  perPage: number;
  hasMore: boolean;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void;
  onChangeRowsPerPage?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
}

// TODO this should not be the clients responsibility.
function normalize(
  group: AllOrganisationsQueryResult,
): AllOrganisationsQueryResult {
  const {
    _id,
    isEnterprise,
    members,
    metadata,
    owner,
    totalActiveMembers,
  } = group;

  return {
    _id,
    metadata,
    owner,
    // can be null which breaks material-ui switches
    isEnterprise: !!isEnterprise,
    members: members.map((m) => ({
      ...m,
      organisationAdmin: !!m.organisationAdmin,
    })),
    totalActiveMembers,
  };
}

class OrganisationTable extends React.Component<Props> {
  render() {
    const colWidths = ['30%', '24%', '14%', '14%', '18%'];
    const {
      groups = [],
      page,
      hasMore,
      headers,
      perPage,
      onChangePage,
      onChangeRowsPerPage,
    } = this.props;
    return (
      <>
        <Table headers={headers} colWidths={colWidths}>
          {groups.map((group) => (
            <TableRow<AllOrganisationsQueryResult>
              key={group._id}
              item={normalize(group)}
              component={OrganisationListItem}
            />
          ))}
        </Table>
        <Pagination
          page={page}
          perPage={perPage}
          hasMore={hasMore}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </>
    );
  }
}

export default OrganisationTable;
