import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { Tabs, Tab, AppBar, Typography } from '@material-ui/core';
import { TabContainer } from 'shared/components';
import EnterpriseAccountSection from 'enterprise/components/EnterpriseAccountSection';

interface Props extends RouteComponentProps<any> {}

class EnterprisePage extends React.Component<Props> {
  changeTab = (event: React.ChangeEvent<{}>, value: any) => {
    const { history } = this.props;
    if (history.location.pathname !== value) history.replace(value);
  };

  render() {
    const { userId }: { userId: string } = this.props.match.params;
    const { path, url } = this.props.match;
    const { location } = this.props;

    return (
      <React.Fragment>
        <AppBar position="static" color="default">
          <Tabs
            value={location.pathname}
            indicatorColor="primary"
            onChange={this.changeTab}
          >
            <Tab label="Manage Account" value={`${url}/account`} />
            <Tab label="Enterprise Stats" value={`${url}/stats`} />
          </Tabs>
        </AppBar>
        <TabContainer>
          <Switch>
            <Route
              path={`${path}/account`}
              render={() => <EnterpriseAccountSection userId={userId} />}
            />
            <Route
              path={`${path}/stats`}
              render={() => (
                <Typography variant="h5" style={{ padding: '50px' }}>
                  Enterprise stats comming soon...
                </Typography>
              )}
            />
          </Switch>
        </TabContainer>
      </React.Fragment>
    );
  }
}

export default EnterprisePage;
