import * as React from 'react';
import moment from 'moment';
import { QueryResult } from 'react-apollo';
import { TableRow, TableCell, Theme, createStyles } from '@material-ui/core';

import { createStyled } from 'shared/hocs';
import { GlobalCircleLoader, Table } from 'shared/components';
import * as create from 'shared/components/Table';

export interface Coupon {
  id: string;
  name: string;
  percent_off: number;
  valid: boolean;
  created: number;
}

interface CouponsTableProps {
  className?: string;
  queryResult: QueryResult;
  mapDataToCoupons?(data: any): [Coupon];
}

function CouponsTable({
  className,
  queryResult,
  mapDataToCoupons = (a) => a,
}: CouponsTableProps) {
  const { data, error, loading } = queryResult;

  if (loading) return <GlobalCircleLoader show />;
  if (error) return <span>{error.message}</span>;
  if (!data) return null;

  const coupons = mapDataToCoupons(data);

  const headers = [
    create.header('Voucher ID'),
    create.header('Name'),
    create.header('Discount'),
    create.header('Used'),
    create.header('Created'),
    create.header('Actions'),
  ];
  const colWidths = ['15%', '20%', '12.5%', '12.5%', '20%', '20%'];

  return (
    <Styled>
      {({ classes }) => (
        <div className={className}>
          <Table headers={headers} colWidths={colWidths}>
            {coupons.map((coupon: Coupon) => {
              return (
                <TableRow key={coupon.id}>
                  <TableCell>{coupon.id}</TableCell>
                  <TableCell>{coupon.name}</TableCell>
                  <TableCell>
                    {coupon.percent_off ? `${coupon.percent_off}%` : 'N/A'}
                  </TableCell>
                  <TableCell>{coupon.valid ? 'AVAILABLE' : 'USED'}</TableCell>
                  <TableCell>
                    {moment(coupon.created).format('ddd, MMM Do YYYY, H:mm:ss')}
                  </TableCell>
                  <TableCell>N/A</TableCell>
                </TableRow>
              );
            })}
          </Table>
        </div>
      )}
    </Styled>
  );
}

const Styled = createStyled((theme: Theme) =>
  createStyles({
    width: {
      width: '100%',
    },
    capitalise: {
      textTransform: 'capitalize',
    },
    tableCellPadding: {
      padding: '4px 0px 4px 24px',
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&visited': {
        textDecoration: 'none',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    tooltipPlacementTop: {
      margin: '0 0 3px 0',
    },
    tooltipPopper: {
      '&[x-placement*="top"] ': {
        opacity: 1,
      },
    },
    lightTooltip: {
      backgroundColor: '#aaaaaa',
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
    textOverflow: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

export default CouponsTable;
