import * as React from 'react';
import { createStyled } from 'shared/hocs';
import { Theme, createStyles } from '@material-ui/core';

const Styled = createStyled((theme: Theme) =>
  createStyles({
    tableContainer: {
      marginTop: theme.spacing.unit * 5,
      marginLeft: theme.spacing.unit * 5,
      marginRight: theme.spacing.unit * 5,
    },
  }),
);

const ListViewLayout: React.SFC<{}> = ({ children }) => (
  <Styled>
    {({ classes }) => <div className={classes.tableContainer}>{children}</div>}
  </Styled>
);

export default ListViewLayout;
