import * as React from 'react';
import { Mutation, Query } from 'react-apollo';
import { saveAs } from 'file-saver';
import { createStyled } from 'shared/hocs';
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Paper,
  Theme,
  Typography,
  createStyles,
} from '@material-ui/core';
import {
  ORGANISATION_QUERY,
  OrganisationQueryResult,
} from 'organisations/queries';
import { UPDATE_ORGANISATION_DETAILS } from 'organisations/mutations';
import { Link } from 'react-router-dom';
import { createFormContext } from 'yafl';
import FormTextInput from 'shared/formKit/FormTextInput';
import { FormSelectInput } from 'shared/formKit';
import { getMembersCsv } from 'shared/csv';

interface Props {
  groupId: string;
}

interface OrganisationQueryRes {
  group: OrganisationQueryResult;
}

interface OrganisationQueryVars {
  groupId: string;
}

interface OrganisationMutationVars {
  organisationId: string;
  ownerId: string;
  name: string;
}

interface OrganisationMutationRes {
  _id: string;
  owner: {
    _id: string;
    username: string;
  };
  metadata: {
    name: string;
  };
}

interface FormShape {
  name: string;
  ownerId: string;
}

const { Form, Field } = createFormContext<FormShape>();

function OrganisationDetailsForm(props: Props) {
  const { groupId } = props;

  const [isEditable, setEditable] = React.useState(false);

  function toggleEditable() {
    setEditable(!isEditable);
  }

  return (
    <Query<OrganisationQueryRes, OrganisationQueryVars>
      query={ORGANISATION_QUERY}
      variables={{ groupId }}
      fetchPolicy="cache-and-network"
    >
      {({ data, loading, error }) => {
        if (error) return null;

        if (loading && (!data || !data.group)) return null;

        const { group } = data!;
        const { settings } = group;
        return (
          <Mutation<OrganisationMutationRes, OrganisationMutationVars>
            mutation={UPDATE_ORGANISATION_DETAILS}
            refetchQueries={[
              {
                query: ORGANISATION_QUERY,
                variables: { groupId: group._id },
              },
            ]}
          >
            {(mutation, { error: mutationError }) => (
              <Form
                initialValue={{
                  ownerId: group.owner._id,
                  name: group.metadata.name,
                }}
                defaultValue={{ name: '', ownerId: '' }}
                onSubmit={(formValue, yaflProps) => {
                  mutation({
                    variables: {
                      ...formValue,
                      organisationId: group._id,
                    },
                  }).catch(() => yaflProps.resetForm());
                }}
              >
                {(yafl) => {
                  return (
                    <Styled>
                      {({ classes }) => (
                        <main className={classes.layout}>
                          <Paper className={classes.paper}>
                            <Typography
                              className={classes.title}
                              component="h1"
                              variant="h5"
                            >
                              Organisation Details
                            </Typography>
                            {mutationError && (
                              <Typography
                                className={classes.error}
                                component="h1"
                                variant="body1"
                              >
                                {mutationError.message}
                              </Typography>
                            )}
                            <Grid container spacing={24}>
                              <Grid item xs={12} sm={6}>
                                <Field
                                  fullWidth
                                  name="name"
                                  variant="outlined"
                                  label="Organisation Name"
                                  disabled={true || !isEditable}
                                  component={FormTextInput}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Field
                                  fullWidth
                                  name="ownerId"
                                  variant="outlined"
                                  label="Organisation Owner"
                                  disabled={!isEditable}
                                  options={group.members.map((member) => ({
                                    value: member._id,
                                    label: member.username,
                                  }))}
                                  component={FormSelectInput}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormControl id="ownerLink">
                                  <FormLabel>Owner</FormLabel>
                                  <Link
                                    className={classes.formLink}
                                    to={`/users/${group.ownerId}/details`}
                                  >
                                    {group.owner.username}
                                  </Link>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormControl id="totalSeats">
                                  <FormLabel>Total Seats</FormLabel>
                                  {group.totalSeats}
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormControl id="totalMembers">
                                  <FormLabel>Total Members</FormLabel>
                                  {group.totalMembers}
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormControl id="totalActiveMembers">
                                  <FormLabel>Total Active Members</FormLabel>
                                  {group.totalActiveMembers}
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormControl id="isScimEnabled">
                                  <FormLabel>Has SCIM enabled</FormLabel>
                                  {group.isScimEnabled ? 'yes' : 'no'}
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormControl id="isHrdEnabled">
                                  <FormLabel>Home realm discovery</FormLabel>
                                  {settings.disableHrd ? 'no' : 'yes'}
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormControl id="isSsoEnabled">
                                  <FormLabel>Single sign-on</FormLabel>
                                  {settings.disableSso ? 'no' : 'yes'}
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormControl id="isAutoProvision">
                                  <FormLabel>Auto provisioning</FormLabel>
                                  {settings.autoProvision ? 'yes' : 'no'}
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <FormControl id="membersCsv">
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                      const blob = new Blob(
                                        [getMembersCsv(group.members)],
                                        { type: 'text/plain;charset=utf-8' },
                                      );
                                      saveAs(
                                        blob,
                                        `org-members-${group._id}.csv`,
                                      );
                                    }}
                                  >
                                    Download CSV - {group.members.length} Member
                                    Details
                                  </Button>
                                </FormControl>
                              </Grid>
                            </Grid>
                            <div className={classes.buttons}>
                              {isEditable ? (
                                <React.Fragment>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    onClick={() => {
                                      toggleEditable();
                                      yafl.resetForm();
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    className={classes.button}
                                    onClick={() => {
                                      toggleEditable();
                                      yafl.submit();
                                    }}
                                  >
                                    Save
                                  </Button>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <Link to="/organisations">
                                    <Button className={classes.button}>
                                      Back
                                    </Button>
                                  </Link>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={toggleEditable}
                                  >
                                    Edit
                                  </Button>
                                </React.Fragment>
                              )}
                            </div>
                          </Paper>
                        </main>
                      )}
                    </Styled>
                  );
                }}
              </Form>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
}

const Styled = createStyled((theme: Theme) => {
  return createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing.unit * 3,
      marginLeft: theme.spacing.unit,
    },
    title: {
      marginBottom: theme.spacing.unit * 3,
    },
    formLink: {
      fontSize: 16,
      textDecoration: 'none',
      color: '#0000d0',
      '&visited': {
        textDecoration: 'none',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    error: {
      color: 'red',
      border: '1px solid red',
      borderRadius: '5px',
      marginBottom: '20px',
    },
  });
});

export default OrganisationDetailsForm;
