import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { Mutation } from 'react-apollo';
import { UPDATE_DOMAINS } from 'organisations/mutations';
import { BaseModalProps } from 'contexts/ModalTrigger';

interface ComponentProps {
  groupId: string;
}

type Props = ComponentProps & BaseModalProps;

class AddDomainsModal extends React.Component<Props> {
  state = {
    newDomains: [''],
  };

  handleChange = (e: any) => {
    this.setState({
      newDomains: e.target.value.split(/\n/),
    });
  };

  render() {
    const { groupId, closeModal } = this.props;
    const { newDomains } = this.state;
    return (
      <React.Fragment>
        <DialogTitle id="form-dialog-title">Add Domains</DialogTitle>
        <DialogContent>
          <Grid container xs={12} spacing={24} direction="column">
            <Grid item xs={12}>
              <TextField
                id="teamDomains"
                label="Allowed Domains"
                multiline
                defaultValue={newDomains.join('\n')}
                onChange={this.handleChange}
                margin="normal"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div>
            <Button onClick={closeModal}>Cancel</Button>
            <Mutation mutation={UPDATE_DOMAINS}>
              {(mutation) => (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    mutation({
                      variables: {
                        domains: newDomains.filter(Boolean),
                        groupId: groupId,
                      },
                    })
                      .then(closeModal)
                      .catch();
                  }}
                >
                  Save
                </Button>
              )}
            </Mutation>
          </div>
        </DialogActions>
      </React.Fragment>
    );
  }
}

export default AddDomainsModal;
