import React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { Table, TableRow, TableCell, TableHead } from '@material-ui/core';
import moment from 'moment';

interface Props {
  groupId: string;
}

interface RealtimeStream {
  _id: string;
  trintId: string;
  userId: string;
  status: string;
  config: {
    name: string;
    type: string;
    feedUrl: string;
  };
  controllerId: string;
  created: Date;
}

const REALTIME_RUNNING_ORG_QUERY = gql`
  query realtimeStreamsRunningOrg($groupId: String!) {
    realtimeStreamsRunningOrg(groupId: $groupId) {
      _id
      trintId
      userId
      status
      config {
        name
        type
        feedUrl
      }
      controllerId
      created
    }
  }
`;

export function RealtimeRunningStreamsOrg(props: Props) {
  const { groupId } = props;

  const { data, error, loading } = useQuery(REALTIME_RUNNING_ORG_QUERY, {
    variables: {
      groupId,
    },
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error</div>;
  if (!data) return null;

  const { realtimeStreamsRunningOrg } = data;

  if (realtimeStreamsRunningOrg.length < 1) {
    return <div>No streams currently running</div>;
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Stream Id</TableCell>
            <TableCell align="right">Stream Type</TableCell>
            <TableCell align="right">Created By</TableCell>
            <TableCell align="right">Created</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Trint Id</TableCell>
          </TableRow>
        </TableHead>
        {realtimeStreamsRunningOrg.map((stream: RealtimeStream) => {
          return (
            <TableRow key={stream._id}>
              <TableCell>{stream.config.name}</TableCell>
              <TableCell align="right">{stream._id}</TableCell>
              <TableCell align="right">{stream.config.type}</TableCell>
              <TableCell align="right">{stream.userId}</TableCell>
              <TableCell align="right">
                {moment(stream.created).format('dd, MMM Do YYYY, H:mm:ss')}
              </TableCell>
              <TableCell align="right">{stream.status}</TableCell>
              <TableCell align="right">{stream.trintId}</TableCell>
            </TableRow>
          );
        })}
      </Table>
    </>
  );
}

export default RealtimeRunningStreamsOrg;
