import { AuthStorage } from '../auth';
import config from 'config';

/*
  use this simple function wrapper to create functions with strongly typed access to local storage
  only the keys of TShape will be allowed to be used here
*/

type Nullable<T> = { [K in keyof T]: T[K] | null };

const MASTER_KEY = config.LOCAL_STORAGE_KEY;
export function createStorageHandler<TShape>(store = localStorage) {
  type InternalState = Nullable<TShape>;

  function setItem<T>(key: string, value: T): string {
    const str = JSON.stringify(value);
    store.setItem(key, str);
    return str;
  }

  function getItem<T>(key: string): T | null {
    const data = store.getItem(key);
    if (data) {
      return JSON.parse(data);
    }

    return null;
  }

  const empty = {} as InternalState;
  return {
    getItem<K extends keyof TShape & string>(key: K): TShape[K] | null {
      const state = getItem<InternalState>(MASTER_KEY) || empty;
      return state[key] || null;
    },
    setItem<K extends keyof TShape & string>(key: K, value: TShape[K]) {
      const state = getItem<InternalState>(MASTER_KEY) || empty;
      state[key] = value;
      setItem<InternalState>(MASTER_KEY, state);
      return value;
    },
    unsetItem(...keys: Array<keyof TShape & string>) {
      const state = getItem<InternalState>(MASTER_KEY) || empty;
      keys.forEach(key => delete state[key]);
      setItem<InternalState>(MASTER_KEY, state);
    },
    clear() {
      store.removeItem(MASTER_KEY);
    },
  };
}

export type LocalStorageState = AuthStorage;
export interface SessionStorageState {
  redirectUrl: string;
}

export const local = createStorageHandler<LocalStorageState>(localStorage);
export const session = createStorageHandler<SessionStorageState>(
  sessionStorage,
);
