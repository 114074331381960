import React from 'react';
import { formatDuration } from 'shared/utils';

interface Props {
  duration: number; // Seconds
  format?: string;
}

const FormatDuration: React.SFC<Props> = ({ duration, format = 'h:mm:ss' }) => (
  <>{formatDuration(duration, format)}</>
);

export default FormatDuration;
