import * as React from 'react';
import createModalContext, {
  ModalContextState,
} from 'contexts/createModalContext';
import * as components from 'modals/modals';
import { PropsOf } from 'shared/types';

export type ModalComponentTypes = typeof components;
type MCT = ModalComponentTypes;

const Context = createModalContext<MCT>();

export const ModalProvider = Context.Provider;
export const BaseModalConsumer = Context.Consumer;

export interface BaseModalProps {
  openModal: (e: any) => void;
  closeModal: (e: any) => void;
}

type ModalConsumerChildrenFn<K extends keyof MCT = keyof MCT> = (
  context: BaseModalProps & PropsOf<MCT[K]>,
) => React.ReactNode;

interface InnerProps<K extends keyof MCT> {
  context: ModalContextState<MCT>;
  modal: keyof MCT;
  children: ModalConsumerChildrenFn;
  modalProps: PropsOf<MCT[K]>;
}

class InnerModalRenderer<
  K extends keyof MCT = keyof MCT
> extends React.Component<InnerProps<K>> {
  openModal = () => {
    const { context, modal, modalProps } = this.props;
    context.openModal(modal, modalProps);
  };

  closeModal = () => {
    const { context } = this.props;
    context.closeModal();
  };

  render() {
    return this.props.children({
      closeModal: this.closeModal,
      openModal: this.openModal,
    });
  }
}

interface ModalConsumerProps<K extends keyof MCT = keyof MCT> {
  modal: keyof MCT;
  modalProps: PropsOf<MCT[K]>;
  children: ModalConsumerChildrenFn;
}

export class ModalTrigger<K extends keyof MCT> extends React.Component<
  ModalConsumerProps<K>
> {
  render() {
    const { modal, modalProps, children } = this.props;
    return (
      <Context.Consumer>
        {context => (
          <InnerModalRenderer<K>
            context={context}
            modal={modal}
            modalProps={modalProps}
          >
            {children}
          </InnerModalRenderer>
        )}
      </Context.Consumer>
    );
  }
}
