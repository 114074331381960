import React, { useState } from 'react';
import {
  Button,
  TableCell,
  TableRow,
  Theme,
  createStyles,
} from '@material-ui/core';
import { AllOrganisationsQueryResult } from 'organisations/queries';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { createStyled } from 'shared/hocs';
import EnterpriseCreateModal from 'enterprise/components/EnterpriseCreateModal';
import OrganisationsIcon from '@material-ui/icons/Business';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
interface Props {
  id: string;
  item: AllOrganisationsQueryResult;
  itemName: string;
  onRowClick: (
    e: React.MouseEvent<HTMLTableRowElement>,
    item: AllOrganisationsQueryResult,
  ) => void;
}

const OrganisationListItem = (props: Props) => {
  const { item } = props;
  const { isEnterprise } = item;
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState<Boolean>(false);
  return (
    <Styled>
      {({ classes }) => (
        <TableRow>
          <TableCell>
            <Link
              to={`/organisations/${item._id}/details`}
              title="Details"
              className={classnames(classes.link)}
            >
              {item.metadata ? item.metadata.name : 'no name'}
            </Link>
          </TableCell>
          <TableCell>{item._id}</TableCell>
          <TableCell>{item.totalActiveMembers}</TableCell>
          <TableCell>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {isEnterprise ? (
                <>
                  <AccountBalanceIcon color="primary" />
                  Enterprise
                </>
              ) : (
                <>
                  <OrganisationsIcon color="primary" />
                  Pro Team
                </>
              )}
            </div>
          </TableCell>
          <TableCell>
            {isEnterprise ? (
              <p> - </p>
            ) : (
              <>
                <Button
                  disabled={false}
                  onClick={() => setIsUpgradeModalOpen(true)}
                  color="primary"
                  variant="contained"
                >
                  Upgrade
                </Button>
                <EnterpriseCreateModal
                  onClose={() => setIsUpgradeModalOpen(false)}
                  isOpen={isUpgradeModalOpen}
                  cancel={() => setIsUpgradeModalOpen(false)}
                  userId={item.owner && item.owner._id}
                  enterpriseName={item.metadata && item.metadata.name}
                />
              </>
            )}
          </TableCell>
        </TableRow>
      )}
    </Styled>
  );
};

const Styled = createStyled((theme: Theme) =>
  createStyles({
    capitalise: {
      textTransform: 'capitalize',
    },
    padLeft3: {
      paddingLeft: 3,
    },
    adminIcon: {
      display: 'inline-flex',
      flex: '0 0 auto',
      verticalAlign: 'middle',
      paddingRight: 3,
    },
    link: {
      textDecoration: 'none',
      color: '#0000d0',
      '&visited': {
        textDecoration: 'none',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    tooltipPlacementTop: {
      margin: '0 0 3px 0',
    },
    tooltipPopper: {
      '&[x-placement*="top"] ': {
        opacity: 1,
      },
    },
    lightTooltip: {
      backgroundColor: '#aaaaaa',
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }),
);

export default OrganisationListItem;
