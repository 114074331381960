import { createMuiTheme } from '@material-ui/core';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

const defaultOverrides: ThemeOptions = {
  overrides: {
    MuiTableCell: {
      root: {
        paddingLeft: 10,
        paddingRight: 10,
        '&:last-child': {
          paddingRight: 10,
        },
      },
    },
  },
};

export const defaultTheme = createMuiTheme({
  ...defaultOverrides,
  palette: {
    primary: {
      main: '#ffcf01',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

export const usersTheme = createMuiTheme({
  ...defaultOverrides,
  palette: {
    primary: {
      main: '#B500B2',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

export const filesTheme = createMuiTheme({
  ...defaultOverrides,
  palette: {
    primary: {
      main: '#F26430',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

export const organisationsTheme = createMuiTheme({
  ...defaultOverrides,
  palette: {
    primary: {
      main: '#F26430',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

export const plansTheme = createMuiTheme({
  ...defaultOverrides,
  palette: {
    primary: {
      main: '#34d058',
    },
  },
  typography: {
    useNextVariants: true,
  },
});
