import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { parseQuery } from 'shared/utils';

interface Props<T extends object> extends RouteComponentProps {
  defaultValues: Partial<T>;
  children: (query: T) => React.ReactNode;
}

class UriQueryParams<T extends object> extends React.Component<Props<T>> {
  render() {
    const { location, children, defaultValues = {} } = this.props;
    const query = parseQuery<T>(location.search);
    return children(Object.assign({} as T, query, defaultValues));
  }
}

export default withRouter(UriQueryParams);
