import React from 'react';
import { createStyles } from '@material-ui/core';
import { createStyled } from 'shared/hocs';

export const SearchTips = () => {
  return (
    <Styled>
      {({ classes }) => (
        <div className={classes.tips}>
          <details>
            <summary>Toggle for search tips</summary>
            <br />
            <div>
              Prefix your search with the following for specific queries:
            </div>
            <ul>
              <li>
                <strong>email:</strong> - Search by username{' '}
                <i>(email:test@trint.com, email:user@gmail.com)</i>
              </li>
              <li>
                <strong>fname:</strong> - Search by first name{' '}
                <i>(fname:Firstname)</i>
              </li>
              <li>
                <strong>lname:</strong> - Search by last name{' '}
                <i>(lname:Lastname)</i>
              </li>
              <li>
                <strong>org-id:</strong> - Search by organisation ID{' '}
                <i>(org-id:orgId123)</i>
              </li>
              <li>
                <strong>team-id:</strong> - Search by team ID{' '}
                <i>(team-id:teamId123)</i>
              </li>
              <li>
                <strong>company:</strong> - Search by company name in customer
                profile <i>(company:Trint)</i>
              </li>
              <li>
                <strong>domain:</strong> - Search by email domain{' '}
                <i>(domain:@trint.com, domain:trint.com)</i>
              </li>
              <li>
                <strong>uuid:</strong> - Search by user ID{' '}
                <i>(uuid:609a94c6507bab72ee9e56f5)</i>
              </li>
            </ul>
            <div>Notes:</div>
            <ul>
              <li>
                If you don't provide a prefix, and if your query string contains
                a <strong>@</strong>, it will search for the entire
                email/username <i>(demo@trint.com)</i>
              </li>
              <li>
                If you don't provide a prefix, and if your query string is 24
                characters long, it will search for the user ID{' '}
                <i>(609a94c6507bab72ee9e56f5)</i>
              </li>
              <li>
                In all other cases, it will search for the user's first name OR
                last name <i>(firstname, lastname).</i> Please note that{' '}
                <i>firstname lastname</i> will not work!
              </li>
              <li>
                If you don't pass a query string, it will return all users in
                the database
              </li>
            </ul>
          </details>
        </div>
      )}
    </Styled>
  );
};

const Styled = createStyled(() => {
  return createStyles({
    tips: {
      borderRadius: '5px',
      padding: '20px',
      backgroundColor: '#EDDAF7',
      fontFamily: 'Roboto',
    },
  });
});
