import * as React from 'react';
import { createStyles, Theme, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { createStyled } from 'shared/hocs';

interface Props {
  children: React.ReactNode;
}

export default function ErrorDisplay(props: Props) {
  const { children } = props;
  return (
    <Styled>
      {({ classes }) => (
        <div className={classes.container}>
          <div className={classes.iconWrapper}>
            <ErrorIcon className={classes.icon} />
          </div>
          <div className={classes.messageWrapper}>
            <Typography className={classes.message}>{children}</Typography>
          </div>
        </div>
      )}
    </Styled>
  );
}

const Styled = createStyled((theme: Theme) => {
  return createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      color: '#F44336',
      lineHeight: '1',
      borderRadius: '5px',
      padding: '5px',
      marginTop: '20px',
      marginBottom: '20px',
      boxShadow: '0 0 4px red',
    },
    iconWrapper: {
      width: '48px',
    },
    icon: {
      width: '48px',
      height: '48px',
    },
    messageWrapper: {
      width: 'calc(100% - 48px)',
      marginLeft: '6px',
    },
    message: {
      color: '#C62828',
    },
  });
});
