/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useReducer } from 'react';
import { GENERAL_SEARCH_QUERY, GeneralSearchQueryResult } from 'users/queries';
import { QueryVars, QueryRes } from '../../pages/UserListPage';
import { Query } from 'react-apollo';
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
} from '@material-ui/core';
import CustomToast from 'shared/components/CustomToast';

interface Args {
  userId: string;
  filterResults?(userInfo: GeneralSearchQueryResult): boolean;
  onSelectionChanged(userId: string): void;
}
interface BaseState {
  searchPredicate: string;
}
type State = QueryVars & BaseState;

interface ActionType {
  type: 'updatePredicate' | 'page' | 'applyFilter' | 'selectUser';
  data?: string;
}

const initialState: State = {
  order: 'desc',
  sortBy: 'created',
  page: 0,
  searchPredicate: '',
  limit: 10,
};

function reducer(state: State, action: ActionType): State {
  const { type, data } = action;
  switch (type) {
    case 'updatePredicate':
      return {
        ...state,
        searchPredicate: data!,
      };

    case 'page':
      return {
        ...state,
        page: state.page + 1,
      };

    case 'applyFilter':
      return {
        ...state,
        searchText: state.searchPredicate,
      };
    default:
      throw new Error('Args out of range');
  }
}
function PagedUserSelect(props: Args) {
  const { onSelectionChanged, userId, filterResults } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { page, order, limit, sortBy, searchText, searchPredicate } = state;

  function massageUserDisplay(user: GeneralSearchQueryResult) {
    return `${user.profile.firstName} ${user.profile.lastName} (${user.username})`;
  }
  return (
    <Query<QueryRes, QueryVars>
      query={GENERAL_SEARCH_QUERY}
      fetchPolicy="cache-and-network"
      variables={{
        page,
        order,
        limit,
        sortBy,
        searchText,
      }}
    >
      {({ loading, error, data, refetch }) => {
        if (error) return <CustomToast message={error.message} isOpen />;
        if (loading && (!data || !data.users)) return <CircularProgress />;
        const filteredUsers =
          (filterResults
            ? data?.users.list.filter(filterResults)
            : data?.users.list) || [];

        return (
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12}>
              <TextField
                value={searchPredicate}
                onChange={(e) =>
                  dispatch({
                    type: 'updatePredicate',
                    data: e.target.value,
                  })
                }
                placeholder="Search for user"
              />
              &nbsp;
              <Button
                onClick={() => {
                  dispatch({ type: 'applyFilter' });
                  refetch();
                }}
              >
                Search
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl>
                <Select
                  value={userId}
                  onChange={(e) => onSelectionChanged(e.target.value)}
                  name="Owner"
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Please select an owner
                  </MenuItem>
                  {filteredUsers.map((user) => (
                    <MenuItem key={user._id} value={user._id}>
                      {massageUserDisplay(user)}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {data?.users.hasMore && (
                    <a
                      href="#"
                      onClick={() =>
                        dispatch({
                          type: 'page',
                        })
                      }
                    >
                      Load more...
                    </a>
                  )}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        );
      }}
    </Query>
  );
}

export default PagedUserSelect;
