import React from 'react';
import { CircularProgress } from '@material-ui/core';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import Table, * as create from 'shared/components/Table';
import { BulkDeleteRow } from './BulkDeleteRow';
import { Log } from './models';

interface Props {
  organisationId: string;
  onSelect: (log: Log) => void;
  from: Date;
  to: Date;
}

export const sdlQuery = gql`
  query bulkDeleteLogs($organisationId: String!, $from: Float!, $to: Float!) {
    getSecureDeletionLogs(
      organisationId: $organisationId
      from: $from
      to: $to
    ) {
      _id
      executedBy {
        userId
        username
      }
      created
      request {
        spanId
        type
      }
      outcome {
        message
        status
        artefacts {
          type
          id
          state
        }
      }
    }
  }
`;

interface LogQueryRes {
  getSecureDeletionLogs: Log[];
}

interface LogQueryVars {
  from: number;
  to: number;
  organisationId: string;
}

const headers = [
  create.header('ID'),
  create.header('Date Created'),
  create.header('Executed By'),
  create.header('Status'),
  create.header('-'),
];

const colWidths = ['20%', '10%', '30%', '25%', '15%'];

function BulkDeleteTable(props: Props) {
  const { from, to, organisationId, onSelect } = props;
  const { data, error, loading } = useQuery<LogQueryRes, LogQueryVars>(
    sdlQuery,
    {
      variables: {
        organisationId,
        from: Number(from),
        to: Number(to),
      },
      fetchPolicy: 'cache-and-network',
      // 1.5 minute
      pollInterval: 90000,
    },
  );

  if (error) {
    return <div>{error.message}</div>;
  }
  if (loading && !data) {
    return <CircularProgress />;
  }

  const { getSecureDeletionLogs } = data!;

  return (
    <Table headers={headers} colWidths={colWidths}>
      {getSecureDeletionLogs.map((row) => (
        <BulkDeleteRow row={row} key={row._id} onSelect={onSelect} />
      ))}
    </Table>
  );
}

export default BulkDeleteTable;
