import React, { useState } from 'react';
import moment from 'moment';
import { createStyled } from 'shared/hocs';
import { Mutation, useQuery } from 'react-apollo';
import {
  Theme,
  Grid,
  Paper,
  Typography,
  createStyles,
  Button as Btn,
  Table,
  TableRow,
  TableCell,
  CircularProgress,
  TableBody,
  // ExpansionPanel,
  // ExpansionPanelSummary,
  // ExpansionPanelDetails,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import { SYNC_USER_FROM_STRIPE_MUTATION } from 'users/mutations';
import { USER_BILLING_QUERY, UserBillingQueryResult } from 'users/queries';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { BillingOperationTypes, BillingOperation } from 'types';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import PendingBillingOperation from './PendingBillingOperation';

interface Props {
  userId: string;
}

// https://github.com/mui-org/material-ui/issues/11968
const Button: React.ComponentType<any> = Btn;
function Form(props: Props) {
  const { userId } = props;
  const { data, error, loading } = useQuery<UserBillingQueryResult>(
    USER_BILLING_QUERY,
    {
      variables: {
        userId,
      },
    },
  );

  const [pendingOpsPanelOpen, setPendingOpsPanelOpen] = useState<boolean>(
    false,
  );
  if (error) {
    return null;
  }
  if (loading) {
    return <CircularProgress />;
  }

  const {
    billing,
    currentSubscription,
    username,
    version,
    customer,
  } = data!.user;

  const pendingOperations = billing.pendingOperations || [];

  return (
    <Styled>
      {({ classes }) => (
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography className={classes.title} component="h1" variant="h5">
              Billing Info
            </Typography>

            <Grid spacing={24}>
              <Grid item xs={12} sm={12} zeroMinWidth>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Version
                      </TableCell>
                      <TableCell align="right">{version || 'v1'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Username
                      </TableCell>
                      <TableCell align="right">{username || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        First name
                      </TableCell>
                      <TableCell align="right">
                        {billing.first_name || '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Surname
                      </TableCell>
                      <TableCell align="right">
                        {billing.last_name || '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Brand
                      </TableCell>
                      <TableCell align="right">
                        {billing.brand || '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        PaymentMethodId
                      </TableCell>
                      <TableCell align="right">
                        {billing.paymentMethodId || '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Company
                      </TableCell>
                      <TableCell align="right">
                        {billing.company || '-'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={12} sm={12} zeroMinWidth>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Plan name
                      </TableCell>
                      <TableCell align="right">
                        {currentSubscription.title || '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        PricingInterval
                      </TableCell>
                      <TableCell align="right">
                        {currentSubscription.pricingInterval || '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        CustomerID
                      </TableCell>
                      <TableCell align="right">
                        {(customer && customer.id) || '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        PlanID
                      </TableCell>
                      <TableCell align="right">
                        {currentSubscription.planId || '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Currency
                      </TableCell>
                      <TableCell align="right">
                        {billing.currency || '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Subscription status v2
                      </TableCell>
                      <TableCell align="right">
                        {billing.v2.subscriptionStatus || '-'}{' '}
                        {billing.v2.cancellationDate &&
                          `subscription will be cancelled on ${moment(
                            billing.v2.cancellationDate,
                          ).format('DD MMMM YYYY')}`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Subscription purchase source
                      </TableCell>
                      <TableCell align="right">
                        {billing.purchaseSource || '-'}{' '}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} align="right">
                        <List>
                          <ListItem
                            button
                            onClick={() =>
                              setPendingOpsPanelOpen(!pendingOpsPanelOpen)
                            }
                          >
                            <ListItemIcon>
                              <CreditCardIcon />
                            </ListItemIcon>

                            <ListItemText secondary="Pending billing operations" />
                            {pendingOpsPanelOpen && <ExpandLess />}
                            {!pendingOpsPanelOpen && <ExpandMore />}
                          </ListItem>
                          <Collapse
                            in={pendingOpsPanelOpen}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List disablePadding>
                              {pendingOperations.map((pendingOp) => {
                                return (
                                  <ListItem key={pendingOp.id}>
                                    <ListItemText
                                      disableTypography
                                      className={classes.pendingOpsListItem}
                                    >
                                      <PendingBillingOperation
                                        userId={userId}
                                        pendingOperation={pendingOp}
                                      />
                                    </ListItemText>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </Collapse>
                        </List>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>

            {version === 'v2' && customer && customer.id && (
              <Mutation mutation={SYNC_USER_FROM_STRIPE_MUTATION}>
                {(syncUserFromStripe, { loading: syncingUser }) => (
                  <div className={classes.buttons}>
                    <Button
                      disabled={syncingUser}
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={async () => {
                        try {
                          await syncUserFromStripe({
                            variables: { userId },
                          });
                          alert(`Syncronisation complete for ${userId}`);
                        } catch (err) {
                          alert(`Syncronisation failed for ${userId}`);
                        }
                      }}
                    >
                      {syncingUser
                        ? 'Syncronising user...'
                        : 'Sync User from stripe'}
                    </Button>
                  </div>
                )}
              </Mutation>
            )}
          </Paper>
        </main>
      )}
    </Styled>
  );
}

const Styled = createStyled((theme: Theme) => {
  return createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing.unit * 3,
      marginLeft: theme.spacing.unit,
    },
    title: {
      marginBottom: theme.spacing.unit * 3,
    },
    nested: {
      paddingLeft: theme.spacing.unit + 4,
    },
    pendingOpsListItem: {
      fontSize: 13,
    },
  });
});

export default Form;
