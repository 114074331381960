import * as React from 'react';
import { createStyled } from 'shared/hocs';
import { Paper, Theme, Typography, createStyles } from '@material-ui/core';

function WelcomeView() {
  return (
    <Styled>
      {({ classes }) => (
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography
              className={classes.title}
              component="h1"
              variant="h5"
              align="center"
            >
              Unicorn
            </Typography>
            <Typography variant="body1" paragraph align="center">
              Log in using your Trint Google ID ⇗
            </Typography>
          </Paper>
        </main>
      )}
    </Styled>
  );
}

const Styled = createStyled((theme: Theme) => {
  return createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    title: {
      marginBottom: theme.spacing.unit,
    },
    subtitle: {
      marginBottom: theme.spacing.unit * 3,
    },
  });
});

export default WelcomeView;
