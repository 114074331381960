import React from 'react';
import StripeButtonBase from './StripeButtonBase';

interface Props {
  customerId: string | null | undefined;
}

const StripeCustomerButton: React.SFC<Props> = props => {
  const disabled = !props.customerId;

  return (
    <StripeButtonBase
      disabled={disabled}
      itemUriPath="customers"
      itemId={props.customerId}
    />
  );
};

export default StripeCustomerButton;
