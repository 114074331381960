import * as React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import Avatar from '@material-ui/core/Avatar';

interface Props {
  img: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default class AvatarButton extends React.Component<Props> {
  render() {
    const { img, ...buttonProps } = this.props;

    return (
      <ButtonBase disableRipple disableTouchRipple {...buttonProps}>
        <Avatar src={img} />
      </ButtonBase>
    );
  }
}
