import React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { Table, TableRow, TableCell, TableHead } from '@material-ui/core';
import OrganisationLabelFromUser from 'organisations/components/OrganisationLabelFromUser';
import { ListCell } from 'organisations/components/ListCell';
import { StackedListCell } from 'organisations/components/StackedListCell';

interface RealtimeStream {
  _id: string;
  trintId: string;
  userId: string;
  status: string;
  config: {
    name: string;
    type: string;
    feedUrl: string;
    sourceApplication?: string;
  };
  controllerId: string;
  created: Date;
}

const REALTIME_RUNNING_ORG_QUERY = gql`
  query realtimeStreamsRunning {
    realtimeStreamsRunning {
      _id
      trintId
      userId
      status
      config {
        name
        type
        feedUrl
        sourceApplication
      }
      controllerId
      created
    }
  }
`;

export function RealtimeGlobalListPage() {
  const { data, error, loading } = useQuery(REALTIME_RUNNING_ORG_QUERY);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error</div>;
  if (!data) return null;

  const { realtimeStreamsRunning } = data;

  if (realtimeStreamsRunning.length < 1) {
    return <div>No streams currently running</div>;
  }

  return (
    <>
      <h2> Running Streams: </h2>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Organisation</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Stream Type</TableCell>
            <TableCell>Source</TableCell>
            <TableCell align="left">Stream Id</TableCell>
            <TableCell align="left">Created</TableCell>
            <TableCell align="left">User Id and Trint Id</TableCell>
          </TableRow>
        </TableHead>
        {realtimeStreamsRunning.map((stream: RealtimeStream) => {
          return (
            <TableRow key={stream._id}>
              <TableCell align="left">
                <OrganisationLabelFromUser userId={stream.userId} />
              </TableCell>
              <ListCell content={stream.config.name} />
              <ListCell content={stream.status} />
              <ListCell content={stream.config.type} />
              <ListCell
                style={{minWidth: '70px'}}
                content={
                  stream.config.sourceApplication
                    ? stream.config.sourceApplication
                    : undefined
                }
              />
              <ListCell content={stream._id} />
              <ListCell content={new Date(stream.created).toUTCString()} />
              <StackedListCell
                firstContent={stream.userId}
                secondContent={stream.trintId}
              />
            </TableRow>
          );
        })}
      </Table>
    </>
  );
}

export default RealtimeGlobalListPage;
