import * as React from 'react';
import { isEmptyOrSpaces } from 'shared/utils';
import { QueryString } from 'shared/components';
import { PagingDefinition } from './ListPage';

interface RenderProps {
  value: string;
  context: SearchContextTypes | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

interface InnerProps {
  render: (props: RenderProps) => React.ReactNode;
  search?: string;
  updateSearch: (context: SearchContextTypes, value?: string) => void;
}

export type SearchContextTypes = 'USERS' | 'ORGANISATIONS';

const TAB_KEY = 9;
const ENTER_KEY = 13;
const BACKSPACE_KEY = 8;

interface State {
  value: string;
  context: SearchContextTypes | null;
}

class Inner extends React.Component<InnerProps, State> {
  state: State = { value: '', context: null };

  onSearchTextChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget;
    const { context } = this.state;

    if (context !== null && isEmptyOrSpaces(value)) {
      this.setState({ context: null });
    }

    this.setState({ value });
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { value, context } = this.state;
    if (isEmptyOrSpaces(value)) {
      if (e.keyCode === BACKSPACE_KEY && context !== null) {
        this.setState({ context: null });
      }
      return;
    }

    if (e.keyCode === TAB_KEY) {
      this.setContext();
      e.preventDefault();
      return;
    }

    if (e.keyCode === ENTER_KEY) {
      this.props.updateSearch(context || 'USERS', value);
      this.clearSearch();
    }
  };

  setContext = () => {
    const prefix = this.state.value.trim().toUpperCase();
    const contexts: SearchContextTypes[] = ['USERS', 'ORGANISATIONS'];
    let context: SearchContextTypes | undefined;
    while ((context = contexts.pop())) {
      if (context.startsWith(prefix)) {
        this.setState({ context, value: '' });
        break;
      }
    }
  };

  clearSearch = () => {
    this.setState({ value: '', context: null });
  };

  render() {
    const { value, context } = this.state;
    const { render } = this.props;

    return render({
      value,
      context,
      onChange: this.onSearchTextChanged,
      onKeyDown: this.onKeyDown,
    });
  }
}

interface Props {
  render: (props: RenderProps) => React.ReactNode;
}

const LOCATIONS: { [K in SearchContextTypes]: string } = {
  USERS: '/users',
  ORGANISATIONS: '/organisations',
};

class SearchInput extends React.Component<Props> {
  render() {
    return (
      <QueryString<{ search?: string } & PagingDefinition>>
        {({ updateAt, params }) => {
          return (
            <Inner
              {...this.props}
              search={params.search}
              updateSearch={(context: SearchContextTypes, search: string) => {
                updateAt(
                  LOCATIONS[context],
                  { search, perPage: 10, page: 0, order: 'desc' },
                  { replace: true },
                );
              }}
            />
          );
        }}
      </QueryString>
    );
  }
}

export default SearchInput;
