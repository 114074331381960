import * as React from 'react';
import { Dialog } from '@material-ui/core';
import * as components from 'modals/modals';
import { BaseModalConsumer } from 'contexts/ModalTrigger';

export class ModalRoot extends React.Component<{}> {
  render() {
    return (
      <BaseModalConsumer>
        {context => {
          const { modals } = context;
          return modals.map((x, i) => {
            const Modal = components[x.modal] as any;
            return (
              <Dialog key={i} open onClose={context.closeModal}>
                <Modal
                  {...x.props}
                  openModal={context.openModal}
                  closeModal={context.closeModal}
                />
              </Dialog>
            );
          });
        }}
      </BaseModalConsumer>
    );
  }
}

export default ModalRoot;
