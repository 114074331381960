import * as React from 'react';
import { Link } from 'react-router-dom';

import { createStyled } from 'shared/hocs';
import {
  Paper,
  Theme,
  Typography,
  createStyles,
  Button,
} from '@material-ui/core';

function IntegrationsPage() {
  return (
    <Styled>
      {({ classes }) => (
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography
              className={classes.title}
              component="h1"
              variant="h5"
              align="center"
            >
              Integrations
            </Typography>
            <Typography
              className={classes.subtitle}
              variant="subtitle2"
              align="center"
            >
              Trint's Integrations with 3rd Parties
            </Typography>
            <Typography variant="body1" paragraph>
              You will need to log into the 3rd party to complete the
              integration.
            </Typography>
            <Link to="/integrations/salesforce" className={classes.link}>
              <Button variant="contained" color="primary">
                Salesforce
              </Button>
            </Link>
            <Typography className={classes.footer} variant="caption">
              Integrations may expire or be revoked by the 3rd party for
              security reasons.
            </Typography>
          </Paper>
        </main>
      )}
    </Styled>
  );
}

export const Styled = createStyled((theme: Theme) => {
  return createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    title: {
      marginBottom: theme.spacing.unit,
    },
    subtitle: {
      marginBottom: theme.spacing.unit * 3,
    },
    footer: {
      marginTop: theme.spacing.unit * 6,
    },
    link: {
      textDecoration: 'none',
    },
  });
});

export default IntegrationsPage;
