import React from 'react';
import { createStyled } from 'shared/hocs';
import { Theme, Typography, createStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import UnicornTriviaItem from './UnicornTriviaItem';

const trivia = [
  {
    title: 'The unicorn is Scotland’s national animal.',
    tooltip:
      'In Celtic mythology the unicorn was a symbol of purity and innocence.',
    url:
      'https://www.visitscotland.com/about/uniquely-scottish/national-animal-unicorn/',
  },
  {
    title: 'Fun unicorn facts',
    tooltip: 'Unicorns rule!',
    url: 'http://www.unicornsrule.com/unicorn-facts/',
  },
];

function UnicornTrivia() {
  // Get a random piece of trivia
  const { title, tooltip, url } = trivia.sort(() => 0.5 - Math.random())[0];

  return (
    <Styled>
      {({ classes }) => (
        <UnicornTriviaItem chance={0.1}>
          <Typography className={classes.trivia} variant="caption">
            <Tooltip title={tooltip}>
              <React.Fragment>
                <span role="img" aria-label="Unicorn" className="mirror">
                  🦄
                </span>{' '}
                <a href={url}>{title}</a>
              </React.Fragment>
            </Tooltip>
          </Typography>
        </UnicornTriviaItem>
      )}
    </Styled>
  );
}

const Styled = createStyled((theme: Theme) => {
  return createStyles({
    trivia: {
      '& a': {
        textDecoration: 'none',
      },
      '& a:hover': {
        textDecoration: 'underline',
      },
    },
  });
});

export default UnicornTrivia;
