import React from 'react';
import gql from 'graphql-tag';
import { AppBar } from '@material-ui/core';
import { useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import CouponsTable from '../vouchers/components/CouponsTable';

import { createStyled } from 'shared/hocs';
import { Theme, createStyles } from '@material-ui/core';

interface Props extends RouteComponentProps<any> {}

export const COUPON_BATCH_QUERY = gql`
  query($batchId: String!) {
    couponBatch(batchId: $batchId) {
      count
      lastCouponId
      coupons {
        id
        name
        valid
        percent_off
      }
    }
  }
`;

function VouchersBatchDetailsPage(props: Props) {
  const { params } = props.match;
  const queryResult = useQuery(COUPON_BATCH_QUERY, {
    variables: { batchId: params.batchId },
  });

  return (
    <AppBar position="static" color="default">
      <Styled>
        {({ classes }) => (
          <CouponsTable
            className={classes.tableContainer}
            queryResult={queryResult}
            mapDataToCoupons={(data) => data.couponBatch.coupons}
          />
        )}
      </Styled>
    </AppBar>
  );
}

const Styled = createStyled((theme: Theme) =>
  createStyles({
    tableContainer: {
      marginTop: theme.spacing.unit * 5,
      marginLeft: theme.spacing.unit * 5,
      marginRight: theme.spacing.unit * 5,
    },
  }),
);

export default VouchersBatchDetailsPage;
