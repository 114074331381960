import React from 'react';
import {
  Button,
  CircularProgress,
  TableCell,
  TableRow,
} from '@material-ui/core';
import moment from 'moment';
import { Info, Cancel } from '@material-ui/icons';
import { Log, SecureDeletionState } from './models';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

interface Props {
  row: Log;
  onSelect: (row: Log) => void;
}

export const CANCEL_GDPR_REQ = gql`
  mutation cancelGdprRequest($id: String!) {
    cancelGdprDelete(id: $id) {
      _id
      outcome {
        status
      }
    }
  }
`;

interface MutationVars {
  id: string;
}

interface MutationRes {
  cancelGdprDelete: {
    _id: string;
    outcome: {
      status: string;
    };
  };
}

export function BulkDeleteRow(props: Props) {
  const { row, onSelect } = props;
  const { status } = row.outcome;
  const [cancelRequest, { loading }] = useMutation<MutationRes, MutationVars>(
    CANCEL_GDPR_REQ,
    {
      variables: {
        id: row._id,
      },
      onError: e => {
        alert(e.message);
      },
    },
  );

  const batchProcessed =
    status === SecureDeletionState.Error ||
    status === SecureDeletionState.Completed ||
    status === SecureDeletionState.Partial;

  const canCancel = status === SecureDeletionState.Pending;
  const isProcessing = status === SecureDeletionState.Processing;

  return (
    <TableRow data-testid="log-delete-row" key={row._id}>
      <TableCell>{row._id}</TableCell>
      <TableCell>{moment(row.created).format('ddd DD MMM YYYY')}</TableCell>
      <TableCell>{row.executedBy.username}</TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>
        {batchProcessed && (
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            onClick={() => {
              onSelect(row);
            }}
            data-testid="more-info-button"
          >
            <Info /> More Info
          </Button>
        )}
        {canCancel && (
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            disabled={loading}
            onClick={() => cancelRequest()}
            data-testid="cancel-button"
          >
            <Cancel />
            {loading ? 'Cancelling' : 'Cancel'}
          </Button>
        )}

        {isProcessing && (
          <span data-testid="processing-spinner">
            <CircularProgress />
          </span>
        )}
      </TableCell>
    </TableRow>
  );
}
