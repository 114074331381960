import React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { featureFlagKeys } from 'shared/constants/featureFlagKeys';
import { Grid } from '@material-ui/core';

interface Props {
  ownerId: string;
}

const FF_OWNER_QUERY = gql`
  query orgOwnerFeatureFlags($userId: String!) {
    getUserFeatureFlags(userId: $userId) {
      featureFlags
    }
  }
`;

export function RealtimeDetailsForOrg(props: Props) {
  const { ownerId } = props;
  const { data, error, loading } = useQuery(FF_OWNER_QUERY, {
    variables: {
      userId: ownerId,
    },
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error</div>;
  if (!data) return null;

  const { featureFlags } = data.getUserFeatureFlags;

  const isRealtimeEnabled = featureFlags.includes(featureFlagKeys.realtime);
  const isPushEnabled = featureFlags.includes(featureFlagKeys.realtimePush);
  const streamsFromFlag = featureFlags.find((f: string) =>
    f.includes(featureFlagKeys.realtimeConcurrentStreams),
  );

  const streamsAllowed = streamsFromFlag!.split(':')[1];

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          Pull enabled: {isRealtimeEnabled ? 'Yes' : 'No'}
        </Grid>
        <Grid item xs={3}>
          Push enabled: {isPushEnabled ? 'Yes' : 'No'}
        </Grid>
        <Grid item xs={3}>
          Streams limit: {streamsAllowed ? streamsAllowed : '3'}
        </Grid>
      </Grid>
    </>
  );
}

export default RealtimeDetailsForOrg;
