import * as React from 'react';
import { useQuery } from 'react-apollo';
import { Warning } from '@material-ui/icons';
import {
  Theme,
  Paper,
  WithStyles,
  Typography,
  withStyles,
  createStyles,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { StripeCustomerButton } from 'shared/components';
import {
  EnterpriseUserQueryResult,
  EnterpriseUserQueryVars,
  ENTERPRISE_USER_QUERY,
} from 'enterprise/queries';
import EnterpriseAccountActionsMenu from './EnterpriseAccountActionsMenu';

interface Props {
  userId: string;
}

function EnterpriseAccountSection(props: Props & WithStyles<typeof styles>) {
  const { userId, classes } = props;

  const { data, loading, error } = useQuery<
    EnterpriseUserQueryResult,
    EnterpriseUserQueryVars
  >(ENTERPRISE_USER_QUERY, {
    variables: {
      userId,
    },
    fetchPolicy: 'network-only',
  });

  if (error) {
    return (
      <main className={classes.layout}>
        <p>{error.message}</p>
      </main>
    );
  }

  if (loading && (!data || !data.user)) {
    return (
      <main className={classes.layout}>
        <p>Loading</p>
      </main>
    );
  }

  if (!data || !data.user) {
    return (
      <main className={classes.layout}>
        <p>User not found</p>
      </main>
    );
  }

  const enterpriseName = data.user.billing?.planName ?? 'Unknown';
  const accountHolder = data.user.username;
  const subscriptionSeatCount = data.user.billing.v2.subscriptionSeatCount ?? 1;
  const subscriptionStatus =
    data.user.billing.v2.subscriptionStatus ?? 'No status';

  const organisationMembersCount = data.user.group?.totalMembers ?? 1;
  const organisationGroupId = data.user.group?._id;

  const internalContactsArray =
    data.user.currentSubscription.internalContacts ?? [];
  const internalContacts = internalContactsArray.length
    ? internalContactsArray.join(' ')
    : 'No contacts';

  const overSeatLimit = organisationMembersCount > subscriptionSeatCount;
  const contractEndDate =
    data.user.currentSubscription.contractEndDate ?? 'No contract end date';

  return (
    <main className={classes.layout}>
      <Paper className={classes.paper}>
        <Grid container spacing={24}>
          <Grid item xs={6}>
            <Typography component="h1" variant="h5">
              Enterprise Account
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <EnterpriseAccountActionsMenu userId={userId} />
          </Grid>
        </Grid>

        <p>&nbsp;</p>

        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Enterprise name
              </TableCell>
              <TableCell align="right">{enterpriseName}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <p>&nbsp;</p>

        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Account holder
              </TableCell>
              <TableCell align="right">{accountHolder}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Customer ID
              </TableCell>
              <TableCell align="right">
                <StripeCustomerButton customerId={data.user.customer.id} />
                {data.user.customer.id}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Status
              </TableCell>
              <TableCell align="right">{subscriptionStatus}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Internal contacts
              </TableCell>
              <TableCell align="right">{internalContacts}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Contract End
              </TableCell>
              <TableCell align="right">{contractEndDate}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Plan ID
              </TableCell>
              <TableCell align="right">{data.user.billing.plan}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Currency
              </TableCell>
              <TableCell align="right">{data.user.billing.currency}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Members and Seats
              </TableCell>
              <TableCell align="right">
                <p className={classes.seatCountStatus}>
                  <span className={classes.seatCountStatusLabel}>
                    {organisationGroupId ? (
                      <Link
                        className={classes.link}
                        to={`/organisations/${organisationGroupId}/members`}
                      >
                        {data.user.group.totalActiveMembers} of
                        {data.user.group.totalSeats}
                      </Link>
                    ) : (
                      `${data.user.group.totalActiveMembers} of ${data.user.group.totalSeats}`
                    )}
                  </span>
                  {overSeatLimit && (
                    <Warning className={classes.seatCountStatusIcon} />
                  )}
                </p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Total Seats
              </TableCell>
              <TableCell align="right">{data.user.group.totalSeats}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Total Members
              </TableCell>
              <TableCell align="right">
                {data.user.group.totalMembers}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Total Active Members
              </TableCell>
              <TableCell align="right">
                {data.user.group.totalActiveMembers}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Has SCIM enabled
              </TableCell>
              <TableCell align="right">
                {data.user.group.isScimEnabled ? 'yes' : 'no'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </main>
  );
}

const styles = (theme: Theme) => {
  return createStyles({
    seatCountStatus: {
      verticalAlign: 'bottom',
      lineHeight: '24px',
      whiteSpace: 'nowrap',
    },
    seatCountStatusLabel: {
      display: 'inline-block',
      minWidth: '40px',
    },
    seatCountStatusIcon: {
      verticalAlign: 'inherit',
      marginLeft: '7px',
      padding: '2px',
      color: '#fed000',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    link: {
      textDecoration: 'underline',
      color: theme.palette.primary.dark,
      '&visited': {
        textDecoration: 'none',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  });
};

export default withStyles(styles)(EnterpriseAccountSection);
