import * as React from 'react';
import PeopleIcon from '@material-ui/icons/Person';
import OrganisationsIcon from '@material-ui/icons/Business';
import HomeIcon from '@material-ui/icons/Home';
import PlansIcon from '@material-ui/icons/Money';
import IntegrationsIcon from '@material-ui/icons/SwapHoriz';
import ToolsIcon from '@material-ui/icons/Settings';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AddAlert from '@material-ui/icons/AddAlert';
import ComputeIcon from '@material-ui/icons/Computer';

export interface DrawerItem {
  path: string;
  allowAnonymous: boolean;
  roles: string[];
  label: string;
  ariaLabel: string;
  icon: React.ReactElement<any>;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const items: DrawerItem[] = [
  {
    path: '/',
    label: 'Home',
    allowAnonymous: true,
    roles: [],
    icon: <HomeIcon color="inherit" />,
    ariaLabel: 'Home',
  },
  {
    path: '/users',
    label: 'User Accounts',
    allowAnonymous: false,
    roles: [],
    icon: <PeopleIcon color="inherit" />,
    ariaLabel: 'Search user accounts and perform actions',
  },
  {
    path: '/enterprises',
    label: 'Enterprise Accounts',
    allowAnonymous: false,
    roles: [],
    icon: <AccountBalanceIcon color="inherit" />,
    ariaLabel: 'Search for Enterprise Accounts and perform actions',
  },
  {
    path: '/organisations',
    label: 'Organisations',
    allowAnonymous: false,
    roles: [],
    icon: <OrganisationsIcon color="inherit" />,
    ariaLabel: 'Search for organisations and perform actions on them',
  },
  {
    path: '/plans',
    label: 'Plans',
    allowAnonymous: false,
    roles: [],
    icon: <PlansIcon color="inherit" />,
    ariaLabel: 'Find plans codes',
  },
  {
    path: '/integrations',
    label: 'Integrations',
    allowAnonymous: false,
    roles: [],
    icon: <IntegrationsIcon color="inherit" />,
    ariaLabel: 'Setup integrations',
  },
  {
    path: '/tools',
    label: 'Tools',
    allowAnonymous: false,
    roles: [],
    icon: <ToolsIcon color="inherit" />,
    ariaLabel: 'Tools',
  },
  {
    path: '/realtime',
    label: 'Realtime Global list',
    allowAnonymous: false,
    roles: [],
    icon: <LiveTvIcon color="inherit" />,
    ariaLabel: 'LiveTv',
  },
  {
    path: '/vouchers/single',
    label: 'Vouchers List',
    allowAnonymous: false,
    roles: [],
    icon: <ConfirmationNumberIcon color="inherit" />,
    ariaLabel: 'Vouchers',
  },
  {
    path: '/incidents',
    label: 'Raise an Incident',
    allowAnonymous: false,
    roles: [],
    icon: <AddAlert color="inherit" />,
    ariaLabel: 'Incidents',
  },
  {
    path: '/notifications',
    label: 'Send a notification',
    allowAnonymous: false,
    roles: [],
    icon: <NotificationsActiveIcon color="inherit" />,
    ariaLabel: 'Incidents',
  },
  {
    path: '/ec2launcher',
    label: 'Manage adhoc EC2 instances',
    allowAnonymous: false,
    roles: [],
    icon: <ComputeIcon color="inherit" />,
    ariaLabel: 'Ec2Launcher',
  },
];

export default items;
