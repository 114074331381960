import * as React from 'react';
import classnames from 'classnames';
import { createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  AppBar,
  Divider,
  Toolbar,
  Typography,
  Theme,
  Input,
} from '@material-ui/core';
import {
  NavbarLoginButton,
  DrawerMenuItem,
  OmniSearchInput,
} from 'shared/components';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search';
import PeopleIcon from '@material-ui/icons/Person';
import OrganisationsIcon from '@material-ui/icons/Business';
import { DrawerItem } from 'pages/drawerLinks';
import { createStyled } from 'shared/hocs';
import CssBaseline from '@material-ui/core/CssBaseline';
import Tooltip from '@material-ui/core/Tooltip';
import { AuthContextState } from './UserContext';
import UnicornTenantSelector from './UnicornTenantSelector';

const SEARCH_ICONS = {
  USERS: PeopleIcon,
  ORGANISATIONS: OrganisationsIcon,
  [undefined!]: SearchIcon,
  [null!]: SearchIcon,
};

interface Props {
  children: React.ReactNode;
  menuItems: DrawerItem[];
  auth: AuthContextState;
}

interface State {
  open: boolean;
}

class SideDrawer extends React.Component<Props, State> {
  state = { open: false };

  handleDrawerToggle = (): void => {
    this.setState(({ open }) => ({ open: !open }));
  };

  render() {
    const { open } = this.state;
    const { menuItems, auth } = this.props;

    return (
      <Styled>
        {({ classes }) => (
          <>
            <CssBaseline />
            <div className={classes.root}>
              <AppBar
                position="absolute"
                className={classnames(classes.appBar)}
              >
                <Toolbar disableGutters className={classes.sidebar}>
                  <Typography
                    variant="h6"
                    color="inherit"
                    noWrap
                    className={classes.title}
                  >
                    <span
                      role="img"
                      aria-label="Unicorn"
                      className={classnames('mirror', classes.unicornIcon)}
                    >
                      🦄
                    </span>{' '}
                    Unicorn
                  </Typography>
                  {auth.isAuthorised && (
                    <>
                      <div className={classes.endpointSelector}>
                        <UnicornTenantSelector />
                      </div>

                      <div className={classes.search}>
                        <OmniSearchInput
                          render={props => {
                            const Icon = SEARCH_ICONS[props.context!];
                            return (
                              <>
                                <div className={classes.searchIcon}>
                                  <Icon />
                                </div>
                                <Tooltip
                                  title="Enter user search string"
                                  placement="left"
                                >
                                  <Input
                                    {...props}
                                    disableUnderline
                                    placeholder="Search users…"
                                    classes={{
                                      root: classes.inputRoot,
                                      input: classes.inputInput,
                                    }}
                                    aria-label="Enter user search string"
                                  />
                                </Tooltip>
                              </>
                            );
                          }}
                        />
                      </div>
                    </>
                  )}
                  <NavbarLoginButton />
                </Toolbar>
              </AppBar>
              <Drawer
                variant="permanent"
                classes={{
                  paper: classnames(
                    classes.drawerPaper,
                    !open && classes.drawerPaperClose,
                  ),
                }}
                open={open}
              >
                <List component="nav" className={classes.sidebarNav}>
                  {menuItems.map(item => (
                    <DrawerMenuItem key={item.path} {...item} />
                  ))}
                </List>
                <Divider className={classes.divider} />
                <List
                  component="menu"
                  className={classnames(
                    classes.sidebarFooter,
                    open && classes.sidebarFooterOpen,
                  )}
                >
                  <Divider className={classes.divider} />
                  <Tooltip
                    title={`${open ? 'Collapse' : 'Open'} sidebar`}
                    placement="right"
                  >
                    <ListItem
                      className={classes.sidebarItem}
                      button
                      aria-label={`${open ? 'Collapse' : 'Open'} sidebar`}
                      onClick={this.handleDrawerToggle}
                    >
                      <React.Fragment>
                        <ListItemIcon
                          color="inherit"
                          className={classes.sidebarIcon}
                        >
                          {open ? (
                            <ChevronLeftIcon className={classes.sidebarIcon} />
                          ) : (
                            <ChevronRightIcon className={classes.sidebarIcon} />
                          )}
                        </ListItemIcon>
                        <ListItemText>
                          <span className={classes.sidebarText}>
                            Collapse sidebar
                          </span>
                        </ListItemText>
                      </React.Fragment>
                    </ListItem>
                  </Tooltip>
                </List>
              </Drawer>
              <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                {this.props.children}
              </main>
            </div>
          </>
        )}
      </Styled>
    );
  }
}

const drawerWidth = 240;

const Styled = createStyled((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    unicornIcon: {
      display: 'inline-block',
      width: 72,
      padding: '12px 24px',
    },
    sidebar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    sidebarNav: {
      marginTop: 64,
      paddingTop: 11,
      paddingBottom: 0,
    },
    sidebarFooter: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      paddingBottom: 0,
      background: '#354052',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 72,
      overflow: 'hidden',
    },
    sidebarFooterOpen: {
      width: drawerWidth,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    sidebarItem: {
      paddingLeft: 24,
    },
    sidebarIcon: {
      color: '#ffffff66',
      margin: 0,
    },
    sidebarText: {
      color: '#ffffff66',
      marginLeft: 16,
    },
    appBar: {
      background: '#354052',
      color: theme.palette.getContrastText('#354052'),
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      background: '#354052',
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing.unit * 7,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing.unit * 9,
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    chartContainer: {
      marginLeft: -22,
    },
    tableContainer: {
      height: 320,
    },
    listItem: {
      color: theme.palette.text.secondary,
    },
    divider: {
      backgroundColor: '#ffffff66',
    },
    h5: {
      marginBottom: theme.spacing.unit * 2,
    },
    endpointSelector: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      marginRight: theme.spacing.unit * 2,
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing.unit * 3,
        width: 'auto',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing.unit * 2,
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing.unit * 3,
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing.unit * 9,
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      paddingTop: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: theme.spacing.unit * 10,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 200,
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
);

export default SideDrawer;
