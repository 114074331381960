import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import { USER_BILLING_QUERY } from 'users/queries';
import { CancelSubscriptionDialog } from './CancelSubscriptionDialog';
import { Button, Typography } from '@material-ui/core';
import moment from 'moment';
import { CANCEL_SUBSCRIPTION_V2 } from '../mutations';
import { BillingPurchaseSource } from 'types';

interface Props {
  userId: string;
  isMobileSubscription: boolean;
  purchaseSource?: BillingPurchaseSource;
  cancellationDate: number | null;
  subscriptionStatus: string;
  isTrialist: boolean;
}

function CancelUserSubscription(props: Props) {
  const {
    userId,
    isMobileSubscription,
    purchaseSource,
    cancellationDate,
    subscriptionStatus,
    isTrialist,
  } = props;
  const [isCancelSubDialogOpen, setIsCancelSubDialogOpen] = useState(false);

  return (
    <>
      {isMobileSubscription ? (
        <Typography>
          This user's subscription was purchased through their{' '}
          <strong>{purchaseSource}</strong> and requires them to manage it from
          their mobile device.
        </Typography>
      ) : (
        <>
          {subscriptionStatus !== 'cancelled' && cancellationDate === null ? (
            <Mutation<{}, { userId: string }>
              // mutation to cancel an account
              mutation={CANCEL_SUBSCRIPTION_V2}
              refetchQueries={[
                {
                  query: USER_BILLING_QUERY,
                  variables: { userId },
                },
              ]}
              onError={() => {}}
              onCompleted={() => setIsCancelSubDialogOpen(false)}
              variables={{ userId }}
            >
              {(
                cancelSubscription,
                { loading: isLoading, error: mutationError },
              ) => {
                if (isLoading) return null;
                if (mutationError) return <div>{mutationError.message}</div>;
                return (
                  <>
                    {isCancelSubDialogOpen && (
                      <CancelSubscriptionDialog
                        cancelSubscription={cancelSubscription}
                        handleClose={() => setIsCancelSubDialogOpen(false)}
                      />
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={isTrialist}
                      onClick={() => setIsCancelSubDialogOpen(true)}
                      data-testid="cancel-button"
                    >
                      Cancel Subscription {'v2'}
                    </Button>
                  </>
                );
              }}
            </Mutation>
          ) : (
            <Typography>
              This user's subscription
              {cancellationDate &&
                ` will be cancelled on ${moment(cancellationDate).format(
                  'MMMM Do YYYY',
                )}`}
              {subscriptionStatus === 'cancelled' && ' has been cancelled'}
            </Typography>
          )}
        </>
      )}
    </>
  );
}

export default CancelUserSubscription;
