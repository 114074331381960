import * as React from 'react';
import { createStyled } from 'shared/hocs';
import { Theme, createStyles, CircularProgress } from '@material-ui/core';

interface Props {
  show: boolean;
}

const Styled = createStyled((theme: Theme) =>
  createStyles({
    progress: {
      color: theme.palette.secondary.dark,
      bottom: 0,
      right: 0,
      position: 'fixed',
      margin: theme.spacing.unit * 5,
    },
  }),
);

const CircleLoader: React.SFC<Props> = props => {
  return props.show ? (
    <Styled>
      {({ classes }) => (
        <CircularProgress className={classes.progress} size={50} />
      )}
    </Styled>
  ) : null;
};

export default CircleLoader;
