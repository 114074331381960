import * as React from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { IconButton } from '@material-ui/core';
import AvatarButton from 'shared/components/AvatarButton';

interface AvatarOrDefaultProps {
  src: string | null;
  ariaOwns?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const AvatarOrDefault: React.SFC<AvatarOrDefaultProps> = ({
  src,
  onClick,
  ariaOwns,
  ...rest
}) => {
  if (src) {
    return <AvatarButton onClick={onClick} img={src} {...rest} />;
  }

  return (
    <IconButton
      aria-haspopup
      onClick={onClick}
      color="inherit"
      aria-owns={ariaOwns}
      {...rest}
    >
      <AccountCircle />
    </IconButton>
  );
};

export default AvatarOrDefault;
