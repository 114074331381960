/* 

NOTE:

For now the plan is to to define all (top level) business types in this file and pull them into the files where they're needed.
modules (i.e. users/organisations/files/etc) should only know about types that defined here (in this very file) or in their own directories. 
Of course this is likely to change since this file could get rather large but for now and until we have a better idea of our requirements 
this solution works best 
- dont think too hard about where to put a type that models a business entity, it goes in here. 
- this structure (no structure) will be the easiest to refactor and move away from when the time comes
- For gql queries that return subsets of data, pull the type in from this file and Pick<> off the properties you need
    - export the sub-type from the query/mutation that uses it.
    - For example, if your gql query is called "USER_BILLING_QUERY" then the exported type should be called "UserBillingQueryResult"
*/

export interface TrintUser {
  _id: string;
  userId: string;
  username: string;
  profile: TrintUserProfile;
  allowance: Allowance;
  billing: Billing;
  verified: boolean;
  created: number;
  updated: number;
  apiKey: string | null;
  currentTeam: string | null;
  externalIds: string[];
  featureFlags: string[];
  group: TrintGroup;
  userType: string;
  customer: StripeCustomer | null;
  version: string;
  organisationAdmin: boolean;

  // state: userState
  // callbackSettings: CallbackSettings
  currentSubscription: PlanDetails;
  // nextSubscription: PlanDetails
  // preferences: Preferences
  // reporting: Reporting
}

export interface PlanDetails {
  id: string;
  planId: string;
  internalContacts?: string[];
  contractEndDate?: string;
  trial: boolean;
  vip: boolean;
  payg: boolean;
  title: string;
  persona: string;
  hours: number;
  minutes: number;
  seconds: number;
  free: number;
  bonus: number;
  pricing: Pricing;
  price: Currency;
  extra: Currency;
  fee: Currency;
  planCode: string;
  planType: string;
  features: [string];
  currentPlan: boolean;
  currency: string;
  referrerBonus: number;
  prospectBonus: number;
  pricingInterval: string;
}

export interface Pricing {
  price: number;
  extra: number;
  fee: number;
}

export interface Currency {
  eur: number;
  gbp: number;
  usd: number;
}

export interface TrintUserProfile {
  firstName: string;
  lastName: string;
  industry: string;
  companySize: string;
  country: string;
  city: string;
  company: string;
  department: string;
  jobTitle: string;
}

export interface V2 {
  cancellationDate: number;
  downgradeDate: number;
  subscriptionEnd: number;
  subscriptionStatus: string;
  subscriptionSeatCount: number;
}

export enum BillingPurchaseSource {
  STRIPE = 'STRIPE',
  APP_STORE = 'APP_STORE',
  PLAY_STORE = 'PLAY_STORE',
}

// determine what the data types ACTUALLY are
export interface Billing {
  currency: string;
  first_name: string;
  last_name: string;
  company: string;
  vat_number: string;
  address_line1: string;
  address_city: string;
  address_zip: string;
  address_country: string;
  plan: string;
  planName?: string;
  nextPlan: string;
  nextPlanUpgrade: string;
  last4: string;
  brand: string;
  exp_month: number;
  exp_year: number;
  delinquent: boolean;
  path: string;
  joinInvoiceItem: string;
  subscriptionFrozen?: string;
  pendingOperations?: BillingOperation[];
  v2: V2;
  purchaseSource?: BillingPurchaseSource;
  paymentMethodId?: string;
}

export enum BillingOperationTypes {
  Downgrade = 'downgrade',
  Cancelling = 'cancelling',
  NoOp = 'noop',
  PaymentIntentAuthExpiry = 'payment-intent-auth-expiry',
}

export type BillingOperation =
  | DowngradeOperation
  | CancellingOperation
  | NoopBillingOperation
  | PaymentIntentAuthExpiryOperation;

interface DowngradeOperation {
  id: string;
  type: BillingOperationTypes.Downgrade;
  executeAt: string;
  data: {
    nextPlanId: string;
    nextSeats: number;
  };
}

interface NoopBillingOperation {
  id: string;
  type: BillingOperationTypes.NoOp;
}
interface PaymentIntentAuthExpiryOperation {
  id: string;
  type: BillingOperationTypes.PaymentIntentAuthExpiry;
  executeAt: string;
  data: {
    basePlanId: string;
    paymentIntentId: string;
  };
}
interface CancellingOperation {
  id: string;
  type: BillingOperationTypes.Cancelling;
  executeAt: Date;
}

export interface Allowance {
  balance: number;
  credit: CreditBreakdown;
  dailyUsage: number;
  end: number;
  expectedUsage: number;
  extra: number;
  plan: number;
  planCode: string;
  seconds: number;
  start: number;
  subscriptionDay: number;
  usage: number;
  usageByPlan: number;
}

export interface CreditBreakdown {
  planAllowance: number;
  bonus: number;
}

export interface BillingLog {
  user: string;
  group: string;
  type: string;
  created: number;
  currency: string;
  from: Allowance;
  to: Allowance;
  reason: number;
}

export interface TrintGroup {
  _id: string;
  connectionIds: string[];
  name: string; // NOTE: This is not currently returned by the backend - see metadata.name instead
  type: number;
  admins: GroupMember[];
  members: GroupMember[];
  ownerId: string;
  owner: GroupMember;
  created: number;
  totalMembers: number;
  totalSeats: number;
  totalActiveMembers: number;
  isScimEnabled: boolean;
  usage: Usage;
  allowance: Allowance;
  customerCreated: number;
  stats: string[];
  isEnterprise: boolean;
  invited: GroupMember[];
  metadata: GroupMetaData;
  settings: GroupSettings;
}

export interface TrintTeam {
  _id: string;
  name: string;
  description: string;
  admins: GroupMember[];
  members: GroupMember[];
}

export interface GroupSettings {
  allowedSharingDomains: string[];
  disableHrd: boolean;
  disableSso: boolean;
  autoProvision: boolean;
  ssoDomains: string[];
}

export interface Usage {
  principal: string;
  count: UsageItem;
  uploaded: UsageItem;
  transcoded: UsageItem;
  transcribed: UsageItem;
  transcribing: UsageItem;
  blocked: UsageItem;
  capping: UsageItem;
  members: MemberUsage[];
}

export interface MemberUsage {
  _id: string;
  username: string;
  count: number;
  total: number;
  profile: TrintUserProfile;
}

export interface UsageItem {
  count: number;
  total: number;
  audio: number;
  video: number;
}

export interface GroupMetaData {
  name: string;
}

export interface Reporting {
  costCode?: string;
}

export interface GroupMember {
  _id: string;
  username: string;
  teamAdmin: boolean | null;
  organisationAdmin: boolean;
  profile: TrintUserProfile;
  currentTeam: string;
  reporting: Reporting | null;
  teamName: string | null;
  ownerId: string | null;
}

export interface Plan {
  title: string;
  planId: string;
  currentPlan: boolean;
  planType: string | null;
  planCode: string | null;
}

export interface StripeCustomer {
  id: string;
  currency: string;
  delinquent: boolean;
  email: string;
  invoice_prefix: string;
}

export interface TrintFile {
  _id: string;
  algorithm: string;
  duration: number;
  status: string;
  title: string;
  created: string;
  updated: string;
  uploadSource: string;
}
