import React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { TableRow, TableCell, Theme, createStyles } from '@material-ui/core';
import { Table, GlobalCircleLoader } from 'shared/components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { createStyled } from 'shared/hocs';
import * as create from 'shared/components/Table';
import VoucherStats from './VouchersStats';

interface CouponBatch {
  batchId: string;
  count: number;
  numberAvailable: number;
  numberUsed: number;
  created: number;
  coupons: {
    id: string;
  };
}

export const COUPON_BATCH_LIST_QUERY = gql`
  query {
    couponBatches {
      count
      batches {
        batchId
        count
        numberAvailable
        numberUsed
        created
        coupons {
          id
        }
      }
    }
  }
`;

function VoucherBatchList() {
  const { data, error, loading } = useQuery(COUPON_BATCH_LIST_QUERY);

  if (loading) return <GlobalCircleLoader show />;
  if (error) return <span>{error.message}</span>;
  if (!data) return null;

  const { batches } = data.couponBatches;

  const headers = [
    create.header('Voucher Batch'),
    create.header('Count'),
    create.header('Number Available'),
    create.header('Number Used'),
    create.header('Created'),
    create.header('Actions'),
  ];
  const colWidths = ['22.5%', '12.5%', '12.5%', '12.5%', '20%', '20%'];

  return (
    <Styled>
      {({ classes }) => (
        <>
          <VoucherStats batch />
          <Table headers={headers} colWidths={colWidths}>
            {batches.map((batch: CouponBatch) => {
              return (
                <TableRow key={batch.batchId}>
                  <TableCell>
                    <Link
                      to={`/vouchers/batches/${batch.batchId}`}
                      title="Voucher Batch"
                      className={classnames(classes.link)}
                    >
                      {batch.batchId}
                    </Link>
                  </TableCell>
                  <TableCell>{batch.count}</TableCell>
                  <TableCell>{batch.numberAvailable}</TableCell>
                  <TableCell>{batch.numberUsed}</TableCell>
                  <TableCell>
                    {moment(batch.created).format('ddd, MMM Do YYYY, H:mm:ss')}
                  </TableCell>
                  <TableCell>action</TableCell>
                </TableRow>
              );
            })}
          </Table>
        </>
      )}
    </Styled>
  );
}

const Styled = createStyled((theme: Theme) =>
  createStyles({
    width: {
      width: '100%',
    },
    capitalise: {
      textTransform: 'capitalize',
    },
    tableCellPadding: {
      padding: '4px 0px 4px 24px',
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&visited': {
        textDecoration: 'none',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    tooltipPlacementTop: {
      margin: '0 0 3px 0',
    },
    tooltipPopper: {
      '&[x-placement*="top"] ': {
        opacity: 1,
      },
    },
    lightTooltip: {
      backgroundColor: '#aaaaaa',
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
    textOverflow: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

export default VoucherBatchList;
