import { useEffect } from 'react';

interface Props {
  fn: () => void;
}

/**
 * AutoExecute - Execute function on mount
 * @param {Function} fn
 *        The function to execute
 */

export default function AutoExecute(props: Props) {
  const { fn } = props;
  useEffect(() => fn(), [fn]);
  return null;
}
