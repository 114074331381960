import { Address4, Address6 } from 'ip-address';

export function ipAddressListValidator(value?: string) {
  const ipAddresses =
    value
      ?.trim()
      .split(/[ ,]+/)
      ?.filter((ip) => !!ip) ?? [];
  const invalid =
    ipAddresses.length === 0 ||
    ipAddresses.some((ip) => {
      try {
        const ip4 = new Address4(ip);
        if (ip4.subnet !== '/32') {
          // We return true for invalid
          return true;
        }

        // Valid IPv4
        return false;
      } catch (e) {
        try {
          const ip6 = new Address6(ip);
          if (ip6.subnet !== '/128') {
            // We return true for invalid
            return true;
          }
          // Valid IPv6
          return false;
        } catch (e) {
          return true;
        }
      }
    });

  if (invalid) {
    return 'Invalid IP address list. Please provide a space or comma separated list of valid IP addresses. Network ranges are not allowed.';
  }
  return undefined;
}
