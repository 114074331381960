import React from 'react';
import { TableCell, Typography, Grid } from '@material-ui/core';

interface Props {
  firstContent: string;
  secondContent: string;
}
export const StackedListCell = ({ firstContent, secondContent }: Props) => {
  return (
    <TableCell align="left">
      <Grid container direction="column">
        <Grid item>
          <Typography>userId: {firstContent}</Typography>
        </Grid>
        <Grid item>
          <Typography>trintId: {secondContent}</Typography>
        </Grid>
      </Grid>
    </TableCell>
  );
};
