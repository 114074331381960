import * as React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { Mutation, MutationFunction, Query } from 'react-apollo';
import { createFormContext } from 'yafl';
import { USER_ALLOWANCE_QUERY, UserAllowanceQueryResult } from 'users/queries';
import { USER_ADJUST_END_DATE } from 'users/mutations';
import { FormTextInput, FormKit } from 'shared/formKit';
import { BaseModalProps, ModalTrigger } from 'contexts/ModalTrigger';
import { formatDate } from 'shared/utils';
import { ErrorDisplay } from 'shared/components';

interface ComponentProps {
  userId: string;
}

interface MutationVariables {
  userId: string;
  date: string;
}

interface Data {
  user: UserAllowanceQueryResult;
}

interface Variables {
  userId: string;
}

interface FormData {
  end: string;
}

interface InjectedProps {
  adjustEndDate: MutationFunction<Data, MutationVariables>;
}

type Props = ComponentProps &
  BaseModalProps & {
    user: UserAllowanceQueryResult;
  };

const context = createFormContext<FormData>();

const { Field } = context;

function Inner(props: Props & InjectedProps) {
  const { userId, user, adjustEndDate, closeModal } = props;
  const { allowance } = user;
  const { end } = allowance;
  const endInitialValue = new Date(end).toISOString().slice(0, 10);

  const handleSubmit = (value: FormData) => {
    adjustEndDate({
      variables: {
        userId,
        date: formatDate(value.end, 'dd/LL/yyyy'),
      },
    })
      .then(closeModal)
      .catch();
  };

  const onEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (value === '') e.preventDefault();
  };

  return (
    <FormKit<FormData>
      context={context}
      onSubmit={handleSubmit}
      initialValue={{
        end: endInitialValue,
      }}
      defaultValue={{ end: endInitialValue }}
    >
      {({ formValue, formIsValid, submit }) => (
        <>
          <DialogTitle id="form-dialog-title">
            Adjust End Date for ({user.username})
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={12}>
                <DialogContentText>
                  Set the Allowance End Date for a user's account.
                </DialogContentText>
                <Typography>Current End Date: {formatDate(end)}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  fullWidth
                  name="end"
                  label="End Date"
                  type="date"
                  onChange={onEndDateChange}
                  component={FormTextInput}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              Cancel
            </Button>
            <ModalTrigger<'ConfirmDialog'>
              modal="ConfirmDialog"
              modalProps={{
                onConfirm: submit,
                title:
                  "Are you sure you want to update this user's end date?",
              }}
            >
              {({ openModal }) => (
                <Button
                  disabled={!formIsValid}
                  onClick={openModal}
                  color="primary"
                  variant="contained"
                >
                  Done
                </Button>
              )}
            </ModalTrigger>
          </DialogActions>
        </>
      )}
    </FormKit>
  );
}

class AdjustEndDateModal extends React.Component<Props> {
  render() {
    const { userId, openModal, closeModal } = this.props;
    return (
      <Query<Data, Variables>
        query={USER_ALLOWANCE_QUERY}
        fetchPolicy="cache-and-network"
        variables={{ userId }}
      >
        {({ error, loading, data }) => {
          if (error) return null;
          if (loading && (!data || !data.user)) return null;

          return (
            <Mutation<Data, MutationVariables>
              mutation={USER_ADJUST_END_DATE}
              refetchQueries={[
                { query: USER_ALLOWANCE_QUERY, variables: { userId } },
              ]}
            >
              {(adjustEndDate, { error: mutationError }) => {
                return (
                  <>
                    {mutationError && (
                      <ErrorDisplay>{mutationError.message}</ErrorDisplay>
                    )}
                    <Inner
                      user={data!.user}
                      userId={userId}
                      openModal={openModal}
                      closeModal={closeModal}
                      adjustEndDate={adjustEndDate}
                    />
                  </>
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default AdjustEndDateModal;
