import React from 'react';
import { IconButton, Theme, createStyles } from '@material-ui/core';
import classnames from 'classnames';
import { createStyled } from 'shared/hocs';
import stripeIcon from '../assets/StripeButton.svg';
import stripeIconDisabled from '../assets/StripeButtonDisabled.svg';
import config from '../../config';

interface Props {
  itemId: string | null | undefined;
  itemUriPath: string;
  disabled: boolean;
}

const StripeButtonBase: React.SFC<Props> = props => {
  const disabled = props.disabled;
  const sanitisedItemUriPath = props.itemUriPath.replace(/^\/|\/$/g, '');

  return (
    <Styled>
      {({ classes }) => (
        <IconButton disabled={disabled}>
          {disabled ? (
            <img
              width="20"
              height="20"
              alt="stripe disabled icon"
              src={stripeIconDisabled}
            />
          ) : (
            <a
              className={classnames(classes.plainLink)}
              href={`${config.STRIPE_BASE_URL}/${sanitisedItemUriPath}/${
                props.itemId
              }`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img width="20" height="20" alt="stripe icon" src={stripeIcon} />
            </a>
          )}
        </IconButton>
      )}
    </Styled>
  );
};

export default StripeButtonBase;

const Styled = createStyled((theme: Theme) =>
  createStyles({
    plainLink: {
      margin: 0,
      padding: 0,
      lineHeight: 0,
      textDecoration: 'none',
      color: '#0000d0',
      '&visited': {
        textDecoration: 'none',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
);
