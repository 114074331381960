import * as React from 'react';
import { Query } from 'react-apollo';
import FileList from 'users/components/FileList';
import { QueryRouteComponentProps } from 'shared/components/QueryRoute';
import ListViewLayout from 'shared/layout/ListViewLayout';
import { ListViewSearchArea } from 'shared/layout';
import {
  Button,
  Input,
  createStyles,
  Theme,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import Assignment from '@material-ui/icons/Assignment';
import SearchIcon from '@material-ui/icons/Search';
import { createStyled } from 'shared/hocs';
import * as create from 'shared/components/Table';
import { GlobalCircleLoader, ListPage } from 'shared/components';
import {
  USER_FILES_QUERY,
  UserFilesQueryVars,
  UserFilesQueryResult,
} from 'users/queries';

import { ListPageRenderProps, SortDirection } from 'shared/components/ListPage';
import { ApolloError } from 'apollo-boost';

interface QueryDefinition {
  search?: string;
  order: SortDirection;
  sort: string;
  perPage: number;
  page: number;
  deleted?: boolean;
  project?: string;
}

interface State {
  value: string;
  isShowingDeleted: boolean;
  projectId?: string;
  mutationError: ApolloError | null;
}

type Props = QueryRouteComponentProps<QueryDefinition> &
  ListPageRenderProps<{ search?: string; deleted?: boolean; project?: string }>;

class Inner extends React.Component<Props, State> {
  state: State = {
    value: '',
    isShowingDeleted: false,
    mutationError: null,
    projectId: '',
  };

  componentDidUpdate(pp: Props, ps: State) {
    const {
      search: prevSearch = '',
      deleted: prevDeleted = false,
    } = pp.query.params;
    const { search = '', deleted = false } = this.props.query.params;
    if (search !== prevSearch) {
      this.setState({ value: search });
    }
    if (deleted !== prevDeleted) {
      this.setState({ isShowingDeleted: deleted });
    }
  }

  componentDidMount() {
    const {
      search = '',
      deleted = false,
      project = '',
    } = this.props.searchValues;
    this.setState({
      value: search,
      isShowingDeleted: deleted,
      projectId: project,
    });
  }

  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      this.search();
    }
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    this.setState({ value, mutationError: null });
  };

  search = () => {
    const { value, isShowingDeleted, projectId } = this.state;
    const { updateSearch } = this.props;
    this.setState({ mutationError: null });
    if (value) {
      updateSearch({
        search: value,
        deleted: isShowingDeleted,
        project: projectId,
      });
    } else {
      updateSearch({
        search: undefined,
        deleted: isShowingDeleted,
        project: projectId,
      });
    }
  };

  render() {
    const { userId } = this.props.match.params;
    const { isShowingDeleted, mutationError, projectId } = this.state;

    const {
      paging,
      searchValues,
      changeRowsPerPage,
      handleChangePage,
    } = this.props;
    const { search: searchText } = searchValues;
    const { order, sortBy, page, perPage: limit } = paging;

    const headers = [
      create.header('Title', 'title'),
      create.header('ID'),
      create.header('uploadSource'),
      create.header('[Algorithm] Status'),
      create.header('Duration', 'duration'),
      create.header('Created', 'created'),
      create.header('Updated', 'updated'),
      create.header('Actions'),
    ];

    return (
      <Style>
        {({ classes }) => (
          <ListViewLayout>
            <ListViewSearchArea>
              <Input
                placeholder="Search for files by title…"
                value={this.state.value}
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
                disableUnderline
                className={classes.searchInput}
              />

              <Button
                onClick={this.search}
                variant="contained"
                color="primary"
                className={classes.searchButton}
              >
                <SearchIcon />
              </Button>

              <div className={classes.deletedContainer}>
                <Tooltip title="Control whether only deleted files are shown">
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.deleted}
                        color="default"
                        icon={<Assignment />}
                        checkedIcon={<Delete />}
                        checked={isShowingDeleted}
                        onChange={(event, checked) => {
                          this.setState(
                            {
                              isShowingDeleted: checked,
                              mutationError: null,
                            },
                            () => this.search(),
                          );
                        }}
                      />
                    }
                    label={
                      isShowingDeleted
                        ? 'Showing deleted files'
                        : 'Showing regular files'
                    }
                  />
                </Tooltip>
              </div>
            </ListViewSearchArea>

            {mutationError && (
              <Typography
                className={classes.error}
                component="h1"
                variant="body1"
              >
                {mutationError.message}
              </Typography>
            )}

            <Query<UserFilesQueryResult, UserFilesQueryVars>
              query={USER_FILES_QUERY}
              variables={{
                userId,
                page,
                limit,
                order,
                sortBy,
                searchText,
                projectId: projectId as string,
                deleted: isShowingDeleted,
              }}
              fetchPolicy="cache-and-network"
              notifyOnNetworkStatusChange
            >
              {({ data, error, loading, refetch, networkStatus }) => {
                const refetching = networkStatus === 4;
                if (error) return null;
                if (loading || (refetching && (!data || !data.files)))
                  return <GlobalCircleLoader show />;

                return (
                  <FileList
                    files={data!.files.list}
                    headers={headers}
                    isShowingDeleted={isShowingDeleted}
                    page={page}
                    perPage={limit}
                    hasMore={data!.files.hasMore}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={changeRowsPerPage}
                    onMutationCompleted={() => {
                      this.setState({ mutationError: null });
                      refetch();
                    }}
                    onMutationError={(e) => {
                      this.setState({ mutationError: e });
                    }}
                  />
                );
              }}
            </Query>
          </ListViewLayout>
        )}
      </Style>
    );
  }
}

interface SearchVars {
  search?: string;
  deleted?: boolean;
  project?: string;
}

const sortKeys = ['title', 'duration', 'created', 'updated'];
const FileListPage: React.SFC<Props> = (props) => {
  const searchKeys: Array<keyof SearchVars> = ['search', 'deleted', 'project'];
  return (
    <ListPage<SearchVars>
      sortKeys={sortKeys}
      searchKeys={searchKeys}
      defaultSortBy="created"
      initialQuery={{ deleted: false }}
    >
      {(query) => <Inner {...query} {...props} />}
    </ListPage>
  );
};

export default FileListPage;

const Style = createStyled((theme: Theme) =>
  createStyles({
    searchInput: {
      flex: 5,
      borderRadius: theme.shape.borderRadius,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.grey[500],
      padding: theme.spacing.unit,
      // maxWidth: 'none',
      // '& div': {
      //   maxWidth: 'none',
      // },
    },
    searchButton: {
      // flex: 1,
    },
    deletedContainer: {
      flex: 2,
      textAlign: 'right',
    },
    deleted: {
      marginRight: 0,
    },
    error: {
      color: 'red',
      border: '1px solid red',
      borderRadius: '5px',
      marginBottom: '20px',
    },
  }),
);
