import * as React from 'react';
import { DialogTitle, DialogContent, Button } from '@material-ui/core';
import { BaseModalProps } from 'contexts/ModalTrigger';

interface Props extends BaseModalProps {
  onNope: () => void;
  x: number;
  onConfirm: () => void;
}

const noop = () => {};

class AlertDialog extends React.Component<Props> {
  handleConfirm = (e: any) => {
    const { closeModal, onConfirm = noop } = this.props;
    onConfirm();
    closeModal(e);
  };

  render() {
    return (
      <>
        <DialogTitle id="form-dialog-title">
          Alert! you did a thing!
        </DialogTitle>
        <DialogContent>
          <Button onClick={this.handleConfirm}>Cool</Button>
        </DialogContent>
      </>
    );
  }
}

export default AlertDialog;
