import * as React from 'react';
import { RouteQueryProps } from 'shared/components/QueryRoute';
import QueryString from './QueryString';

export type PagingUpdate<T extends object> = Partial<QueryDefinition<T>>;
export type SortDirection = 'asc' | 'desc' | undefined;

export interface PagingDefinition {
  order: SortDirection;
  sortBy?: string;
  perPage: number;
  page: number;
}

export type QueryDefinition<T extends object = {}> = PagingDefinition & T;

export interface ListPageRenderProps<T extends object> {
  paging: PagingDefinition;
  updateSearch: (values: T) => void;
  clearSearch: () => void;
  changeRowsPerPage: (event: React.ChangeEvent<any>) => void;
  handleChangePage: (event: null, page: number) => void;
  searchValues: T;
}

interface Props<T extends object> {
  sortKeys: string[];
  defaultPerPage: number;
  defaultSortBy?: string;
  defaultOrder: SortDirection;
  initialQuery: PagingUpdate<T>;
  query: RouteQueryProps<QueryDefinition<T>>;
  children: (props: ListPageRenderProps<T>) => React.ReactNode;
}

function getValidSortBy(key: string | undefined, validSortKeys: string[]) {
  if (!key || validSortKeys.length === 0 || validSortKeys.includes(key)) {
    return key;
  }
  return undefined;
}

function isValidSortDirection(order: any): order is SortDirection {
  return typeof order === 'undefined' || order === 'asc' || order === 'desc';
}

function getValidSortDirection(order?: SortDirection): SortDirection {
  if (isValidSortDirection(order)) {
    return order;
  }
  return 'desc';
}

class Inner<Q extends object> extends React.Component<Props<Q>> {
  constructor(props: Props<Q>) {
    super(props);
    const { query } = props;
    const initialQuery: any = props.initialQuery;

    const {
      page = 0,
      order = props.defaultOrder,
      sortBy = props.defaultSortBy,
      perPage = props.defaultPerPage,
    } = query.params;

    query.update({
      page,
      perPage,
      order: getValidSortDirection(order),
      sortBy: getValidSortBy(sortBy, props.sortKeys),
      ...initialQuery,
    });
  }

  updateSearch = (values: Q) => {
    const { query } = this.props;
    query.update({ ...(values as any), page: 0 });
  };

  clearSearch = () => {
    const { query } = this.props;
    const { perPage } = this.props.query.params;
    const def = { perPage, page: 0 } as PagingUpdate<Q>;
    query.update(def, { replace: true });
  };

  handleChangePage = (event: null, page: number) => {
    const { query } = this.props;
    query.update({ page } as PagingUpdate<Q>);
  };

  changeRowsPerPage = (event: React.ChangeEvent<any>) => {
    const { value } = event.target;
    const { query } = this.props;
    query.update({ perPage: value } as PagingUpdate<Q>);
  };

  collectValues = (): PagingDefinition => {
    const { params } = this.props.query;
    const { defaultPerPage, defaultSortBy, defaultOrder } = this.props;
    const {
      page = 0,
      order = defaultOrder,
      sortBy = defaultSortBy,
      perPage = defaultPerPage,
    } = params;
    return { order, sortBy, page, perPage };
  };

  collectSearchValues = (): Q => {
    const { params } = this.props.query;
    const { order, sortBy, page, perPage, ...search } = params as any;
    return search;
  };

  collectProps = (): ListPageRenderProps<Q> => ({
    paging: this.collectValues(),
    searchValues: this.collectSearchValues(),
    updateSearch: this.updateSearch,
    clearSearch: this.clearSearch,
    changeRowsPerPage: this.changeRowsPerPage,
    handleChangePage: this.handleChangePage,
  });

  render() {
    const { children } = this.props;
    const props = this.collectProps();
    return children(props);
  }
}

interface ListPageProps<T extends object> {
  initialQuery?: Partial<QueryDefinition<T>>;
  searchKeys?: Array<keyof T>;
  sortKeys?: string[];
  defaultPerPage?: number;
  defaultSortBy?: string;
  defaultOrder?: SortDirection;
  children: (props: ListPageRenderProps<T>) => React.ReactNode;
}

const ALLOWED_KEYS: Array<keyof QueryDefinition> = [
  'perPage',
  'page',
  'order',
  'sortBy',
];

export const DEFAULT_PER_PAGE = 25;

export default class ListPage<T extends object> extends React.Component<
  ListPageProps<T>
> {
  render() {
    const {
      children,
      sortKeys = [],
      searchKeys = [],
      initialQuery = {},
      defaultSortBy,
      defaultOrder = 'desc',
      defaultPerPage = DEFAULT_PER_PAGE,
    } = this.props;
    return (
      <QueryString<QueryDefinition<T>>
        allowedKeys={[...searchKeys, ...ALLOWED_KEYS]}
      >
        {query => {
          return (
            <Inner<T>
              query={query}
              defaultSortBy={defaultSortBy}
              defaultOrder={defaultOrder}
              defaultPerPage={defaultPerPage}
              sortKeys={sortKeys}
              initialQuery={initialQuery}
            >
              {children}
            </Inner>
          );
        }}
      </QueryString>
    );
  }
}
