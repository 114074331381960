export interface TimeComponents {
  hours: number;
  minutes: number;
  seconds: number;
}

export const getMinuteComponent = (totalSeconds: number) => {
  const hours = getHourComponent(totalSeconds);
  const hoursAsSeconds = hours * 60 * 60;
  const minutesAsSeconds = totalSeconds - hoursAsSeconds;
  return Math.floor(minutesAsSeconds / 60);
};

export const getHourComponent = (totalSeconds: number) => {
  return Math.floor(totalSeconds / (60 * 60));
};

export const getSecondsComponent = (seconds: number) => {
  return seconds % 60;
};

export const getTimeComponents = (seconds: number): TimeComponents => ({
  hours: getHourComponent(seconds),
  minutes: getMinuteComponent(seconds),
  seconds: getSecondsComponent(seconds),
});

export const formatSeconds = (seconds: number): string => {
  const tc = getTimeComponents(seconds);
  return `${tc.hours}h ${tc.minutes}m ${tc.seconds}s`;
};
