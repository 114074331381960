import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './Routes';
import { ApolloProvider } from 'react-apollo';
import { UserProvider } from 'shared/components/UserContext';
import { apolloClient } from 'shared/api/apollo';
import ModalRoot from 'modals/ModalRoot';
import { ModalProvider } from 'contexts/ModalTrigger';
import * as themes from 'shared/themes';
import { ThemeSetter } from 'shared/components';
import {
  UnicornTenantProvider,
  UnicornTenantConsumer,
} from 'shared/components/UnicornTenantContext';
import './App.css';

class App extends React.Component<{}, {}> {
  render() {
    return (
      <UnicornTenantProvider>
        <UnicornTenantConsumer>
          {value => {
            return (
              <ApolloProvider client={apolloClient(value!.activeEndpoint)}>
                <Router>
                  <ThemeSetter themes={themes}>
                    <ModalProvider>
                      <ModalRoot />
                      <UserProvider>
                        <AppRoutes />
                      </UserProvider>
                    </ModalProvider>
                  </ThemeSetter>
                </Router>
              </ApolloProvider>
            );
          }}
        </UnicornTenantConsumer>
      </UnicornTenantProvider>
    );
  }
}

export default App;
