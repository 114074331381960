import * as React from 'react';
import { Query } from 'react-apollo';
import { createStyled } from 'shared/hocs';
import {
  Theme,
  Grid,
  Paper,
  Typography,
  createStyles,
} from '@material-ui/core';
import { USER_BILLING_QUERY, UserBillingQueryResult } from 'users/queries';
import CancelUserSubscription from './CancelUserSubscription';
import DeleteUser from './deleteUser/DeleteUser';
import { BillingPurchaseSource } from 'types';

interface Props {
  userId: string;
}

interface UserActionsProps {
  userId: string;
  isMobileSubscription: boolean;
  purchaseSource?: BillingPurchaseSource;
  cancellationDate: number | null;
  subscriptionStatus: string;
  isTrialist: boolean;
  isActiveSubscriberAndOwner: boolean;
}

class UserActions extends React.Component<Props> {
  renderForm(data: UserActionsProps) {
    const {
      userId,
      isMobileSubscription,
      purchaseSource,
      cancellationDate,
      subscriptionStatus,
      isTrialist,
      isActiveSubscriberAndOwner,
    } = data;

    return (
      <Styled>
        {({ classes }) => (
          <main data-testid="main" className={classes.layout}>
            <Paper className={classes.paper}>
              <Typography className={classes.title} component="h1" variant="h5">
                User Actions
              </Typography>
              <Grid container spacing={24}>
                <Grid item xs={12}>
                  <CancelUserSubscription
                    userId={userId}
                    isMobileSubscription={isMobileSubscription}
                    purchaseSource={purchaseSource}
                    cancellationDate={cancellationDate}
                    subscriptionStatus={subscriptionStatus}
                    isTrialist={isTrialist}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DeleteUser
                    userId={userId}
                    isActiveSubscriberAndOwner={isActiveSubscriberAndOwner}
                  />
                </Grid>
              </Grid>
            </Paper>
          </main>
        )}
      </Styled>
    );
  }
  render() {
    const { userId } = this.props;

    return (
      <Query<UserBillingQueryResult, { userId: string }>
        query={USER_BILLING_QUERY}
        fetchPolicy="cache-and-network"
        variables={{ userId }}
      >
        {({ data, loading, error }) => {
          if (error) {
            return (
              <div id="query-error-message" data-testid-="query-error-message">
                {error.message}
              </div>
            );
          }
          if (loading && (!data || !data.user)) return null;

          const { user } = data!;
          const isPartOfTeam = user.group !== null;
          const { trial } = user.currentSubscription;
          const isTrialist = !isPartOfTeam && trial;
          const { purchaseSource } = user.billing;
          const isMobileSubscription = purchaseSource !== 'STRIPE';
          const { subscriptionStatus, cancellationDate } = user.billing.v2;
          const isActiveSubscriberAndOwner =
            subscriptionStatus === 'active' && userId === user.group.ownerId;

          return this.renderForm({
            userId,
            isMobileSubscription,
            purchaseSource,
            cancellationDate,
            subscriptionStatus,
            isTrialist,
            isActiveSubscriberAndOwner,
          });
        }}
      </Query>
    );
  }
}

const Styled = createStyled((theme: Theme) => {
  return createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    title: {
      marginBottom: theme.spacing.unit * 3,
    },
  });
});

export default UserActions;
