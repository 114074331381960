import React from 'react';
import { Mutation, MutationFunction } from 'react-apollo';
import { createFormContext } from 'yafl';
import { CREATE_USER_MUTATION } from '../mutations';
import FormTextInput from 'shared/formKit/FormTextInput';
import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { FormKit, FormSelectInput } from 'shared/formKit';
import { ModalTrigger } from 'contexts/ModalTrigger';
import CustomToast from 'shared/components/CustomToast';
import { email } from 'shared/form/validators';
import { minLength } from '../../shared/form/validators';

interface FormData {
  username: string;
  firstName: string;
  lastName: string;
  currency: string;
}

const CURRENCIES = ['usd', 'gbp', 'eur'];
const CURRENCY_LIST = CURRENCIES.map(c => ({
  value: c,
  label: c.toUpperCase(),
}));

const context = createFormContext<FormData>();
const { Field } = context;

function UserCreateModal(props: any) {
  const { onClose, isOpen, onCreate } = props;

  const dto = {
    username: '',
    firstName: '',
    lastName: '',
    currency: 'usd',
  };

  function handleSubmit(
    formData: FormData,
    createUser: MutationFunction<any, { input: FormData }>,
  ) {
    createUser({
      variables: {
        input: {
          ...formData,
        },
      },
    }).catch();
  }

  function onCompletion() {
    onCreate();
    onClose();
  }

  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth>
      <Mutation onCompleted={onCompletion} mutation={CREATE_USER_MUTATION}>
        {(mutation, { error: mutationError }) => {
          return (
            <>
              <FormKit<FormData>
                context={context}
                initialValue={dto}
                defaultValue={dto}
              >
                {({ formValue, formIsValid, setFormValue }) => {
                  return (
                    <>
                      <DialogTitle id="form-dialog-title">
                        Create a user
                      </DialogTitle>
                      <DialogContent>
                        <Grid container spacing={24}>
                          <Grid item xs={6} sm={12}>
                            <Field
                              fullWidth
                              name="username"
                              label="User email"
                              component={FormTextInput}
                              validate={[
                                email('A valid email address is required'),
                              ]}
                            />
                          </Grid>
                          <Grid item xs={6} sm={12}>
                            <Field
                              fullWidth
                              name="firstName"
                              label="First name"
                              component={FormTextInput}
                              validate={[minLength(2)]}
                            />
                          </Grid>
                          <Grid item xs={6} sm={12}>
                            <Field
                              fullWidth
                              name="lastName"
                              label="Last name"
                              component={FormTextInput}
                              validate={[minLength(2)]}
                            />
                          </Grid>
                          <Grid item xs={6} sm={12}>
                            <Field
                              name="currency"
                              label="Currency"
                              options={CURRENCY_LIST}
                              component={FormSelectInput}
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>

                      <DialogActions>
                        <Button onClick={onClose} color="primary">
                          Cancel
                        </Button>
                        <ModalTrigger<'ConfirmDialog'>
                          modal="ConfirmDialog"
                          modalProps={{
                            onConfirm: () => handleSubmit(formValue, mutation),
                            title: (
                              <span>
                                Are you sure you want to create this user?
                                <br />
                                The user will receive an email to reset their
                                password.
                              </span>
                            ),
                          }}
                        >
                          {({ openModal }) => (
                            <Button
                              disabled={!formIsValid}
                              onClick={openModal}
                              color="primary"
                              variant="contained"
                            >
                              Done
                            </Button>
                          )}
                        </ModalTrigger>
                      </DialogActions>
                    </>
                  );
                }}
              </FormKit>
              {mutationError && (
                <CustomToast message={mutationError.message} isOpen />
              )}
            </>
          );
        }}
      </Mutation>
    </Dialog>
  );
}

export { UserCreateModal };
