import ApolloClient from 'apollo-boost';
import * as auth from 'shared/auth';

export function apolloClient(endpointUri: string) {
  return new ApolloClient({
    uri: endpointUri,
    request: async (operation) => {
      const authData = auth.getAuthData();
      const token = authData ? authData.accessToken : null;
      operation.setContext({
        headers: {
          Authorization: token ? `Bearer ${token}` : null,
        },
      });
    },
  });
}
