import * as React from 'react';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Duration, DateTime } from 'luxon';
import LuxonUtils from '@date-io/luxon';
import {
  Grid,
  Paper,
  Theme,
  Typography,
  createStyles,
  Button,
} from '@material-ui/core';
import { Query } from 'react-apollo';
import { createStyled } from 'shared/hocs';
import {
  ORGANISATION_USAGE_QUERY,
  OrganisationUsageQueryResult,
} from 'organisations/queries';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';

interface Props {
  groupId: string;
}

interface State {
  start: DateTime;
  end: DateTime;
}

interface OrganisationUsageQueryRes {
  group: OrganisationUsageQueryResult | null | undefined;
}

interface OrganisationUsageQueryVars {
  organisationId: string;
  start: number;
  end: number;
}
const GET_USAGE_CSV = gql`
  query UsageCsv($groupId: String!, $dateFrom: String!, $dateTo: String!) {
    usageCsv(groupId: $groupId, dateFrom: $dateFrom, dateTo: $dateTo)
  }
`;
interface CsvDownloadProps {
  groupId: string;
  dateFrom: DateTime;
  dateTo: DateTime;
}

function CsvDownload({ groupId, dateFrom, dateTo }: CsvDownloadProps) {
  const client = useApolloClient();

  function fetchUsageCsv() {
    client
      .query<{ usageCsv: string }, CsvDownloadProps>({
        query: GET_USAGE_CSV,
        variables: { groupId, dateFrom, dateTo },
      })
      .then((result) => {
        window.location.assign(result.data.usageCsv);
      });
  }

  return (
    <Button
      type="button"
      variant="contained"
      color="primary"
      onClick={fetchUsageCsv}
    >
      Download CSV
    </Button>
  );
}
class OrganisationUsage extends React.Component<Props, State> {
  state = {
    start: DateTime.fromJSDate(new Date()).startOf('month'),
    end: DateTime.fromJSDate(new Date()).endOf('month'),
  };

  formatMinutes(usage: number) {
    const { seconds, minutes, hours } = Duration.fromObject({
      seconds: usage,
      minutes: 0,
      hours: 0,
    }).normalize();

    return `${hours} hours, ${minutes} minutes, ${seconds} seconds`;
  }

  updateStartDate = (start: DateTime) => {
    this.setState({ start });
  };

  updateEndDate = (end: DateTime) => {
    this.setState({ end });
  };

  render() {
    const { groupId: organisationId } = this.props;
    const { start, end } = this.state;

    return (
      <Query<OrganisationUsageQueryRes, OrganisationUsageQueryVars>
        query={ORGANISATION_USAGE_QUERY}
        fetchPolicy="cache-and-network"
        variables={{
          organisationId,
          start: start.startOf('day').toMillis(),
          end: end.endOf('day').toMillis(),
        }}
      >
        {({ data, loading, error }) => {
          if (error) return null;
          if (loading && (!data || !data.group)) return null;
          const { group } = data!;

          return (
            <Styled>
              {({ classes }) => (
                <main className={classes.layout}>
                  <Paper className={classes.paper}>
                    <Typography
                      className={classes.title}
                      component="h1"
                      variant="h5"
                    >
                      Organisation Usage for {group!.metadata.name}
                    </Typography>
                    <Grid container spacing={24}>
                      <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <Grid item xs={12} sm={6}>
                          <InlineDatePicker
                            keyboard
                            variant="outlined"
                            label="Start Date"
                            format="yyyy-MM-dd"
                            value={start}
                            onChange={this.updateStartDate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InlineDatePicker
                            keyboard
                            variant="outlined"
                            label="End Date"
                            format="yyyy-MM-dd"
                            value={end}
                            onChange={this.updateEndDate}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Usage</Typography>
                        <Typography variant="body1">
                          {this.formatMinutes(group!.usage.capping.total)}
                        </Typography>
                        {group && (
                          <CsvDownload
                            groupId={group._id}
                            dateFrom={start}
                            dateTo={end}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </main>
              )}
            </Styled>
          );
        }}
      </Query>
    );
  }
}

const Styled = createStyled((theme: Theme) => {
  return createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    title: {
      marginBottom: theme.spacing.unit * 3,
    },
  });
});

export default OrganisationUsage;
