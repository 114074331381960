import * as React from 'react';
import { createStyled } from 'shared/hocs';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import {
  Theme,
  Grid,
  Paper,
  Typography,
  createStyles,
  Button as Btn,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { USER_ALLOWANCE_QUERY, UserAllowanceQueryResult } from 'users/queries';
import { TextField } from 'shared/components';
import { createFormContext } from 'yafl';
import { required, min } from 'shared/form/validators';
import Timelapse from '@material-ui/icons/Timelapse';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import { formatSeconds } from 'shared/utils/time';
import { FormKit, FormTextInput } from 'shared/formKit';
import { ModalTrigger } from 'contexts/ModalTrigger';
import { Allowance } from 'types';
import EventIcon from '@material-ui/icons/Event';
import { formatDate } from 'shared/utils';

const context = createFormContext<Allowance>();
const { Section, Field } = context;

interface Props {
  userId: string;
}

interface Data {
  user: UserAllowanceQueryResult;
}

// https://github.com/mui-org/material-ui/issues/11968
const Button: React.ComponentType<any> = Btn;

class Form extends React.Component<Props> {
  handleSubmit = (value: Allowance) => {};

  render() {
    const { userId } = this.props;
    return (
      <Query<Data, { userId: string }>
        query={USER_ALLOWANCE_QUERY}
        fetchPolicy="cache-and-network"
        variables={{ userId }}
      >
        {({ error, data, loading }) => {
          if (error) return null;
          if (loading || !data || !data.user) return null;
          const { allowance, billing, _id, username } = data.user;

          return (
            <FormKit<Allowance>
              disabled
              context={context}
              loading={loading}
              initialValue={allowance}
              onSubmit={this.handleSubmit}
              components={{ input: FormTextInput }}
            >
              <Styled>
                {({ classes }) => (
                  <main className={classes.layout}>
                    <Paper className={classes.paper}>
                      <Typography
                        className={classes.title}
                        component="h1"
                        variant="h5"
                      >
                        Allowance
                      </Typography>
                      <Grid container spacing={24}>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="start"
                            label="Start"
                            component="input"
                            value={formatDate(allowance.start)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <AdjustEndDateModalTrigger
                            end={allowance.end}
                            userId={_id}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Field
                            name="dailyUsage"
                            label="Daily Usage"
                            type="number"
                            component="input"
                            validate={[
                              required('This field is required'),
                              min(30),
                            ]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <AllocateMinutesModalTrigger
                            seconds={allowance.balance}
                            userId={_id}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="subscriptionDay"
                            label="Subscription Day"
                            type="number"
                            component="input"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="usage"
                            label="Usage"
                            type="number"
                            component="input"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="planCode"
                            label="Plan Code"
                            component="input"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FrozenTranscriptionModalTrigger
                            subscriptionFrozen={billing.subscriptionFrozen}
                            userId={_id}
                            username={username}
                          />
                        </Grid>
                        <Section name="credit">
                          <Grid item xs={12}>
                            <h3>Credit Breakdown</h3>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field
                              name="bonus"
                              label="Bonus"
                              type="number"
                              component="input"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field
                              name="planAllowance"
                              label="Plan Allowance"
                              component="input"
                              type="number"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}></Grid>
                        </Section>
                      </Grid>
                      <div className={classes.buttons}>
                        <Button
                          component={Link}
                          to="/users"
                          className={classes.button}
                        >
                          Back
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          Save
                        </Button>
                      </div>
                    </Paper>
                  </main>
                )}
              </Styled>
            </FormKit>
          );
        }}
      </Query>
    );
  }
}

interface AdjustEndDateTriggerProps {
  end: number;
  userId: string;
}

const AdjustEndDateModalTrigger: React.SFC<AdjustEndDateTriggerProps> = ({
  end,
  userId,
}) => {
  return (
    <ModalTrigger modal="AdjustEndDateModal" modalProps={{ userId, end }}>
      {({ openModal }) => {
        return (
          <TextField
            disabled
            value={formatDate(end)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e: React.MouseEvent<any>) => {
                      openModal(e);
                      e.stopPropagation();
                    }}
                  >
                    <EventIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onClick={openModal}
            name="end"
            label="End"
          />
        );
      }}
    </ModalTrigger>
  );
};

interface BalanceTriggerProps {
  seconds: number;
  userId: string;
}

const AllocateMinutesModalTrigger: React.SFC<BalanceTriggerProps> = ({
  seconds,
  userId,
}) => {
  return (
    <ModalTrigger modal="AllocateMinutesModal" modalProps={{ userId }}>
      {({ openModal }) => {
        return (
          <TextField
            disabled
            value={formatSeconds(seconds)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e: React.MouseEvent<any>) => {
                      openModal(e);
                      e.stopPropagation();
                    }}
                  >
                    <Timelapse />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onClick={openModal}
            name="balance"
            label="Balance"
          />
        );
      }}
    </ModalTrigger>
  );
};

interface FrozenTriggerProps {
  subscriptionFrozen?: string;
  userId: string;
  username: string;
}

const FrozenTranscriptionModalTrigger: React.FunctionComponent<FrozenTriggerProps> = ({
  subscriptionFrozen,
  userId,
  username,
}) => {
  return (
    <ModalTrigger
      modal="FrozenTranscriptionModal"
      modalProps={{ subscriptionFrozen, userId, username }}
    >
      {({ openModal }) => {
        return (
          <TextField
            disabled
            value={subscriptionFrozen ? 'Yes' : 'No'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e: React.MouseEvent<any>) => {
                      openModal(e);
                      e.stopPropagation();
                    }}
                  >
                    <AcUnitIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onClick={openModal}
            name="frozen"
            label="Transcription Frozen"
          />
        );
      }}
    </ModalTrigger>
  );
};

const Styled = createStyled((theme: Theme) => {
  return createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing.unit * 3,
      marginLeft: theme.spacing.unit,
    },
    title: {
      marginBottom: theme.spacing.unit * 3,
    },
  });
});

export default Form;
