import gql from 'graphql-tag';

const Plans = {
  fragments: {
    plansBase: gql`
      fragment PlansBase on PlanDetails {
        planId
        title
        currentPlan
        planType
        planCode
      }
    `,
  },
};

export default Plans;
