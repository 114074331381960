import React, { useState } from 'react';
import gql from 'graphql-tag';

import {
  Button,
  Dialog,
  Stepper,
  Typography,
  DialogTitle,
  DialogContent,
  Step,
  StepLabel,
  DialogActions,
  StepContent,
  TextField,
  Grid,
} from '@material-ui/core';
import { useQuery, useMutation } from 'react-apollo';

interface Props {
  onClose: () => void;
  userId: string;
  handleComplete: () => void;
  handleError: () => void;
}

export const USER_TO_DELETE_QUERY = gql`
  query userToDeleteQuery($userId: String) {
    user(userId: $userId) {
      _id
      username
    }
  }
`;

export const SECURE_DELETE_USER = gql`
  mutation secureDeleteUser($userId: String!) {
    gdprDeleteUser(userId: $userId) {
      _id
    }
  }
`;
interface QueryRes {
  user: {
    _id: string;
    username: string;
  };
}

interface QueryVars {
  userId: string;
}

interface MutationRes {
  gdprDeleteUser: {
    _id: string;
  };
}

type MutationVars = QueryVars;

function DeleteUserDialog(props: Props) {
  const { onClose, userId, handleComplete, handleError } = props;
  const [activeStep, setActiveStep] = useState(0);
  const { data, loading, error } = useQuery<QueryRes, QueryVars>(
    USER_TO_DELETE_QUERY,
    {
      variables: { userId },
    },
  );

  const [deleteUser, { loading: loadingMutation }] = useMutation<
    MutationRes,
    MutationVars
  >(SECURE_DELETE_USER, {
    variables: { userId },
    onCompleted: () => {
      handleComplete();
      onClose();
    },
    onError: () => {
      handleError();
    },
  });

  const username = data ? data.user.username : null;
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };
  const [userInputEmail, setUserInputEmail] = useState('');
  if (error) {
    return <div>{error.message}</div>;
  }

  const formIsComplete = activeStep === 2;

  return (
    <>
      <Dialog open onClose={onClose}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step>
              <StepLabel>Are you sure you want to delete this user?</StepLabel>
              <StepContent>
                <Typography>
                  You're about to delete this user. Once deleted this user
                  cannot be recovered and will no longer be able to log in. If
                  you would like to continue click 'next' to proceed.
                </Typography>
                <div>
                  <Button
                    data-testid="step-one-back"
                    disabled={true}
                    onClick={handlePrevious}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    data-testid="step-one-next"
                  >
                    Next
                  </Button>
                </div>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Please confirm the users email address:</StepLabel>
              <StepContent>
                {data && <Typography>{data!.user.username} </Typography>}
                <TextField
                  label="Enter E-mail Address"
                  value={userInputEmail}
                  id="step-two-input"
                  data-testid="step-two-input"
                  onChange={e => {
                    setUserInputEmail(e.target.value);
                  }}
                  onPaste={e => {
                    e.preventDefault();
                  }}
                />
                <div>
                  <Button onClick={handlePrevious} data-testid="step-two-back">
                    Back
                  </Button>

                  <Button
                    variant="contained"
                    disabled={username !== userInputEmail}
                    color="primary"
                    onClick={handleNext}
                    data-testid="step-two-next"
                  >
                    Finish
                  </Button>
                </div>
              </StepContent>
            </Step>
          </Stepper>
          {activeStep === 2 && (
            <>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    All steps completed
                  </Typography>
                  <Typography variant="body2">
                    {`You are about to delete user ${username}`}
                  </Typography>

                  <Typography variant="body2">
                    Please click delete to proceed.
                  </Typography>
                </Grid>

                <Grid item xs={2} />
                <Grid item xs={2} />
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={loading || loadingMutation || !formIsComplete}
            onClick={() => deleteUser()}
            data-testid="delete-button"
          >
            Delete{' '}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            data-testid="cancel-button"
            disabled={loading}
            onClick={onClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeleteUserDialog;
