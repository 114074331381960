import * as React from 'react';
import { AuthContextState } from 'shared/components/UserContext';
import { Switch, Route as ReactRoute } from 'react-router-dom';
import { Drawer, Route } from 'shared/components';
import { withUserContext } from 'shared/hocs';
import Callback from './Callback';
import menuItems from 'pages/drawerLinks';
import config from './config';
import Home from 'pages/Home';
import UserDetailsPage from 'pages/UserDetailsPage';
import UserListPage from 'pages/UserListPage';
import PlansListPage from 'pages/PlansListPage';
import OrganisationListPage from 'pages/OrganisationListPage';
import EnterpriseListPage from 'pages/EnterpriseListPage';
import OrganisationDetailsPage from 'pages/OrganisationDetailsPage';
import IntegrationsPage from 'pages/IntegrationsPage';
import SalesforcePage from 'integrations/SalesforcePage';
import SalesforceAuthPage from 'integrations/SalesforceAuthPage';
import ToolsPage from 'pages/ToolsPage';
import NotificationPage from 'pages/NotificationsPage';
import RealtimeGlobalListPage from 'pages/RealtimeGlobalListPage';
import EnterprisePage from 'pages/EnterprisePage';
import IncidentsPage from 'pages/IncidentsPage';
import VouchersPage from 'pages/VouchersPage';
import VouchersBatchDetailsPage from 'pages/VouchersBatchDetailsPage';
import Ec2LauncherPage from 'pages/Ec2LauncherPage';

const redirect = `/${config.AUTH_REDIRECT_URL}`;

class AppRoutes extends React.Component<{ auth: AuthContextState }> {
  render() {
    const { auth } = this.props;
    const links = !auth.isAuthorised
      ? menuItems.filter((item) => item.allowAnonymous)
      : menuItems;
    return (
      <Drawer menuItems={links} auth={auth}>
        <Switch>
          <ReactRoute exact path={redirect} component={Callback} />
          <Route path="/users/:userId" component={UserDetailsPage} />
          <Route path="/users" exact component={UserListPage} />
          <Route path="/plans" exact component={PlansListPage} />
          <Route path="/realtime" exact component={RealtimeGlobalListPage} />
          <Route path="/organisations" exact component={OrganisationListPage} />
          <Route path="/enterprises" exact component={EnterpriseListPage} />
          <Route path="/enterprises/:userId" component={EnterprisePage} />
          <Route
            path="/organisations/:groupId"
            component={OrganisationDetailsPage}
          />
          <Route path="/integrations" exact component={IntegrationsPage} />
          <Route
            path="/integrations/salesforce"
            exact
            component={SalesforcePage}
          />
          <Route
            path="/integrations/salesforce/auth"
            exact
            component={SalesforceAuthPage}
          />
          <Route path="/tools" exact component={ToolsPage} />
          <Route
            path="/vouchers/batches/:batchId"
            component={VouchersBatchDetailsPage}
          />
          <Route path="/vouchers" component={VouchersPage} />
          <Route path="/incidents" exact component={IncidentsPage} />
          <Route path="/notifications" exact component={NotificationPage} />
          <Route path="/ec2launcher" exact component={Ec2LauncherPage} />
          <Route exact allowAnonymous path="/" component={Home} />
        </Switch>
      </Drawer>
    );
  }
}

export default withUserContext(AppRoutes);
