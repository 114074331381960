import gql from 'graphql-tag';

const Team = {
  fragments: {
    teamBase: gql`
      fragment TeamBase on Team {
        _id
        name
        description
      }
    `,
  },
};

export default Team;
