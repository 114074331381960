import React, { useState } from 'react';
import { ApolloConsumer } from 'react-apollo';
import gql from 'graphql-tag';
import { createFormContext } from 'yafl';

import { createStyled } from 'shared/hocs';
import {
  FormControl,
  Grid,
  Paper,
  Theme,
  Typography,
  createStyles,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { ErrorDisplay } from 'shared/components';
import { BillingLog } from 'types';
import { FormTextInput, FormKit } from 'shared/formKit';
import { getLastMonthDates, dateToMillis, isUserId } from 'shared/utils';
import { getBonusesCsv } from 'shared/csv';

const getUserIdFilter = (value: string): string | undefined => {
  if (!value || !isUserId(value)) return undefined;
  return value;
};

const BONUESES_QUERY = gql`
  query getBillingLogs(
    $userIdFilter: String
    $type: String
    $start: Float
    $end: Float
  ) {
    getBillingLogs(
      userIdFilter: $userIdFilter
      type: $type
      start: $start
      end: $end
    ) {
      user
      group
      type
      created
      currency
      reason
      from {
        planCode
        usage
        credit {
          planAllowance
          bonus
        }
        balance
        start
        end
      }
      to {
        planCode
        usage
        credit {
          planAllowance
          bonus
        }
        balance
        start
        end
      }
    }
  }
`;

interface FormData {
  startDate: string;
  endDate: string;
  userIdFilter: string;
}

const context = createFormContext<FormData>();

const { Field } = context;

const { startDate, endDate } = getLastMonthDates();
const initialValue: FormData = { startDate, endDate, userIdFilter: '' };
const defaultValue: FormData = initialValue;

function ToolsPage() {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  return (
    <FormKit<FormData>
      context={context}
      initialValue={initialValue}
      defaultValue={defaultValue}
    >
      {({ formValue, formIsValid, submit }) => {
        return (
          <Styled>
            {({ classes }) => (
              <main className={classes.layout}>
                <Paper className={classes.paper}>
                  <Typography
                    className={classes.title}
                    component="h1"
                    variant="h5"
                    align="center"
                  >
                    Tools
                  </Typography>
                  <Typography
                    className={classes.subtitle}
                    variant="subtitle2"
                    align="center"
                  >
                    Trint's Tools & CSV Generators
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Use a shreadsheet to view the CSV data.
                  </Typography>

                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        fullWidth
                        name="startDate"
                        label="Start Date"
                        type="date"
                        component={FormTextInput}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        fullWidth
                        name="endDate"
                        label="End Date"
                        type="date"
                        component={FormTextInput}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field
                        fullWidth
                        name="userIdFilter"
                        label="Filter by User ID (optional)"
                        component={FormTextInput}
                        validate={(value: string) =>
                          value && !isUserId(value)
                            ? 'Enter a valid User ID or leave empty'
                            : undefined
                        }
                      />
                    </Grid>
                  </Grid>
                  {loading && <CircularProgress className={classes.loading} />}
                  {error && <ErrorDisplay>{error}</ErrorDisplay>}
                  {!loading && (
                    <ApolloConsumer>
                      {(client) => (
                        <Grid item xs={12} sm={12}>
                          <FormControl id="bonusesCsv">
                            <Button
                              className={classes.button}
                              variant="contained"
                              color="primary"
                              disabled={!formIsValid}
                              onClick={async () => {
                                setLoading(true);
                                setError('');
                                try {
                                  const { data } = await client.query({
                                    query: BONUESES_QUERY,
                                    variables: {
                                      start: dateToMillis(formValue.startDate),
                                      end: dateToMillis(formValue.endDate, {
                                        endOfDay: true,
                                      }),
                                      userIdFilter: getUserIdFilter(
                                        formValue.userIdFilter,
                                      ),
                                      type: 'allocateBonus',
                                    },
                                  });
                                  const {
                                    getBillingLogs: logs = [],
                                  }: {
                                    getBillingLogs: BillingLog[];
                                  } = data!;
                                  if (logs.length === 0) {
                                    throw new Error('No results.');
                                  }

                                  const filenameBase = `bonuses-${formValue.startDate}-to-${formValue.endDate}`;
                                  const filenameUser = getUserIdFilter(
                                    formValue.userIdFilter,
                                  )
                                    ? `-user-${formValue.userIdFilter}`
                                    : '';
                                  const filename = `${filenameBase}${filenameUser}.csv`;
                                  const blob = new Blob([getBonusesCsv(logs)], {
                                    type: 'text/plain;charset=utf-8',
                                  });

                                  saveAs(blob, filename);
                                } catch (error) {
                                  setError((error as Error).message);
                                }
                                setLoading(false);
                              }}
                            >
                              CSV - Bonuses
                            </Button>
                          </FormControl>
                        </Grid>
                      )}
                    </ApolloConsumer>
                  )}
                  <Typography className={classes.footer} variant="caption">
                    If you need a specific tool, ask the development team.
                  </Typography>
                </Paper>
              </main>
            )}
          </Styled>
        );
      }}
    </FormKit>
  );
}

const Styled = createStyled((theme: Theme) => {
  return createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    title: {
      marginBottom: theme.spacing.unit,
    },
    subtitle: {
      marginBottom: theme.spacing.unit * 3,
    },
    footer: {
      marginTop: theme.spacing.unit * 6,
    },
    loading: {
      marginTop: 30,
    },
    button: {
      marginTop: 38,
    },
  });
});

export default ToolsPage;
