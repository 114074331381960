import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { Typography, Grid } from '@material-ui/core';

interface Props {
  userId: string;
}

const USER_QUERY = gql`
  query user($userId: String) {
    user(userId: $userId) {
      group {
        _id
        metadata {
          name
        }
      }
    }
  }
`;

function OrganisationLabelFromUser(props: Props) {
  const { userId } = props;

  const { data, loading, error } = useQuery(USER_QUERY, {
    variables: {
      userId,
    },
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error</div>;
  if (!data) return <div>No Data</div>;

  const { group } = data.user;
  const { name } = group.metadata;
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '250px',
          }}
          title={name}
        >
          {name}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>Group Id: {group._id}</Typography>
      </Grid>
    </Grid>
  );
}

export default OrganisationLabelFromUser;
