import React from 'react';
import { createStyled } from 'shared/hocs';
import { Query } from 'react-apollo';
import {
  Theme,
  Grid,
  Paper,
  TextField,
  Typography,
  createStyles,
  Button as Btn,
  FormControl,
  FormLabel,
} from '@material-ui/core';
import { USER_GROUP_QUERY, UserGroupQueryResult } from 'users/queries';
import { TEAM_QUERY, TeamQueryResult } from 'organisations/queries';
import { Link } from 'react-router-dom';

interface Props {
  userId: string;
}

interface TeamQueryRes {
  getTeam?: TeamQueryResult | null;
}

interface TeamQueryVars {
  teamId: string;
}
interface UserOrganisationQueryRes {
  user: UserGroupQueryResult | null | undefined;
}

interface UserOrganisationQueryVars {
  userId: string;
}

// https://github.com/mui-org/material-ui/issues/11968
const Button: React.ComponentType<any> = Btn;
interface UserFormProps {
  userId: string;
  group: {
    _id: string;
    metadata?: {
      name: string;
    };
  };
  team?: {
    name: string;
  } | null;
}

class UserGroupForm extends React.Component<Props> {
  renderForm(data: UserFormProps) {
    const { userId, group, team } = data;
    return (
      <Styled>
        {({ classes }) => (
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <Typography className={classes.title} component="h1" variant="h5">
                User Organisation Details
              </Typography>

              <Grid container spacing={24}>
                <Grid item xs={12} sm={6}>
                  <FormControl id="orgLink">
                    <FormLabel>Organisation</FormLabel>
                    <Link
                      className={classes.formLink}
                      to={`/organisations/${group._id}/details`}
                    >
                      {(group && group.metadata && group.metadata.name) || ''}
                    </Link>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="orgId"
                    name="orgId"
                    data-testid="organisation-id"
                    label="Organisation Id"
                    value={group._id}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    data-testid="current-team-name"
                    id="currentTeam"
                    name="currentTeam"
                    label="Current Team"
                    value={(team && team.name) || ''}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <div className={classes.buttons}>
                <Button
                  component={Link}
                  data-testid="back-to-user-details"
                  to={`/users/${userId}/details`}
                  className={classes.button}
                >
                  Back
                </Button>
              </div>
            </Paper>
          </main>
        )}
      </Styled>
    );
  }

  render() {
    const { userId } = this.props;

    return (
      <Query<UserOrganisationQueryRes, UserOrganisationQueryVars>
        query={USER_GROUP_QUERY}
        fetchPolicy="cache-and-network"
        variables={{ userId }}
      >
        {({ error, loading, data }) => {
          if (error) return null;
          if (loading) return null;

          const { user } = data!;
          const group = (user && user.group) || {
            _id: '',
            metadata: { name: '' },
          };

          return (
            <Query<TeamQueryRes, TeamQueryVars>
              query={TEAM_QUERY}
              fetchPolicy="cache-and-network"
              variables={{ teamId: (user && user.currentTeam) || '' }}
            >
              {res => {
                if (res.error || res.loading) {
                  return null;
                }
                return this.renderForm({
                  userId,
                  group,
                  team: res.data!.getTeam,
                });
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

const Styled = createStyled((theme: Theme) => {
  return createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing.unit * 3,
      marginLeft: theme.spacing.unit,
    },
    title: {
      marginBottom: theme.spacing.unit * 3,
    },
    formLink: {
      fontSize: 16,
      textDecoration: 'none',
      color: '#0000d0',
      '&visited': {
        textDecoration: 'none',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  });
});

export default UserGroupForm;
