import * as React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { DateTime } from 'luxon';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const FREEZE_TRANSCRIPTION_MUTATION = gql`
  mutation freezeSubscription($userId: String!, $freeze: Boolean!) {
    freezeSubscription(userId: $userId, freeze: $freeze) {
      _id
      billing {
        subscriptionFrozen
      }
    }
  }
`;

interface freezeSubscriptionResult {
  _id: string;
  billing: {
    subscriptionFrozen: boolean;
  };
}

interface freezeSubscriptionVars {
  userId: string;
  freeze: boolean;
}

interface Props {
  openModal: (e: any) => void;
  closeModal: (e: any) => void;
  username: string;
  userId: string;
  subscriptionFrozen?: string;
}

const FrozenTranscriptionModal: React.FunctionComponent<Props> = ({
  closeModal,
  username,
  userId,
  subscriptionFrozen,
}) => {
  const [freezeSubscriptionMutation, { loading, error }] = useMutation<
    freezeSubscriptionResult,
    freezeSubscriptionVars
  >(FREEZE_TRANSCRIPTION_MUTATION);

  const update = async (freeze: boolean) => {
    await freezeSubscriptionMutation({ variables: { userId, freeze } });
    closeModal(null);
  };

  if (loading || error)
    return (
      <DialogContent>
        <Grid container spacing={24}>
          <CircularProgress />
        </Grid>
      </DialogContent>
    );

  return (
    <>
      <DialogTitle id="form-dialog-title">
        Freeze transcription ({username})
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={12}>
            <DialogContentText>
              {subscriptionFrozen
                ? `${username} has been frozen since ${DateTime.fromJSDate(
                    new Date(subscriptionFrozen),
                  ).toFormat('dd LLL yyyy')}`
                : `${username} does not currently have their transcriptions frozen.`}
            </DialogContentText>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {subscriptionFrozen ? (
          <Button
            onClick={() => update(false)}
            color="primary"
            variant="contained"
          >
            Unfreeze
          </Button>
        ) : (
          <Button
            onClick={() => update(true)}
            color="primary"
            variant="contained"
          >
            Freeze
          </Button>
        )}
        <Button onClick={closeModal} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </>
  );
};

export default FrozenTranscriptionModal;
