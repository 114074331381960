import React from 'react';
import VoucherBatchList from 'vouchers/components/VoucherBatchList';
import { TabContainer } from 'shared/components';
import { Tabs, Tab, AppBar } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import SingleVoucherList from '../vouchers/components/SingleVoucherList';

interface Props extends RouteComponentProps<any> {}

function VouchersPage(props: Props) {
  const changeTab = (event: React.ChangeEvent<{}>, value: any) => {
    const { history } = props;
    if (history.location.pathname !== value) history.replace(value);
  };

  const { path, url } = props.match;
  const { location } = props;

  return (
    <React.Fragment>
      <AppBar position="static" color="default">
        <Tabs
          value={location.pathname}
          indicatorColor="primary"
          onChange={changeTab}
        >
          <Tab label="Single Vouchers" value={`${url}/single`} />
          <Tab label="Voucher Batches" value={`${url}/batches`} />
        </Tabs>
      </AppBar>
      <TabContainer>
        <Switch>
          <Route
            path={`${path}/single`}
            render={(props) => <SingleVoucherList />}
          />
          <Route
            path={`${path}/batches`}
            render={(props) => <VoucherBatchList />}
          />
        </Switch>
      </TabContainer>
    </React.Fragment>
  );
}

export default VouchersPage;
