import * as React from 'react';
import { ApolloError } from 'apollo-boost';
import { Table, TableRow, Pagination } from 'shared/components';
import FileListItem from './FileListItem';
import { TableCellItem } from 'shared/components/TableRow';
import { TrintFile } from 'types';

interface Props {
  headers: TableCellItem[];
  files: TrintFile[];
  isShowingDeleted: boolean;
  page: number;
  perPage: number;
  hasMore: boolean;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void;
  onChangeRowsPerPage?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  onMutationCompleted?: (data: any) => void;
  onMutationError?: (error: ApolloError) => void;
}

class FileList extends React.Component<Props> {
  render() {
    const colWidths = ['22%', '22%', '10%', '10%', '12%', '12%', '12%', '12%'];
    const {
      files = [],
      headers,
      isShowingDeleted,
      page,
      hasMore,
      perPage,
      onChangePage,
      onChangeRowsPerPage,
      onMutationCompleted,
      onMutationError,
    } = this.props;
    return (
      <>
        <Table headers={headers} colWidths={colWidths}>
          {files.map((file) => (
            <TableRow<TrintFile>
              key={file._id}
              item={file}
              component={FileListItem}
              options={{ isShowingDeleted }}
              onMutationCompleted={onMutationCompleted}
              onMutationError={onMutationError}
            />
          ))}
        </Table>
        <Pagination
          page={page}
          perPage={perPage}
          hasMore={hasMore}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </>
    );
  }
}

export default FileList;
