import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { RouteProps, Route } from 'react-router-dom';
import { parseQuery, getRouteWithQuery, mergeIntoQuery } from 'shared/utils';

type RouteChildren<T extends object> = (
  props: RouteComponentProps<any> & { query: T },
) => React.ReactNode;

function isFuncAsChildren<T extends object>(
  item: React.ReactNode | RouteChildren<T>,
): item is RouteChildren<T> {
  return typeof item === 'function';
}

export interface QueryUpdateOptions {
  /**
   * If true the search query string will be merged into current search string. Useful for updating searchs with sorts, paging, etc
   * Defaults to true since this is the generally what we want. Set to false if you want to replace the search string
   */
  replace?: boolean;
}

export interface RouteQueryProps<T extends object> {
  /**
   * Convers a javascript object into a query string
   * @param value an object to be converted (stringified) into a query string
   * @param options parsing options
   */
  update: (value: Partial<T>, options?: QueryUpdateOptions) => void;
  updateAt: (
    pathname: string,
    value: Partial<T>,
    options?: QueryUpdateOptions,
  ) => void;
  params: T;
}

export interface QueryRouteComponentProps<T extends object>
  extends RouteComponentProps<any> {
  query: RouteQueryProps<T>;
}

export interface BaseQueryRouteProps<T extends object> {
  component?:
    | React.ComponentType<QueryRouteComponentProps<T>>
    | React.ComponentType<any>;
  render?: (props: QueryRouteComponentProps<T>) => React.ReactNode;
  children?:
    | ((props: QueryRouteComponentProps<T>) => React.ReactNode)
    | React.ReactNode;
}

export type QueryRouteProps<T extends object> = RouteProps &
  BaseQueryRouteProps<T>;

const defaultOptions: QueryUpdateOptions = {
  replace: false,
};

type InnerProps<T extends object> = BaseQueryRouteProps<T> &
  RouteComponentProps;

class Inner<T extends object> extends React.Component<InnerProps<T>> {
  update = (queryDef: T, options: QueryUpdateOptions = defaultOptions) => {
    const { pathname } = this.props.location;
    this.updateAt(pathname, queryDef, options);
  };

  updateAt = (
    pathname: string,
    queryDef: T,
    options: QueryUpdateOptions = defaultOptions,
  ) => {
    const { history, location } = this.props;
    const { search } = location;

    if (options.replace) {
      history.push(getRouteWithQuery<T>(pathname, queryDef));
    } else {
      history.push(
        getRouteWithQuery(pathname, mergeIntoQuery<T>(search, queryDef)),
      );
    }
  };

  render() {
    const {
      component: Component,
      location,
      children,
      render,
      ...props
    } = this.props;
    const { search } = location;

    const params = parseQuery<T>(search);
    const query: RouteQueryProps<T> = {
      params,
      update: this.update,
      updateAt: this.updateAt,
    };

    if (Component) {
      return <Component {...props} location={location} query={query} />;
    }

    if (typeof render === 'function') {
      return render({ ...props, location, query });
    }

    if (isFuncAsChildren(children)) {
      return children({ ...props, location, query });
    }

    return null;
  }
}

class QueryRoute<T extends object> extends React.Component<QueryRouteProps<T>> {
  render() {
    const { render, component, children, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => (
          <Inner<T>
            render={render}
            children={children}
            component={component}
            {...props}
          />
        )}
      />
    );
  }
}

export default QueryRoute;
