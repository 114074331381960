import * as React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import ProfileForm from 'users/components/ProfileForm';
import { Tabs, Tab, AppBar } from '@material-ui/core';
import { TabContainer, Route as SearchRoute } from 'shared/components';
import BillingForm from 'users/components/BillingForm';
import AllowanceForm from 'users/components/AllowanceForm';
import UserGroupForm from 'users/components/UserGroupForm';
import { USER_GROUP_QUERY, UserGroupQueryResult } from 'users/queries';
import UserActions from 'users/components/UserActions';
import FileListView from 'users/components/FileListView';
import StoryListView from 'users/components/stories/StoryListView';
import ProjectFolderListView from 'users/components/projectFolders/ProjectFolderListView';

interface Props extends RouteComponentProps<any> {}

interface QueryRes {
  user: UserGroupQueryResult | null | undefined;
}

interface QueryVars {
  userId: string;
}

class UserDetails extends React.Component<Props> {
  changeTab = (event: React.ChangeEvent<{}>, value: any) => {
    const { history } = this.props;
    if (history.location.pathname !== value) history.replace(value);
  };

  render() {
    const { userId }: { userId: string } = this.props.match.params;
    const { path, url } = this.props.match;
    const { location } = this.props;

    return (
      <React.Fragment>
        <AppBar position="static" color="default">
          <Query<QueryRes, QueryVars>
            query={USER_GROUP_QUERY}
            fetchPolicy="cache-and-network"
            variables={{ userId }}
          >
            {({ data, error, loading }) => {
              if (error) return null;
              if (loading && (!data || !data.user)) return null;
              const { user } = data!;
              const noGroup = !(user && user.group);

              return (
                <Tabs
                  value={location.pathname}
                  indicatorColor="primary"
                  onChange={this.changeTab}
                >
                  <Tab label="Details" value={`${url}/details`} />
                  <Tab label="Billing" value={`${url}/billing`} />
                  <Tab label="Allowance" value={`${url}/allowance`} />
                  <Tab label="Files" value={`${url}/files`} />
                  <Tab label="Stories" value={`${url}/stories`} />
                  <Tab label="Folders" value={`${url}/folders`} />
                  <Tab label="Actions" value={`${url}/actions`} />
                  <Tab
                    label="Group"
                    value={`${url}/group`}
                    disabled={noGroup}
                  />
                </Tabs>
              );
            }}
          </Query>
        </AppBar>
        <TabContainer>
          <Switch>
            <Route
              path={`${path}/details`}
              render={(props) => (
                <ProfileForm {...props} key={userId} userId={userId} />
              )}
            />
            <Route
              path={`${path}/billing`}
              render={(props) => <BillingForm key={userId} userId={userId} />}
            />
            <Route
              path={`${path}/actions`}
              render={(props) => <UserActions key={userId} userId={userId} />}
            />
            <Route
              path={`${path}/allowance`}
              render={(props) => <AllowanceForm key={userId} userId={userId} />}
            />
            <Route
              path={`${path}/stories`}
              render={(props) => <StoryListView key={userId} userId={userId} />}
            />
            <SearchRoute path={`${path}/files`} render={FileListView} />
            <Route
              path={`${path}/folders`}
              render={(props) => (
                <ProjectFolderListView key={userId} userId={userId} />
              )}
            />
            <Route
              path={`${path}/group`}
              render={(props) => <UserGroupForm key={userId} userId={userId} />}
            />
          </Switch>
        </TabContainer>
      </React.Fragment>
    );
  }
}

export default UserDetails;
