import { DateTime } from 'luxon';

function formatDate(
  date: number | string,
  format: string = 'dd LLL yyyy HH:mm',
): string {
  return DateTime.fromJSDate(new Date(date)).toFormat(format);
}

export default formatDate;
