import * as React from 'react';
import { Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { QueryUpdateOptions, RouteQueryProps } from './QueryRoute';
import {
  parseQuery,
  getRouteWithQuery,
  mergeIntoQuery,
  pick,
} from 'shared/utils';

interface Props<T extends object> {
  allowedKeys?: Array<keyof T>;
  children: (query: RouteQueryProps<T>) => React.ReactNode;
}

const defaultOptions: QueryUpdateOptions = {
  replace: false,
};

function checkQuery<T extends object = any>(value: T, keys: Array<keyof T>) {
  if (keys.length === 0) return value;
  return pick(value, keys);
}

class InnerQueryString<T extends object> extends React.Component<
  Props<T> & RouteComponentProps
> {
  updateQuery = (queryDef: T, options: QueryUpdateOptions = defaultOptions) => {
    const { pathname } = this.props.location;
    this.updateQueryAt(pathname, queryDef, options);
  };

  updateQueryAt = (
    pathname: string,
    queryDef: T,
    options: QueryUpdateOptions = defaultOptions,
  ) => {
    const { history, location, allowedKeys = [] } = this.props;
    const { search } = location;
    const { replace } = options;

    const update = replace
      ? checkQuery(queryDef, allowedKeys)
      : checkQuery(mergeIntoQuery<T>(search, queryDef), allowedKeys);

    history.push(getRouteWithQuery(pathname, update));
  };

  collectProps = (): RouteQueryProps<T> => {
    const { search } = this.props.location;
    const params = parseQuery<T>(search);
    return { params, update: this.updateQuery, updateAt: this.updateQueryAt };
  };

  render() {
    const { children } = this.props;
    return children(this.collectProps());
  }
}

class QueryString<T extends object> extends React.Component<Props<T>> {
  render() {
    // The magic here happens because a <Route />'s location prop will
    // update when the uri search changes so there is no need to store anything in state
    return (
      <Route
        render={(props) => <InnerQueryString {...this.props} {...props} />}
      />
    );
  }
}

export default QueryString;
