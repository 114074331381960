import React from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { createFormContext } from 'yafl';
import moment from 'moment';
import FormTextInput from 'shared/formKit/FormTextInput';
import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { FormKit } from 'shared/formKit';
import { ModalTrigger } from 'contexts/ModalTrigger';
import CustomToast from 'shared/components/CustomToast';
import {
  PlanDetailsQueryResult,
  PlanDetailsQueryVars,
  PLAN_DETAILS_QUERY,
} from '../queries';
import {
  UpdateEnterprisePlanDetailsResult,
  UpdateEnterprisePlanDetailsVars,
  UPDATE_ENTERPRISE_PLAN_DETAILS_MUTATION,
} from '../mutations';
import { internalContactsValidator, enterpriseNameValidator } from '../formFieldValidators';

interface FormData {
  internalContacts: string;
  enterpriseName: string;
  contractEndDate: string;
}

interface EnterpriseUpdatePlanDetailsFormProps {
  handleClose: () => void;
  userId: string;
}

function EnterpriseUpdatePlanDetailsForm(props: EnterpriseUpdatePlanDetailsFormProps) {
  const context = createFormContext<FormData>();
  const { Field } = context;
  const { userId, handleClose } = props;

  const [
    updatePlanDetailsMutation,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation<UpdateEnterprisePlanDetailsResult, UpdateEnterprisePlanDetailsVars>(
    UPDATE_ENTERPRISE_PLAN_DETAILS_MUTATION,
  );

  const { data, loading, error } = useQuery<
    PlanDetailsQueryResult,
    PlanDetailsQueryVars
  >(PLAN_DETAILS_QUERY, {
    variables: {
      userId,
    },
  });

  if (loading && (!data || !data.user)) {
    return <p>Loading...</p>;
  }

  if (error || !data || !data.user) return <p>Error</p>;

  const internalContactsArray = data.user.currentSubscription.internalContacts ?? [];
  const internalContacts = internalContactsArray.length ? internalContactsArray.join(' ') : '';
  const enterpriseName = data.user.billing.planName || 'Unknown';
  const contractEndDate = data.user.currentSubscription.contractEndDate ?? moment().format('YYYY-MM-DD');

  const dto = {
    userId,
    internalContacts,
    enterpriseName,
    contractEndDate, 
  };

  return (
    <div>
      <FormKit<FormData>
        context={context}
        initialValue={dto}
        defaultValue={dto}
      >
        {({ formValue, formIsValid }) => {
          return (
            <>
              <DialogTitle id="form-dialog-title">
                Update Enterprise Plan Details
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={24}>
                  <Grid item xs={6} sm={12} style={{ marginTop: '10px' }}>
                    <Field
                      fullWidth
                      name="enterpriseName"
                      label="Enterprise Name"
                      component={FormTextInput}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      validate={enterpriseNameValidator}
                    />
                  </Grid>

                  <Grid item xs={6} sm={12}>
                    <Field
                      fullWidth
                      name="internalContacts"
                      label="Internal Contacts"
                      component={FormTextInput}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      validate={internalContactsValidator}
                    />
                  </Grid>

                  <Grid item xs={6} sm={12}>
                    <Field
                      fullWidth
                      name="contractEndDate"
                      label="Contract End"
                      type="date"
                      component={FormTextInput}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <ModalTrigger<'ConfirmDialog'>
                  modal="ConfirmDialog"
                  modalProps={{
                    onConfirm: () => {
                      updatePlanDetailsMutation({
                        variables: {
                          userId,
                          internalContacts: formValue.internalContacts,
                          enterpriseName: formValue.enterpriseName,
                          contractEndDate: formValue.contractEndDate,
                        },
                      })
                        .then(() => {
                          window.location.reload();
                        })
                        .catch(() => {});
                    },
                    title: 'Are you sure you want to update plan details for this Enterprise?',
                  }}
                >
                  {({ openModal }) => (
                    <Button
                      disabled={!formIsValid || mutationLoading}
                      onClick={openModal}
                      color="primary"
                      variant="contained"
                    >
                      {mutationLoading
                        ? 'Updating plan details...'
                        : 'Update plan details'}
                    </Button>
                  )}
                </ModalTrigger>
              </DialogActions>
            </>
          );
        }}
      </FormKit>
      {mutationError && <CustomToast message={mutationError.message} isOpen />}
    </div>
  );
}

interface EnterpriseUpdatePlanDetailsModalProps {
  handleClose: () => void;
  isOpen: boolean;
  userId: string;
}

function EnterpriseUpdatePlanDetailsModal(props: EnterpriseUpdatePlanDetailsModalProps) {
  const { handleClose, isOpen, userId } = props;

  return (
    <Dialog onClose={handleClose} open={isOpen} fullWidth>
      <EnterpriseUpdatePlanDetailsForm handleClose={handleClose} userId={userId} />
    </Dialog>
  );
}

export default EnterpriseUpdatePlanDetailsModal;
