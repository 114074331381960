import * as React from 'react';
import { createStyled } from 'shared/hocs';
import {
  // Button as Btn,
  // FormControl,
  // FormLabel,
  // Grid,
  // Paper,
  // TextField,
  Theme,
  // Typography,
  createStyles,
} from '@material-ui/core';
// import {
//   GENERAL_SEARCH_QUERY,
//   GeneralSearchQueryResult,
// } from 'users/queries';
// import { Link } from 'react-router-dom';

interface Props {
  groupId: string;
}

// interface QueryDefinition {
//   search?: string;
//   order: SortDirection;
//   sort: string;
//   perPage: number;
//   page: number;
// }

// interface QueryVars {
//   searchText?: string;
//   page: number;
//   limit: number;
//   order: SortDirection;
//   sortBy?: string;
// }
// interface QueryRes {
//   users: {
//     list: GeneralSearchQueryResult[];
//     hasMore: boolean;
//   };
// }

// // https://github.com/mui-org/material-ui/issues/11968
// const Button: React.ComponentType<any> = Btn;

class InvitedUsersForm extends React.Component<Props> {
  renderForm(data: any) {
    return (
      <Styled>
        {({ classes }) => (
          <main className={classes.layout}>TODO: Place Holder</main>
        )}
      </Styled>
    );
  }

  render() {
    return this.renderForm({});
  }
}

const Styled = createStyled((theme: Theme) => {
  return createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing.unit * 3,
      marginLeft: theme.spacing.unit,
    },
    title: {
      marginBottom: theme.spacing.unit * 3,
    },
    formLink: {
      fontSize: 16,
      textDecoration: 'none',
      color: '#0000d0',
      '&visited': {
        textDecoration: 'none',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  });
});

export default InvitedUsersForm;
