import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { Tabs, Tab, AppBar } from '@material-ui/core';
import { TabContainer, Route as SearchRoute } from 'shared/components';
import DomainsForm from 'organisations/components/DomainsForm';
import OrganisationDetailsForm from 'organisations/components/OrganisationDetailsForm';
import OrganisationAllowance from 'organisations/components/OrganisationAllowance';
import OrganisationInvitedUsersForm from 'organisations/components/InvitedUsersForm';
import OrganisationUsage from 'organisations/components/OrganisationUsage';
import BulkDelete from 'organisations/components/secureDelete/BulkDelete';
import MembersList from 'organisations/components/MembersList';
import { RealtimeStreamsList } from 'organisations/components/RealtimeStreamsList';

interface Props extends RouteComponentProps<any> {}

class OrganisationDetails extends React.Component<Props> {
  changeTab = (event: React.ChangeEvent<{}>, value: any) => {
    const { history } = this.props;
    history.replace(value);
  };

  render() {
    const { groupId } = this.props.match.params;
    const { path, url } = this.props.match;
    const { location } = this.props;

    return (
      <React.Fragment>
        <AppBar position="static" color="default">
          <Tabs
            value={location.pathname}
            indicatorColor="primary"
            onChange={this.changeTab}
          >
            <Tab label="Details" value={`${url}/details`} />
            <Tab label="Allowance" value={`${url}/allowance`} />
            <Tab label="Domains" value={`${url}/domains`} />
            <Tab label="Members" value={`${url}/members`} />
            <Tab label="Invited" value={`${url}/invited`} />
            <Tab label="Usage" value={`${url}/usage`} />
            <Tab label="GDPR Delete" value={`${url}/gdpr-delete`} />
            <Tab label="Realtime" value={`${url}/realtime`} />
          </Tabs>
        </AppBar>
        <TabContainer>
          <Switch>
            <Route
              path={`${path}/details`}
              render={props => (
                <OrganisationDetailsForm
                  {...props}
                  key={groupId}
                  groupId={groupId}
                />
              )}
            />

            <Route
              path={`${path}/allowance`}
              render={props => <OrganisationAllowance groupId={groupId} />}
            />

            <Route
              path={`${path}/domains`}
              render={props => (
                <DomainsForm {...props} key={groupId} groupId={groupId} />
              )}
            />

            <SearchRoute path={`${path}/members`} render={MembersList} />

            <Route
              path={`${path}/invited`}
              render={props => (
                <OrganisationInvitedUsersForm
                  {...props}
                  key={groupId}
                  groupId={groupId}
                />
              )}
            />
            <Route
              path={`${path}/usage`}
              render={props => (
                <OrganisationUsage {...props} key={groupId} groupId={groupId} />
              )}
            />

            <Route
              path={`${path}/gdpr-delete`}
              render={props => (
                <BulkDelete {...props} key={groupId} groupId={groupId} />
              )}
            />
            <Route
              path={`${path}/realtime`}
              render={props => (
                <RealtimeStreamsList
                  {...props}
                  key={groupId}
                  groupId={groupId}
                />
              )}
            />
          </Switch>
        </TabContainer>
      </React.Fragment>
    );
  }
}

export default OrganisationDetails;
