import isNullOrUndefined from './isNullOrUndefined';

function pick<T extends object = any>(value: T, keys: Array<keyof T>) {
  if (typeof value !== 'object' || isNullOrUndefined(value)) return {};
  const initialValue = {} as T;
  const objKeys = Array.isArray(value)
    ? value.map((x: any, i: any) => i)
    : (Object.keys(value) as Array<keyof T>);
  return objKeys.reduce<T>((ret, key) => {
    if (keys.includes(key)) {
      ret[key] = value[key];
    }
    return ret;
  }, initialValue);
}

export default pick;
