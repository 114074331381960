import * as React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Theme,
  createStyles,
  FormControl,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { createStyled } from 'shared/hocs';
import {
  useUnicornTenantContext,
  UNICORN_TENANT_OPTIONS,
} from './UnicornTenantContext';

export function UnicornTenantSelector() {
  const providerContext = useUnicornTenantContext();

  function handleChange(event: React.ChangeEvent<{ value: string }>) {
    providerContext.setEndpoint(event.target.value);
  }

  return (
    <Style>
      {({ classes }) => (
        <>
          <div className={classes.root}>
            <Typography
              className={classes.tenantLabel}
              variant="h6"
              color="inherit"
              noWrap
            >
              Tenant:
            </Typography>
            <FormControl className={classes.formControl}>
              <Select
                input={<OutlinedInput labelWidth={0} />}
                className={classes.tenantSelector}
                value={providerContext.activeEndpoint}
                onChange={handleChange}
              >
                {UNICORN_TENANT_OPTIONS.map(ep => {
                  return (
                    <MenuItem key={ep.value} value={ep.value}>
                      {ep.display}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </>
      )}
    </Style>
  );
}

const Style = createStyled((theme: Theme) =>
  createStyles({
    formControl: {
      margin: 0,
      minWidth: 200,
    },

    root: {
      display: 'flex',
    },

    tenantLabel: {
      marginRight: theme.spacing.unit,
    },

    tenantSelector: {
      borderRadius: theme.shape.borderRadius,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.grey[500],
      padding: theme.spacing.unit,
      height: 35,
      color: 'inherit',
      width: '100%',
    },
  }),
);

export default UnicornTenantSelector;
