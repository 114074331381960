import * as React from 'react';
import {
  TableRow,
  TableCell,
  Button,
  Theme,
  createStyles,
} from '@material-ui/core';
import { ListInstanceItem } from 'ec2launcher/queries';
import { createStyled } from 'shared/hocs';
import { ModalTrigger } from 'contexts/ModalTrigger';
import { Mutation } from 'react-apollo';
import { TERMINATE_INSTANCE } from 'ec2launcher/mutations';

enum InstanceStatus {
  Starting = 'STARTING',
  Running = 'RUNNING',
  Stopping = 'STOPPING',
  Terminated = 'TERMINATED',
}

interface Props {
  refetch: () => Promise<void>;
  launchId: string;
  item: ListInstanceItem;
  onRowClick: (
    e: React.MouseEvent<HTMLTableRowElement>,
    item: ListInstanceItem,
  ) => void;
}

function Ec2InstanceListItem(props: Props) {
  const { item } = props;

  return (
    <Styled>
      {({ classes }) => (
        <TableRow>
          <TableCell>{item.instanceName}</TableCell>
          <TableCell>{item.instanceIpAddress}</TableCell>
          <TableCell>{item.status}</TableCell>
          <TableCell>
            {new Date(item.expiry).toLocaleString('default', {
              dateStyle: 'medium',
              timeStyle: 'medium',
              hour12: false,
            })}
          </TableCell>
          <TableCell>
            {item.userIpAddresses.map((ip) => {
              return <div key={ip}>{ip}</div>;
            })}
          </TableCell>
          <TableCell>
            {[InstanceStatus.Starting, InstanceStatus.Running].includes(
              item.status as InstanceStatus,
            ) && (
              <Mutation
                mutation={TERMINATE_INSTANCE}
                onCompleted={props.refetch}
                variables={{ launchId: item.launchId }}
              >
                {(terminateInstance, { loading }) => {
                  return (
                    <ModalTrigger
                      modal="ConfirmDialog"
                      modalProps={{
                        onConfirm: terminateInstance,
                        title: 'Terminate',
                        message:
                          'Are you sure you want to terminate the EC2 instance?',
                      }}
                    >
                      {({ openModal }) => (
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={openModal}
                          disabled={loading}
                        >
                          Terminate
                        </Button>
                      )}
                    </ModalTrigger>
                  );
                }}
              </Mutation>
            )}
          </TableCell>
        </TableRow>
      )}
    </Styled>
  );
}

const Styled = createStyled((theme: Theme) =>
  createStyles({
    width: {
      width: '100%',
    },
    capitalise: {
      textTransform: 'capitalize',
    },
    tableCellPadding: {
      padding: '4px 0px 4px 24px',
    },
    adminIcon: {
      display: 'inline-flex',
      flex: '0 0 auto',
      verticalAlign: 'middle',
      paddingRight: 3,
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&visited': {
        textDecoration: 'none',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    tooltipPlacementTop: {
      margin: '0 0 3px 0',
    },
    tooltipPopper: {
      '&[x-placement*="top"] ': {
        opacity: 1,
      },
    },
    lightTooltip: {
      backgroundColor: '#aaaaaa',
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
    textOverflow: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

export default Ec2InstanceListItem;
