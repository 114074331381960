import React from 'react';
import Ec2InstanceList from 'ec2launcher/components/Ec2InstanceList';
import { Query } from 'react-apollo';
import { QueryRouteComponentProps } from 'shared/components/QueryRoute';
import ListViewLayout from 'shared/layout/ListViewLayout';
import { Button, createStyles, Theme } from '@material-ui/core';
import { ListInstanceItem, LIST_INSTANCES } from 'ec2launcher/queries';

// import AddIcon from '@material-ui/icons/PlusOne';
import { createStyled } from 'shared/hocs';
import * as create from 'shared/components/Table';
import { GlobalCircleLoader, ListPage } from 'shared/components';
import { ListPageRenderProps, SortDirection } from 'shared/components/ListPage';
import { LaunchInstanceModal } from 'ec2launcher/components/LaunchInstanceModal';
import CustomToast from 'shared/components/CustomToast';
import ListViewSearchArea from 'shared/layout/ListViewSearchArea';

export interface QueryDefinition {
  search?: string;
  order: SortDirection;
  sort: string;
  perPage: number;
  page: number;
}

export interface QueryVars {
  limit: number;
  order: SortDirection;
  sortBy?: string;
}

interface State {
  value: string;
  isInCreateMode: boolean;
  notification: string;
}

export interface QueryResult {
  listInstances: {
    instances: ListInstanceItem[];
  };
}

type Props = QueryRouteComponentProps<QueryDefinition> &
  ListPageRenderProps<{ search?: string }>;

const colWidths = ['20%', '10%', '10%', '10%', '10%', '15%'];

class Inner extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: '',
      isInCreateMode: false,
      notification: '',
    };
  }

  launchInstance = () => this.setState({ isInCreateMode: true });
  closeModal = () => this.setState({ isInCreateMode: false });
  onCreate = () => {
    this.setState({
      notification:
        'Successfully launched instance, just a short wait until its ready',
    });
  };

  render() {
    const headers = [
      create.header('Instance Name', 'instanceName'),
      create.header('Public IP', 'instanceIpAddress'),
      create.header('Status', 'status'),
      create.header('Expiry', 'expiry'),
      create.header('Allow Access From IPs', 'userIpAddresses'),
      create.header('Actions'),
    ];

    return (
      <Style>
        {({ classes }) => (
          <ListViewLayout>
            <ListViewSearchArea>
              <Button
                onClick={this.launchInstance}
                variant="contained"
                color="primary"
              >
                Launch New Instance
              </Button>
            </ListViewSearchArea>
            <Query<QueryResult, QueryVars>
              query={LIST_INSTANCES}
              fetchPolicy="cache-and-network"
            >
              {({ data, error, loading, refetch }) => {
                if (error) {
                  return null;
                }
                if (loading && (!data || !data.listInstances))
                  return <GlobalCircleLoader show />;

                return (
                  <>
                    <Ec2InstanceList
                      refetch={refetch}
                      colWidths={colWidths}
                      headers={headers}
                      instances={data!.listInstances.instances}
                    />
                    <LaunchInstanceModal
                      onCreate={() => {
                        this.onCreate();
                        refetch();
                      }}
                      onClose={this.closeModal}
                      isOpen={this.state.isInCreateMode}
                      cancel={this.closeModal}
                    />
                  </>
                );
              }}
            </Query>
            {this.state.notification && (
              <CustomToast message={this.state.notification} isOpen />
            )}
          </ListViewLayout>
        )}
      </Style>
    );
  }
}

const sortKeys = ['instanceName', 'status', 'expiry'];

const Ec2LauncherPage: React.SFC<Props> = (props) => {
  return (
    <ListPage sortKeys={sortKeys} defaultSortBy="instanceName">
      {(query) => <Inner {...query} {...props} />}
    </ListPage>
  );
};

const Style = createStyled((theme: Theme) =>
  createStyles({
    searchInput: {
      borderRadius: theme.shape.borderRadius,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.grey[500],
      padding: theme.spacing.unit,
    },
    tableContainer: {
      marginTop: theme.spacing.unit * 5,
      marginLeft: theme.spacing.unit * 5,
      marginRight: theme.spacing.unit * 5,
    },
  }),
);

export default Ec2LauncherPage;
