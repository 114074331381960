import * as React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { UserConsumer, AuthContextState } from 'shared/components/UserContext';

const withUserContext = <T extends object>(
  Comp: React.ComponentType<any>,
): React.ComponentType<T> => {
  const Wrapped: React.SFC<T> = props => {
    return (
      <UserConsumer>
        {userContextProps => {
          return <Comp {...props} auth={userContextProps} />;
        }}
      </UserConsumer>
    );
  };

  return hoistNonReactStatics<
    React.ComponentType<T>,
    React.ComponentType<T & { auth: AuthContextState }>
  >(Wrapped, Comp);
};

export default withUserContext;
