import React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import {
  Grid,
  Paper,
  Typography,
  Theme,
  createStyles,
} from '@material-ui/core';
import { createStyled } from 'shared/hocs';

export const COUPON_STATS_QUERY = gql`
  query {
    couponStats {
      stats {
        count
        batches {
          count
          valid
          used
        }
        coupons {
          count
          valid
          used
        }
      }
    }
  }
`;

interface CouponStats {
  couponStats: {
    stats: {
      count: number;
      batches: {
        count: number;
        valid: number;
        used: number;
      };
      coupons: {
        count: number;
        valid: number;
        used: number;
      };
    };
  };
}

interface Props {
  batch: boolean;
}

function VoucherStats(props: Props) {
  const { batch } = props;
  const { data, error, loading } = useQuery<CouponStats>(COUPON_STATS_QUERY);
  if (loading) {
    return <div> loading stats</div>;
  }

  if (error) {
    return <div>Couldn't load stats{error.message}</div>;
  }

  if (!data) return null;

  return (
    <Styled>
      {({ classes }) => (
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography
              className={classes.title}
              component="h1"
              variant="h5"
              align="center"
            >
              Stats
            </Typography>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={4}>
                {' '}
                <Typography
                  className={classes.subtitle}
                  variant="subtitle2"
                  align="center"
                >
                  Total
                </Typography>
                <Typography variant="h2" align="center" paragraph>
                  {batch
                    ? data?.couponStats?.stats?.batches?.count
                    : data?.couponStats?.stats?.coupons?.count}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                {' '}
                <Typography
                  className={classes.subtitle}
                  variant="subtitle2"
                  align="center"
                >
                  Valid
                </Typography>
                <Typography variant="h2" align="center" paragraph>
                  {batch
                    ? data?.couponStats?.stats?.batches?.valid
                    : data?.couponStats?.stats?.coupons?.valid}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                {' '}
                <Typography
                  className={classes.subtitle}
                  variant="subtitle2"
                  align="center"
                >
                  Used
                </Typography>
                <Typography variant="h2" align="center" paragraph>
                  {batch
                    ? data?.couponStats?.stats?.batches?.used
                    : data?.couponStats?.stats?.coupons?.used}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </main>
      )}
    </Styled>
  );
}

const Styled = createStyled((theme: Theme) => {
  return createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    title: {
      marginBottom: theme.spacing.unit,
    },
    subtitle: {
      marginBottom: theme.spacing.unit * 3,
    },
    tips: {
      fontWeight: 500,
    },
    footer: {
      marginTop: theme.spacing.unit * 6,
    },
  });
});

export default VoucherStats;
