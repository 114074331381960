import gql from 'graphql-tag';
import { TrintGroup, TrintTeam, GroupMember } from 'types';
import Group from 'fragments/Groups';
import Team from 'fragments/Team';

type RootFields = '_id' | 'metadata' | 'isEnterprise' | 'owner';
type GroupFields = '_id' | 'username' | 'organisationAdmin';

export type AllOrganisationsQueryResult = Pick<TrintGroup, RootFields> & {
  members: Array<Pick<GroupMember, GroupFields>>;
  totalActiveMembers: number;
};

/**
 * Query all available organisations. Organisation and Group are
 * synonymous.
 */
export const ALL_ORGANISATIONS_QUERY = gql`
  query groups(
    $searchText: String
    $page: Int!
    $limit: Int!
    $order: String
    $sortBy: String
  ) {
    groups(
      searchText: $searchText
      page: $page
      limit: $limit
      order: $order
      sortBy: $sortBy
    ) {
      list {
        ...GroupBase
        ...GroupMembersInfo
      }
      hasMore
    }
  }
  ${Group.fragments.groupBase}
  ${Group.fragments.groupMembersInfo}
`;

/**
 * Query an organisation by Id. Organisation and Group are
 * synonymous.
 */
export const ORGANISATION_QUERY = gql`
  query group($groupId: String!) {
    group(groupId: $groupId) {
      ...GroupBase
      ...GroupTeamsInfo
      ...GroupOwnerInfo
      ...GroupSettings
      ...GroupMembersInfo
    }
  }
  ${Group.fragments.groupBase}
  ${Group.fragments.groupTeamsInfo}
  ${Group.fragments.groupOwnerInfo}
  ${Group.fragments.groupSettings}
  ${Group.fragments.groupMembersInfo}
`;
export type OrganisationQueryResult = TrintGroup;

/**
 * Query teams within an organisation by organisation Id
 */
export const ORGANISATION_TEAMS_QUERY = gql`
  query getTeams($groupId: String!) {
    getTeams(groupId: $groupId) {
      ...TeamBase
      admins {
        _id
      }
      members {
        _id
      }
    }
  }
  ${Team.fragments.teamBase}
`;
export type OrganisationTeamsQueryResult = TrintTeam;

/**
 * Query a specific team by Id
 */
export const TEAM_QUERY = gql`
  query getTeam($teamId: String!) {
    getTeam(teamId: $teamId) {
      ...TeamBase
      admins {
        _id
      }
      members {
        _id
      }
    }
  }
  ${Team.fragments.teamBase}
`;
export type TeamQueryResult = TrintTeam;

export const ORGANISATION_USAGE_QUERY = gql`
  query getOrganisationUsage(
    $organisationId: String!
    $start: Float
    $end: Float
  ) {
    group(groupId: $organisationId, start: $start, end: $end) {
      _id
      metadata {
        name
      }
      usage {
        principal
        count {
          ...UsageFragment
        }
        uploaded {
          ...UsageFragment
        }
        transcoded {
          ...UsageFragment
        }
        transcribed {
          ...UsageFragment
        }
        transcribing {
          ...UsageFragment
        }
        blocked {
          ...UsageFragment
        }
        capping {
          ...UsageFragment
        }
        members {
          _id
          username
          count
          total
          profile {
            firstName
            lastName
          }
        }
      }
    }
  }
  ${Group.fragments.usageFragment}
`;
export type OrganisationUsageQueryResult = TrintGroup;
