import { DateTime } from 'luxon';

interface LastMonth {
  startDate: string;
  endDate: string;
}

export default function getLastMonthDates(
  format: string = 'yyyy-LL-dd',
): LastMonth {
  const lastMonth = DateTime.fromMillis(Date.now())
    .toUTC()
    .minus({ month: 1 });
  const startDateTime = lastMonth.startOf('month');
  const endDateTime = lastMonth.endOf('month');

  const startDate = startDateTime.toFormat(format);
  const endDate = endDateTime.toFormat(format);

  return { startDate, endDate };
}
