/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useMutation } from 'react-apollo';
import { BillingOperation, BillingOperationTypes } from 'types';
import moment from 'moment';
import gql from 'graphql-tag';
import { Grid } from '@material-ui/core';

const UNDO_PENDING_OP_MUTATION = gql`
  mutation undoPendingOperation(
    $userId: String!
    $pendingOperationId: String!
  ) {
    undoPendingOperation(
      userId: $userId
      pendingOperationId: $pendingOperationId
    ) {
      _id
      profile {
        firstName
        lastName
      }
      billing {
        pendingOperations {
          id
        }
      }
    }
  }
`;

interface Props {
  pendingOperation: BillingOperation;
  userId: string;
  onUndo?: () => void;
}

function PendingBillingOperation(props: Props) {
  const { pendingOperation, onUndo, userId } = props;
  const [undoPendingOp] = useMutation(UNDO_PENDING_OP_MUTATION, {
    variables: {
      userId,
      pendingOperationId: pendingOperation.id,
    },
    onCompleted: onUndo,
  });
  switch (pendingOperation.type) {
    case BillingOperationTypes.Downgrade: {
      return (
        <Grid justify="center" container spacing={0}>
          <Grid item>
            Downgrading to plan id: {pendingOperation.data.nextPlanId}
          </Grid>
          <Grid item>
            with {pendingOperation.data.nextSeats} seats on{' '}
            {moment(pendingOperation.executeAt).format('LLL')}
            &nbsp;
            <a onClick={() => undoPendingOp()} href="#">
              Undo
            </a>
          </Grid>
        </Grid>
      );
    }

    case BillingOperationTypes.Cancelling: {
      return (
        <Grid justify="center" spacing={0} container>
          {' '}
          Cancelling plan on {moment(pendingOperation.executeAt).format(
            'LLL',
          )}{' '}
        </Grid>
      );
    }

    case BillingOperationTypes.PaymentIntentAuthExpiry:
      return (
        <Grid justify="center" spacing={0} container>
          Payment authorisation expiring on{' '}
          {moment(pendingOperation.executeAt).format('LLL')} with plan{' '}
          {pendingOperation.data.basePlanId}
        </Grid>
      );

    default:
      return null;
  }
}

export default PendingBillingOperation;
