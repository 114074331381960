import gql from 'graphql-tag';
import { TrintGroup } from 'types';

export type EnableEnterpriseMutationResult = Pick<
  TrintGroup,
  '_id' | 'isEnterprise'
>;

export const UPDATE_DOMAINS = gql`
  mutation addSharingDomains($groupId: String!, $domains: [String!]) {
    addSharingDomains(groupId: $groupId, domains: $domains) {
      _id
      settings {
        allowedSharingDomains
      }
    }
  }
`;

export const REMOVE_DOMAINS = gql`
  mutation removeSharingDomains($groupId: String!, $domains: [String!]) {
    removeSharingDomains(groupId: $groupId, domains: $domains) {
      _id
      settings {
        allowedSharingDomains
      }
    }
  }
`;

export const UPDATE_ORGANISATION_DETAILS = gql`
  mutation updateOrganisationDetails(
    $organisationId: String!
    $name: String!
    $ownerId: String!
  ) {
    updateOrganisationDetails(
      organisationId: $organisationId
      name: $name
      ownerId: $ownerId
    ) {
      _id
      owner {
        _id
        username
      }
      metadata {
        name
      }
    }
  }
`;

export const CREATE_ORGANISATION_MUTATION = gql`
  mutation createGroup($name: String!, $ownerId: String!) {
    createGroup(name: $name, ownerId: $ownerId)
  }
`;
