import gql from 'graphql-tag';

export interface ListInstanceItem {
  launchId: string;
  userKey: string;
  instanceName: string;
  instanceIpAddress?: string;
  userIpAddresses: string[];
  status: string;
  expiry: string;
}

export const LIST_INSTANCES = gql`
  query listInstances {
    listInstances {
      instances {
        launchId
        userKey
        instanceName
        instanceIpAddress
        userIpAddresses
        status
        expiry
        __typename
      }
    }
  }
`;

export const LIST_LAUNCH_TEMPLATES = gql`
  query listLaunchTemplates {
    listLaunchTemplates {
      launchTemplates
    }
  }
`;

export type LaunchTemplateList = string[];

export interface LaunchTemplateResult {
  listLaunchTemplates: {
    launchTemplates: LaunchTemplateList;
  };
}
