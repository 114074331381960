import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AuthContextState, UserConsumer } from 'shared/components/UserContext';
import { AuthData } from '../auth';
import { tokenIsValid } from '../auth/utils';
import Unauthorised from 'pages/Unauthorised';
import QueryRoute, { QueryRouteProps, RouteQueryProps } from './QueryRoute';

interface Props<T extends object> extends QueryRouteProps<T> {
  allowAnonymous?: boolean;
  check?: (auth: AuthData) => boolean | void;
}

interface InnerProps<T extends object> extends Props<T> {
  auth: AuthContextState;
}

const noop = () => true;

class InnerProtectedRoute<T extends object> extends React.Component<
  InnerProps<T>
> {
  hasAccess = () => {
    const { auth, check = noop, allowAnonymous = false } = this.props;

    if (auth.isAuthorised && tokenIsValid(auth.authData)) {
      return !!check(auth.authData);
    }

    return allowAnonymous;
  };

  // tslint:disable-next-line:variable-name
  _render = (
    routeProps: RouteComponentProps & { query: RouteQueryProps<T> },
  ) => {
    const { auth } = this.props;
    if (auth.signingIn) return null;

    if (this.hasAccess()) {
      const { component: Component } = this.props;

      if (Component) {
        return <Component {...routeProps} />;
      }

      const { render } = this.props;
      if (typeof render === 'function') {
        return render(routeProps);
      }

      throw new Error('Must provide component or render prop');
    }

    const { location } = this.props;
    const redirectUrl = location && location.pathname;
    return <Unauthorised redirectUrl={redirectUrl} login={auth.login} />;
  };

  render() {
    const { render, component, ...rest } = this.props;
    return <QueryRoute {...rest} render={this._render} />;
  }
}

class ProtectedRoute<T extends object> extends React.Component<Props<T>> {
  render() {
    return (
      <UserConsumer>
        {tabContext => (
          <InnerProtectedRoute<T> {...this.props} auth={tabContext} />
        )}
      </UserConsumer>
    );
  }
}

export default ProtectedRoute;
