import * as React from 'react';

export interface UnicornTenantContextState {
  setEndpoint: (tenantEndpoint: string) => void;
  activeEndpoint: string;
}

interface ProviderProps {
  children: React.ReactNode;
  storage?: Storage;
}

interface ProviderState {
  activeEndpoint: string;
}

interface GqlEndpointOption {
  display: string;
  value: string;
}

/**
 * Build the array of options from the config
 */
function getGqlEndpointOptions(): GqlEndpointOption[] {
  const optionParts = process.env.REACT_APP_TENANT_ENDPOINT_OPTIONS!.split('|');

  const optionList = optionParts.map(o => {
    const [k, v] = o.split('=');
    return { display: k, value: v };
  });

  return optionList;
}

export const UNICORN_TENANT_OPTIONS = getGqlEndpointOptions();
const ENDPOINT_STORAGE_KEY = 'unicornEndpoint';

export const UnicornTenantContext = React.createContext<UnicornTenantContextState | null>(
  null,
);

class Provider extends React.Component<ProviderProps, ProviderState> {
  private store: Storage;

  constructor(props: ProviderProps) {
    super(props);

    const { storage } = this.props;
    this.store = storage || window.localStorage;

    this.state = { activeEndpoint: this.getValidDefaultEndpoint() };
  }

  /**
   * Retrieve the last selected endpoint
   */
  private getLastUsedEndpoint(): string | null {
    return this.store.getItem(ENDPOINT_STORAGE_KEY);
  }

  /**
   * Set the last selected endpoint
   */
  private setLastUsedEndpoint(endpoint: string): void {
    this.store.setItem(ENDPOINT_STORAGE_KEY, endpoint);
  }

  /**
   * Get an endpoint that is currently active. This can either be the last endpoint the
   * user used, or null.
   */
  private getValidDefaultEndpoint(): string {
    const endpointValue = this.getLastUsedEndpoint();

    // Use the last selected tenant endpoint if that is still a valid option in the list
    const lastUsedEp = UNICORN_TENANT_OPTIONS.find(
      opt => opt.value === endpointValue,
    );
    if (lastUsedEp) return lastUsedEp.value;

    // Use the configured default option if that is a valid option in the list
    const defaultEp = UNICORN_TENANT_OPTIONS.find(
      // opt => opt.value === process.env.REACT_APP_TENANT_ENDPOINT_DEFAULT!,
      opt => opt.value === process.env.REACT_APP_TENANT_ENDPOINT_LOCALHOST!,
    );
    if (defaultEp) return defaultEp.value;

    // Fallback to the first in the list
    return UNICORN_TENANT_OPTIONS[0].value;
  }

  componentDidUpdate(prevProps: ProviderProps, prevState: ProviderState) {
    const { activeEndpoint } = this.state;

    if (prevState.activeEndpoint !== activeEndpoint) {
      this.setLastUsedEndpoint(activeEndpoint);
    }
  }

  setEndpoint(tenantEndpoint: string) {
    this.setState({ activeEndpoint: tenantEndpoint });
  }

  render() {
    const { children } = this.props;
    const { activeEndpoint } = this.state;

    return (
      <UnicornTenantContext.Provider
        value={{
          activeEndpoint,
          setEndpoint: this.setEndpoint.bind(this),
        }}
      >
        {children}
      </UnicornTenantContext.Provider>
    );
  }
}

Provider.contextType = UnicornTenantContext;

export function useUnicornTenantContext() {
  const ctxValue = React.useContext(UnicornTenantContext);

  if (!ctxValue) {
    throw new Error(
      'UnicornTenantContext consumers must be rendered under a UnicornTenantContext.Provider',
    );
  }

  return ctxValue;
}

export const UnicornTenantProvider = Provider;
export const UnicornTenantConsumer = UnicornTenantContext.Consumer;
