import CustomToast from 'shared/components/CustomToast';
import React from 'react';
import { useMutation } from 'react-apollo';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

import {
  CancelEnterpriseSubscriptionResult,
  CancelEnterpriseSubscriptionVars,
  CANCEL_ENTERPRISE_SUBSCRIPTION_MUTATION,
} from 'enterprise/mutations';

interface Props {
  handleClose: () => void;
  isOpen: boolean;
  userId: string;
}

function CancelEnterpriseSubscriptionDialog(props: Props) {
  const { handleClose, isOpen, userId } = props;
  const [
    cancelEnterpriseSubscription,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation<
    CancelEnterpriseSubscriptionResult,
    CancelEnterpriseSubscriptionVars
  >(CANCEL_ENTERPRISE_SUBSCRIPTION_MUTATION);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to cancel this users enterprise subscription?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={mutationLoading}
          onClick={() => {
            cancelEnterpriseSubscription({ variables: { userId } })
              .then(() => {
                window.location.reload();
              })
              .catch(() => {});
          }}
          data-testid="confirm-button"
        >
          Confirm
        </Button>
        <Button onClick={handleClose} data-testid="abort-button">
          Cancel
        </Button>
      </DialogActions>
      {mutationError && <CustomToast message={mutationError.message} isOpen />}
    </Dialog>
  );
}

export default CancelEnterpriseSubscriptionDialog;
