import * as React from 'react';
import { RouteQueryProps } from './QueryRoute';
import { TableSortLabel } from '@material-ui/core';
import QueryString from './QueryString';
import { SortDirection, PagingDefinition } from './ListPage';

const orders: { [key: string]: SortDirection } = {
  asc: undefined,
  desc: 'asc',
  [undefined!]: 'desc',
};

const nextSortOrder = (order?: SortDirection): SortDirection => {
  return orders[order!];
};

interface InnerProps {
  sortKey: string;
  label: string;
  query: RouteQueryProps<PagingDefinition>;
}

class Inner extends React.Component<InnerProps> {
  onClick = () => {
    const { query, sortKey } = this.props;
    const { order: prev, sortBy } = query.params;
    const order = nextSortOrder(prev);
    // if sortKey !== sort it means we've selected a new column to sort by
    // we always want to sort by descending the first time we select a column
    if (sortKey !== sortBy) {
      query.update({ sortBy: sortKey, order: 'desc' });
    } else {
      query.update({ order, sortBy: order ? sortKey : undefined });
    }
  };

  render() {
    const { label, query, sortKey } = this.props;
    const { order, sortBy } = query.params;
    return (
      <TableSortLabel
        direction={order}
        active={sortBy === sortKey}
        onClick={this.onClick}
      >
        {label}
      </TableSortLabel>
    );
  }
}

interface TableSorterProps {
  sortKey: string;
  label: string;
}

export default class TableSorter extends React.Component<TableSorterProps> {
  render() {
    const { sortKey, label } = this.props;
    return (
      <QueryString<PagingDefinition>>
        {query => <Inner query={query} sortKey={sortKey} label={label} />}
      </QueryString>
    );
  }
}
