import * as React from 'react';
import UserTable from 'users/components/UserList';
import { QueryRouteComponentProps } from 'shared/components/QueryRoute';
import ListViewLayout from 'shared/layout/ListViewLayout';
import { ListViewSearchArea } from 'shared/layout';
import {
  Button,
  Input,
  createStyles,
  Theme,
  Select,
  MenuItem,
  OutlinedInput,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { createStyled } from 'shared/hocs';
import * as create from 'shared/components/Table';
import { Query } from 'react-apollo';
import { GlobalCircleLoader, ListPage } from 'shared/components';
import { GeneralSearchQueryResult } from 'users/queries';
import { ListPageRenderProps, SortDirection } from 'shared/components/ListPage';
import gql from 'graphql-tag';
import Team from 'fragments/Team';
import User from 'fragments/User';
import { getUsersCsv } from 'shared/csv';
import AddUserToOrganisationModal from './AddUserToOrganisationModal';

interface QueryDefinition {
  teamId?: string;
  search?: string;
  order: SortDirection;
  sort: string;
  perPage: number;
  page: number;
}

interface QueryVars {
  searchText?: string;
  groupId?: string;
  teamId?: string;
  page: number;
  limit: number;
  order: SortDirection;
  sortBy?: string;
}

interface State {
  value: string;
  addMemberModalOpen: boolean;
  teamId?: string;
}

interface QueryRes {
  users: {
    list: GeneralSearchQueryResult[];
    hasMore: boolean;
  };
}

const GET_TEAM_REFDATA_QUERY = gql`
  query getTeams($organisationId: String!) {
    getTeams(organisationId: $organisationId) {
      ...TeamBase
    }
  }
  ${Team.fragments.teamBase}
`;

interface TeamType {
  _id: string;
  name: string;
}

interface TeamQueryResult {
  getTeams: TeamType[];
}

export const GROUP_MEMBER_LIST_QUERY = gql`
  query users(
    $searchText: String
    $groupId: String
    $teamId: String
    $page: Int!
    $limit: Int!
    $order: String
    $sortBy: String
  ) {
    users(
      searchText: $searchText
      groupId: $groupId
      teamId: $teamId
      page: $page
      limit: $limit
      order: $order
      sortBy: $sortBy
    ) {
      list {
        ...UserBase
        organisationAdmin
        profile {
          firstName
          lastName
          jobTitle
        }
        customer {
          id
        }
        group {
          _id
          ownerId
        }
        disabled
      }
      hasMore
    }
  }
  ${User.fragments.userBase}
`;

const colWidths = ['30%', '15%', '15%', '15%', '15%', '15%'];

interface SearchQueryOptions {
  search?: string;
  teamId?: string;
}

type Props = QueryRouteComponentProps<QueryDefinition> &
  ListPageRenderProps<SearchQueryOptions>;

class Inner extends React.Component<Props, State> {
  state = { value: '', teamId: '', addMemberModalOpen: false };

  componentDidUpdate(pp: Props, ps: State) {
    const prev = pp.query.params.search || '';
    const { search = '' } = this.props.query.params;
    if (prev && prev !== search) {
      this.setState({ value: search });
    }
  }

  componentDidMount() {
    const { search = '', teamId = '' } = this.props.searchValues;
    this.setState({ teamId, value: search });
  }

  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      this.search();
    }
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    this.setState({ value });
  };

  search = () => {
    const { value, teamId } = this.state;
    const { updateSearch, clearSearch } = this.props;
    const query: SearchQueryOptions = {};

    if (value || teamId) {
      if (value) {
        query.search = value;
      }

      if (teamId) {
        query.teamId = teamId;
      }

      updateSearch(query);
    } else {
      clearSearch();
    }
  };

  render() {
    const {
      paging,
      searchValues,
      changeRowsPerPage,
      handleChangePage,
      match,
    } = this.props;
    const { search: searchText, teamId } = searchValues;
    const { order, sortBy, page, perPage: limit } = paging;

    const headers = [
      create.header('Username', 'username'),
      create.header('First Name', 'firstName'),
      create.header('Last Name', 'lastName'),
      create.header('User Type', 'userType'),
      create.header('Status'),
      create.header('Actions'),
    ];

    const { groupId } = match.params;

    return (
      <Style>
        {({ classes }) => (
          <ListViewLayout>
            <ListViewSearchArea>
              <Input
                fullWidth
                disableUnderline
                value={this.state.value}
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
                className={classes.searchInput}
                placeholder="Search by last name or username..."
              />

              <Query<TeamQueryResult, { organisationId: string }>
                query={GET_TEAM_REFDATA_QUERY}
                fetchPolicy="cache-and-network"
                variables={{ organisationId: groupId }}
              >
                {({ error, loading, data }) => {
                  if (error) return null;
                  if (loading && (!data || !data.getTeams)) return null;

                  const { getTeams } = data!;
                  return (
                    <Select
                      input={
                        <OutlinedInput
                          labelWidth={4}
                          className={classes.searchInput}
                        />
                      }
                      displayEmpty
                      value={this.state.teamId}
                      onChange={(e) => {
                        this.setState({ teamId: e.target.value });
                      }}
                    >
                      <MenuItem value="">
                        <em>Filter by team...</em>
                      </MenuItem>
                      {getTeams.map((x) => {
                        return (
                          <MenuItem key={x._id} value={x._id}>
                            {x.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  );
                }}
              </Query>

              <Button onClick={this.search} variant="contained" color="primary">
                <SearchIcon />
              </Button>
            </ListViewSearchArea>

            <Query<QueryRes, QueryVars>
              query={GROUP_MEMBER_LIST_QUERY}
              fetchPolicy="cache-and-network"
              variables={{
                page,
                order,
                limit,
                sortBy,
                groupId,
                teamId,
                searchText,
              }}
            >
              {({ data, error, loading, refetch }) => {
                if (error) return <span>{error.message}</span>;
                if (loading) return <GlobalCircleLoader show />;
                return (
                  <>
                    <div>
                      <Button
                        className={classes.csvBar}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          const blob = new Blob(
                            [getUsersCsv(data!.users.list)],
                            { type: 'text/plain;charset=utf-8' },
                          );
                          saveAs(blob, `org-members-${groupId}-partial.csv`);
                        }}
                      >
                        Download CSV - {data!.users.list.length} Member Results
                      </Button>
                      <Button
                        onClick={() =>
                          this.setState({ addMemberModalOpen: true })
                        }
                        variant="contained"
                        color="primary"
                      >
                        Add member
                      </Button>
                    </div>
                    <hr />
                    <UserTable
                      page={page}
                      perPage={limit}
                      colWidths={colWidths}
                      headers={headers}
                      refetch={refetch}
                      users={data!.users.list}
                      hasMore={data!.users.hasMore}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={changeRowsPerPage}
                    />
                    <GlobalCircleLoader show={loading} />
                    <AddUserToOrganisationModal
                      groupId={groupId}
                      isOpen={this.state.addMemberModalOpen}
                      onClose={() =>
                        this.setState({ addMemberModalOpen: false })
                      }
                      onSuccess={() => {
                        refetch();
                        this.setState({ addMemberModalOpen: false });
                      }}
                    />
                  </>
                );
              }}
            </Query>
          </ListViewLayout>
        )}
      </Style>
    );
  }
}

interface SearchVars {
  search?: string;
  teamId?: string;
}

const sortKeys = ['firstName', 'lastName', 'created', 'balance'];
const MembersList: React.SFC<Props> = (props) => {
  const searchKeys: Array<keyof SearchVars> = ['search', 'teamId'];
  return (
    <ListPage<SearchVars>
      sortKeys={sortKeys}
      searchKeys={searchKeys}
      defaultSortBy="created"
    >
      {(query) => <Inner {...query} {...props} />}
    </ListPage>
  );
};

export default MembersList;

const Style = createStyled((theme: Theme) =>
  createStyles({
    searchInput: {
      borderRadius: theme.shape.borderRadius,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.grey[500],
      padding: theme.spacing.unit,
    },
    csvBar: {
      float: 'right',
    },
  }),
);
