import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

interface Props {
  cancelSubscription: () => void;
  handleClose: () => void;
}
export function CancelSubscriptionDialog(props: Props) {
  const { cancelSubscription, handleClose } = props;
  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to cancel this users subscription?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelSubscription} data-testid="confirm-button">
          Confirm
        </Button>
        <Button onClick={handleClose} data-testid="abort-button">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
