import React, { SFC } from 'react';

interface Props {
  chance: number;
}

const UnicornTriviaItem: SFC<Props> = ({ chance, children }) => {
  if (Math.random() > chance) return null;
  return <React.Fragment>{children}</React.Fragment>;
};

export default UnicornTriviaItem;
