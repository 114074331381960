import * as React from 'react';
import { Query } from 'react-apollo';
import UserList from 'users/components/UserList';
import { QueryRouteComponentProps } from 'shared/components/QueryRoute';
import ListViewLayout from 'shared/layout/ListViewLayout';
import { ListViewSearchArea } from 'shared/layout';
import { Button, Input, createStyles, Theme } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/PlusOne';
import { createStyled } from 'shared/hocs';
import * as create from 'shared/components/Table';
import { GlobalCircleLoader, ListPage } from 'shared/components';
import { GENERAL_SEARCH_QUERY, GeneralSearchQueryResult } from 'users/queries';
import { ListPageRenderProps, SortDirection } from 'shared/components/ListPage';
import { UserCreateModal } from 'users/components/UserCreateModal';
import CustomToast from 'shared/components/CustomToast';
import { SearchTips } from 'shared/components/SearchTips';

export interface QueryDefinition {
  search?: string;
  order: SortDirection;
  sort: string;
  perPage: number;
  page: number;
}

export interface QueryVars {
  searchText?: string;
  page: number;
  limit: number;
  order: SortDirection;
  sortBy?: string;
}

interface State {
  value: string;
  isInCreateMode: boolean;
  notification: string;
}

export interface QueryRes {
  users: {
    list: GeneralSearchQueryResult[];
    hasMore: boolean;
  };
}

type Props = QueryRouteComponentProps<QueryDefinition> &
  ListPageRenderProps<{ search?: string }>;

const colWidths = ['25%', '10%', '10%', '10%', '10%', '15%', '20%'];

class Inner extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: '',
      isInCreateMode: false,
      notification: '',
    };
  }

  componentDidUpdate(pp: Props, ps: State) {
    const prev = pp.query.params.search || '';
    const { search = '' } = this.props.query.params;
    if (prev && prev !== search) {
      this.setState({ value: search });
    }
  }

  componentDidMount() {
    const { search = '' } = this.props.searchValues;
    this.setState({ value: search });
  }

  createUser = () => this.setState({ isInCreateMode: true });

  closeCreateDrawer = () => this.setState({ isInCreateMode: false });

  onCreate = () => {
    this.setState({ notification: 'Successfully created user' });
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      this.search();
    }
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    this.setState({ value });
  };

  search = () => {
    const { value } = this.state;
    const { updateSearch, clearSearch } = this.props;
    if (value) {
      updateSearch({ search: value });
    } else {
      clearSearch();
    }
  };

  render() {
    const {
      paging,
      searchValues,
      changeRowsPerPage,
      handleChangePage,
    } = this.props;
    const { search: searchText } = searchValues;
    const { order, sortBy, page, perPage: limit } = paging;

    const headers = [
      create.header('Username', 'username'),
      create.header('First Name', 'firstName'),
      create.header('Last Name', 'lastName'),
      create.header('User Type', 'userType'),
      create.header('Current balance', 'balance'),
      create.header('Actions'),
    ];

    return (
      <Style>
        {({ classes }) => (
          <ListViewLayout>
            <SearchTips />
            <ListViewSearchArea>
              <Input
                fullWidth
                placeholder="Search by last name or username..."
                value={this.state.value}
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
                disableUnderline
                className={classes.searchInput}
              />
              <Button onClick={this.search} variant="contained" color="primary">
                <SearchIcon />
              </Button>
              <Button
                style={{ marginLeft: '1%' }}
                onClick={this.createUser}
                variant="contained"
                color="primary"
              >
                <AddIcon />
              </Button>
            </ListViewSearchArea>
            <Query<QueryRes, QueryVars>
              query={GENERAL_SEARCH_QUERY}
              fetchPolicy="cache-and-network"
              variables={{
                page,
                order,
                limit,
                sortBy,
                searchText,
              }}
            >
              {({ data, error, loading, refetch }) => {
                if (error) return null;
                if (loading && (!data || !data.users))
                  return <GlobalCircleLoader show />;

                return (
                  <UserList
                    refetch={refetch}
                    colWidths={colWidths}
                    page={page}
                    perPage={limit}
                    headers={headers}
                    users={data!.users.list}
                    hasMore={data!.users.hasMore}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={changeRowsPerPage}
                  />
                );
              }}
            </Query>
            <UserCreateModal
              onCreate={this.onCreate}
              onClose={this.closeCreateDrawer}
              isOpen={this.state.isInCreateMode}
              cancel={this.closeCreateDrawer}
            />
            {this.state.notification && (
              <CustomToast message={this.state.notification} isOpen />
            )}
          </ListViewLayout>
        )}
      </Style>
    );
  }
}

interface SearchVars {
  search?: string;
}

const sortKeys = ['firstName', 'lastName', 'created', 'balance'];
const UserListPage: React.SFC<Props> = (props) => {
  const searchKeys: Array<keyof SearchVars> = ['search'];
  return (
    <ListPage<SearchVars>
      sortKeys={sortKeys}
      searchKeys={searchKeys}
      defaultSortBy="created"
    >
      {(query) => <Inner {...query} {...props} />}
    </ListPage>
  );
};

export default UserListPage;

const Style = createStyled((theme: Theme) =>
  createStyles({
    searchInput: {
      borderRadius: theme.shape.borderRadius,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.grey[500],
      padding: theme.spacing.unit,
    },
  }),
);
