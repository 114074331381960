import { AuthData } from './types';
import { isString } from 'shared/utils';

export const tokenExists = (
  authData: AuthData | null,
): authData is AuthData => {
  return !!authData;
};

export const tokenIsValid = (
  authData: AuthData | null,
): authData is AuthData => {
  return (
    tokenExists(authData) &&
    isString(authData.accessToken) &&
    !tokenIsExpired(authData)
  );
};

export const tokenIsExpired = (authData: AuthData) => {
  return authData.expiresAt < Date.now();
};

export const getExpiry = (expiresIn: number) => {
  return expiresIn * 1000 + Date.now();
};
