import React, { useState } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core';
import EnterpriseUpdateSeatsModal from './EnterpriseUpdateSeatsModal';
import EnterpriseUpdatePlanDetailsModal from './EnterpriseUpdatePlanDetailsModal';
import EnterpriseCancelSubscriptionModal from './EnterpriseCancelSubscriptionModal';

enum EnterpriseAccountActionModals {
  UpdateSeats = 'UpdateSeats',
  UpdatePlanDetails = 'UpdatePlanDetails',
  CancelEnterpriseSubscription = 'CancelEnterpriseSubscription',
}

interface Props {
  userId: string;
}

function EnterpriseAccountActionsMenu(
  props: Props & WithStyles<typeof styles>,
) {
  const { userId, classes } = props;
  const [menuOpen, setMenuOpen] = useState<(EventTarget & HTMLElement) | null>(
    null,
  );

  const [
    modalOpen,
    setModalOpen,
  ] = useState<EnterpriseAccountActionModals | null>(null);

  return (
    <main className={classes.layout}>
      <Button
        aria-controls="enterprise-account-actions-menu"
        variant="outlined"
        onClick={({ currentTarget }) => setMenuOpen(currentTarget)}
      >
        • • •
      </Button>

      <Menu
        id="enterprise-account-actions-menu"
        keepMounted
        anchorEl={menuOpen}
        open={!!menuOpen}
        onClose={() => setMenuOpen(null)}
      >
        <MenuItem
          onClick={() => {
            setMenuOpen(null);
            setModalOpen(EnterpriseAccountActionModals.UpdatePlanDetails);
          }}
        >
          Update account details{' '}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMenuOpen(null);
            setModalOpen(EnterpriseAccountActionModals.UpdateSeats);
          }}
        >
          Update seats
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMenuOpen(null);
            setModalOpen(
              EnterpriseAccountActionModals.CancelEnterpriseSubscription,
            );
          }}
        >
          Cancel Enterprise Subscription
        </MenuItem>
      </Menu>

      <EnterpriseUpdateSeatsModal
        isOpen={modalOpen === EnterpriseAccountActionModals.UpdateSeats}
        handleClose={() => setModalOpen(null)}
        userId={userId}
      />

      <EnterpriseUpdatePlanDetailsModal
        isOpen={modalOpen === EnterpriseAccountActionModals.UpdatePlanDetails}
        handleClose={() => setModalOpen(null)}
        userId={userId}
      />

      <EnterpriseCancelSubscriptionModal
        isOpen={
          modalOpen ===
          EnterpriseAccountActionModals.CancelEnterpriseSubscription
        }
        handleClose={() => setModalOpen(null)}
        userId={userId}
      />
    </main>
  );
}

const styles = (theme: Theme) => {
  return createStyles({
    layout: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  });
};

export default withStyles(styles)(EnterpriseAccountActionsMenu);
