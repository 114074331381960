import * as React from 'react';
import { GeneralSearchQueryResult } from 'users/queries';
import { Table, TableRow, Pagination } from 'shared/components';
import UserListItem from './UserListItem';
import { TableCellItem } from 'shared/components/TableRow';

interface Props {
  refetch: () => Promise<any>;
  colWidths: string[];
  headers: TableCellItem[];
  users: GeneralSearchQueryResult[];
  page: number;
  perPage: number;
  hasMore: boolean;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void;
  onChangeRowsPerPage?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
}

class UserTable extends React.Component<Props> {
  render() {
    const {
      users = [],
      refetch,
      page,
      hasMore,
      headers,
      perPage,
      onChangePage,
      colWidths = [],
      onChangeRowsPerPage,
    } = this.props;
    return (
      <>
        <Table headers={headers} colWidths={colWidths}>
          {users.map((user) => (
            <TableRow<GeneralSearchQueryResult>
              key={user._id}
              item={user}
              refetch={refetch}
              component={UserListItem}
            />
          ))}
        </Table>
        <Pagination
          page={page}
          perPage={perPage}
          hasMore={hasMore}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </>
    );
  }
}

export default UserTable;
