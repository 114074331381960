import * as React from 'react';
import {
  TableRow,
  TableCell,
  Button,
  IconButton,
  Theme,
  createStyles,
} from '@material-ui/core';
import Timelapse from '@material-ui/icons/Timelapse';
import Security from '@material-ui/icons/Security';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { GeneralSearchQueryResult } from 'users/queries';
import { StripeCustomerButton, FormatDuration } from 'shared/components';
import { createStyled } from 'shared/hocs';
import { ModalTrigger } from 'contexts/ModalTrigger';
import { Mutation } from 'react-apollo';
import { REMOVE_MEMBER_FROM_GROUP } from 'users/mutations';

interface Props {
  refetch: () => Promise<void>;
  userId: string;
  item: GeneralSearchQueryResult;
  onRowClick: (
    e: React.MouseEvent<HTMLTableRowElement>,
    item: GeneralSearchQueryResult,
  ) => void;
}

function UserListItem(props: Props) {
  const { item } = props;
  const customer = item.customer || {};
  const { id: customerId } = customer;

  const groupId = item.group ? item.group._id : null;
  const isOrganisationOwner = item.group && item.group.ownerId === item._id;
  const isOrganisationAdmin = item.organisationAdmin;

  return (
    <Styled>
      {({ classes }) => (
        <TableRow>
          <TableCell className={classes.tableCellPadding}>
            <StripeCustomerButton customerId={customerId} />
            <Link
              to={`/users/${item._id}/details`}
              title="Details"
              className={classnames(classes.link)}
            >
              {item.username}
            </Link>
          </TableCell>
          <TableCell>{item.profile.firstName}</TableCell>
          <TableCell>{item.profile.lastName}</TableCell>
          <TableCell className={classnames(classes.capitalise)}>
            {isOrganisationOwner && (
              <Tooltip
                title="Owner"
                placement="top"
                classes={{
                  tooltip: classes.lightTooltip,
                  popper: classes.tooltipPopper,
                  tooltipPlacementTop: classes.tooltipPlacementTop,
                }}
              >
                <span className={classnames(classes.adminIcon)}>
                  <Security />
                </span>
              </Tooltip>
            )}
            {isOrganisationAdmin && (
              <Tooltip
                title="Organisation Admin"
                placement="top"
                classes={{
                  tooltip: classes.lightTooltip,
                  popper: classes.tooltipPopper,
                  tooltipPlacementTop: classes.tooltipPlacementTop,
                }}
              >
                <span className={classnames(classes.adminIcon)}>
                  <SupervisorAccountIcon />
                </span>
              </Tooltip>
            )}
            <span>{item.userType}</span>
          </TableCell>
          <TableCell>{item.disabled ? 'Inactive' : 'Active'}</TableCell>
          {item.allowance && (
            <TableCell>
              <ModalTrigger
                modal="AllocateMinutesModal"
                modalProps={{ userId: item._id }}
              >
                {({ openModal }) => (
                  <IconButton onClick={openModal}>
                    <Timelapse />
                  </IconButton>
                )}
              </ModalTrigger>

              <span style={{ paddingLeft: 3 }}>
                <FormatDuration duration={item.allowance.balance} />
              </span>
            </TableCell>
          )}
          <TableCell>
            {groupId && (
              <Mutation
                mutation={REMOVE_MEMBER_FROM_GROUP}
                onCompleted={props.refetch}
                variables={{
                  groupId,
                  memberId: item._id,
                }}
              >
                {(removeMember) => {
                  return (
                    <ModalTrigger
                      modal="ConfirmDialog"
                      modalProps={{
                        onConfirm: removeMember,
                        title:
                          'Are you sure you want to remove this user from from the organisation?',
                      }}
                    >
                      {({ openModal }) => (
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={openModal}
                        >
                          Remove from organisation
                        </Button>
                      )}
                    </ModalTrigger>
                  );
                }}
              </Mutation>
            )}
          </TableCell>
        </TableRow>
      )}
    </Styled>
  );
}

const Styled = createStyled((theme: Theme) =>
  createStyles({
    width: {
      width: '100%',
    },
    capitalise: {
      textTransform: 'capitalize',
    },
    tableCellPadding: {
      padding: '4px 0px 4px 24px',
    },
    adminIcon: {
      display: 'inline-flex',
      flex: '0 0 auto',
      verticalAlign: 'middle',
      paddingRight: 3,
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&visited': {
        textDecoration: 'none',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    tooltipPlacementTop: {
      margin: '0 0 3px 0',
    },
    tooltipPopper: {
      '&[x-placement*="top"] ': {
        opacity: 1,
      },
    },
    lightTooltip: {
      backgroundColor: '#aaaaaa',
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
    textOverflow: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

export default UserListItem;
