import * as React from 'react';
import { PropsOf, ComponentHash } from 'shared/types';

interface Modal<TShape extends ComponentHash> {
  props: PropsOf<TShape[keyof TShape]>;
  modal: keyof TShape;
  getProps: () => any;
}

interface State<TShape extends ComponentHash> {
  modals: Array<Modal<TShape>>;
}

const initialState: State<any> = {
  modals: [],
};

export interface ModalContextState<TShape extends ComponentHash> {
  modals: Array<Modal<TShape>>;
  openModal: <K extends keyof TShape>(
    modal: K,
    props: PropsOf<TShape[K]>,
  ) => void;
  closeModal: () => void;
}

function createModalContext<TShape extends ComponentHash>() {
  const Context = React.createContext<ModalContextState<TShape>>({
    modals: [],
    openModal: () => {},
    closeModal: () => {},
  });

  class Provider extends React.Component<{}, State<TShape>> {
    state: State<TShape> = initialState;

    openModal = <K extends keyof TShape>(
      modal: K,
      props: PropsOf<TShape[K]>,
    ) => {
      this.setState(({ modals }) => {
        return {
          modals: [...modals, { modal, props } as any],
        };
      });
    };

    closeModal = () => {
      this.setState(({ modals }) => {
        return {
          modals: modals.slice(0, modals.length - 1),
        };
      });
    };

    render() {
      const { children } = this.props;
      const { modals } = this.state;
      return (
        <Context.Provider
          value={{
            modals,
            openModal: this.openModal,
            closeModal: this.closeModal,
          }}
        >
          {children}
        </Context.Provider>
      );
    }
  }

  return {
    Provider,
    Consumer: Context.Consumer,
  };
}

export default createModalContext;
