import * as React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  createStyles,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import classnames from 'classnames';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { DrawerItem } from 'pages/drawerLinks';
import { createStyled } from 'shared/hocs';

type Props = DrawerItem & RouteComponentProps;

const Drawer: React.SFC<Props> = props => {
  const { location } = props;
  const isActive = props.path === location.pathname;
  return (
    <Styled>
      {({ classes }) => {
        return (
          <Tooltip title={props.label} placement="right">
            <Link
              to={props.path}
              className={classes.link}
              aria-label={props.ariaLabel}
            >
              <ListItem
                className={classnames(classes.item, isActive && classes.active)}
                button
                tabIndex={-1}
              >
                <ListItemIcon
                  className={classnames(
                    classes.icon,
                    isActive && classes.active,
                  )}
                >
                  {props.icon}
                </ListItemIcon>
                <ListItemText>
                  <span
                    className={classnames(
                      classes.label,
                      isActive && classes.active,
                    )}
                  >
                    {props.label}
                  </span>
                </ListItemText>
              </ListItem>
            </Link>
          </Tooltip>
        );
      }}
    </Styled>
  );
};

const Styled = createStyled((theme: Theme) =>
  createStyles({
    active: {
      color: '#fff !important',
    },
    item: {
      color: '#ffffff66',
      paddingLeft: 24,
    },
    icon: {
      color: '#ffffff66',
    },
    label: {
      color: '#ffffff66',
    },
    link: {
      textDecoration: 'none',
    },
  }),
);

export default withRouter(Drawer);
