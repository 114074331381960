import * as React from 'react';
import * as create from 'shared/components/Table';
import { Table } from 'shared/components';

import {
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { Log, LogArtefact } from './models';

const colWidths = ['10%', '20%', '30%'];
const artefactHeaders = [
  create.header('ID'),
  create.header('Type'),
  create.header('Status'),
];

interface Props {
  log: Log;
  handleClose: () => void;
}

function LogDialog(props: Props) {
  const { handleClose, log } = props;

  return (
    <Dialog open={true} onClose={handleClose} maxWidth="lg">
      <DialogTitle disableTypography>
        <h2>Request Details</h2>
      </DialogTitle>
      <DialogContent>
        <h3>Executed by: {log.executedBy.username}</h3>
        <p>
          Request type:{' '}
          {log.request.linkToRequest ? (
            <a
              href={log.request.linkToRequest}
              target="_blank"
              rel="noopener noreferrer"
              data-testid="log-request-link"
            >
              {log.request.type}
            </a>
          ) : (
            log.request.type
          )}
        </p>
      </DialogContent>
      <DialogContent>
        <h3>Outcomes</h3>
        <Table headers={artefactHeaders} colWidths={colWidths}>
          {log.outcome.artefacts.map((artefact: LogArtefact) => (
            <TableRow key={artefact.id} data-testid="log-artefact-row">
              <TableCell>{artefact.id}</TableCell>
              <TableCell>{artefact.type}</TableCell>
              <TableCell>{artefact.state}</TableCell>
            </TableRow>
          ))}
        </Table>
      </DialogContent>
    </Dialog>
  );
}

export default LogDialog;
