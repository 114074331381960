import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Step,
  StepContent,
  StepLabel,
  TextField,
} from '@material-ui/core';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

interface Props {
  active: boolean;
  onNext: () => void;
  organisationId: string;
}

interface QueryVars {
  organisationId: string;
}

interface QueryRes {
  group: {
    _id: string;
    metadata: {
      name: string;
    };
  };
}

export const ORGANISATION_NAME_QUERY = gql`
  query organisationNameQuery($organisationId: String!) {
    group(groupId: $organisationId) {
      _id
      metadata {
        name
      }
    }
  }
`;

function StepThree(props: Props) {
  const { onNext, organisationId } = props;
  const { data, error, loading } = useQuery<QueryRes, QueryVars>(
    ORGANISATION_NAME_QUERY,
    { variables: { organisationId } },
  );

  const [orgName, setOrgName] = useState('');

  if (error) {
    return null;
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Step {...props}>
      <StepLabel data-testid="step-label">
        Enter the name of organisation
      </StepLabel>
      <StepContent {...props} data-testid="step-content">
        <span>{data!.group.metadata.name}</span>
        <br />
        <TextField
          id="step-three-input"
          name="name"
          label="Confirm organisation"
          data-testid="step-three-input"
          value={orgName}
          onPaste={e => {
            e.preventDefault();
          }}
          onChange={e => {
            setOrgName(e.target.value);
          }}
        />

        <br />
        <br />
        <Button
          variant="contained"
          color="primary"
          disabled={orgName !== data!.group.metadata.name}
          onClick={onNext}
          data-testid={'forward-button-3'}
        >
          Next
        </Button>
      </StepContent>
    </Step>
  );
}

export default StepThree;
