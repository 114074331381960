import * as React from 'react';
import * as auth from 'shared/auth';
import { tokenIsValid } from '../auth/utils';
import { withApollo, WithApolloClient } from 'react-apollo';

export interface AuthContextState {
  isAuthorised: boolean;
  profile: auth.UserProfile | null;
  authData: auth.AuthData | null;
  signingIn: boolean;
  login: () => void;
  logout: () => void;
  handleAuthResponse: (hash: string) => void;
}

interface State {
  signingIn: boolean;
  authData: auth.AuthData | null;
  profile: auth.UserProfile | null;
}

interface Props {
  children: React.ReactNode;
}

const defaultValues: AuthContextState = {
  authData: null,
  signingIn: false,
  profile: null,
  isAuthorised: false,
  login: () => {},
  logout: () => {},
  handleAuthResponse: () => {},
};

const UserContext = React.createContext(defaultValues);

class Provider extends React.Component<WithApolloClient<Props>, State> {
  state = {
    signingIn: false,
    profile: auth.getProfile(),
    authData: auth.getAuthData(),
  };

  isAuthorised = (): boolean => {
    const { authData, profile } = this.state;
    return !!profile && tokenIsValid(authData);
  };

  handleAuthResponse = async (hash: string) => {
    this.setState({ signingIn: true });
    try {
      const authData = await auth.handleAuthResponse(hash);
      const profile = await auth.getUserProfile();
      this.setState({ authData, profile, signingIn: false });
    } catch (e) {
      auth.logout();
      this.setState({ authData: null, profile: null, signingIn: false });
    }
  };

  logout = () => {
    auth.logout();
    this.props.client.clearStore();
  }

  render() {
    return (
      <UserContext.Provider
        value={{
          ...this.state,
          login: auth.login,
          logout: this.logout,
          isAuthorised: this.isAuthorised(),
          handleAuthResponse: this.handleAuthResponse,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export const UserProvider = withApollo(Provider);

export const UserConsumer = UserContext.Consumer;
