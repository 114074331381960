import * as React from 'react';
import {
  Theme,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
} from '@material-ui/core';
import { session } from 'shared/utils/storage';

const styles = (theme: Theme) =>
  createStyles({
    card: {
      minWidth: 275,
      maxWidth: 375,
    },
  });

interface Props extends WithStyles<typeof styles> {
  redirectUrl?: string;
  login: () => void;
}

class Unauthorised extends React.Component<Props> {
  componentDidMount() {
    const { redirectUrl } = this.props;
    if (redirectUrl) {
      session.setItem('redirectUrl', redirectUrl);
    }
  }

  componentWillUnmount() {
    session.unsetItem('redirectUrl');
  }

  render() {
    const { classes, login } = this.props;

    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h3">
            Unauthorised
          </Typography>
          <Typography component="p">
            You must be logged in to view this page
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={login}>
            Log in
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(Unauthorised);
