import * as React from 'react';
import {
  Theme,
  Grid,
  Paper,
  WithStyles,
  TextField,
  Typography,
  withStyles,
  createStyles,
  Button as Btn,
  List,
  ListItem,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { Mutation, Query } from 'react-apollo';
import { ModalTrigger } from 'contexts/ModalTrigger';
import {
  OrganisationQueryResult,
  ORGANISATION_QUERY,
} from 'organisations/queries';
import { REMOVE_DOMAINS } from 'organisations/mutations';

interface MutationVariables {
  groupId: string;
  domains: string[];
}

interface Data {
  groupId: string;
}

type InnerProps = { group: OrganisationQueryResult } & WithStyles<
  typeof styles
>;

// https://github.com/mui-org/material-ui/issues/11968
const Button: React.ComponentType<any> = Btn;

class Form extends React.Component<InnerProps> {
  state = {
    checked: [''],
    groupSettings: this.props.group.settings || {
      allowedSharingDomains: [''],
    },
  };

  handleToggle = (value: string) => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  };

  handleChange = (e: any) => {
    this.setState({
      groupSettings: {
        allowedSharingDomains: e.target.value.split(/\n/),
      },
    });
  };

  render() {
    const { group, classes } = this.props;

    const { groupSettings, checked } = this.state;
    const { allowedSharingDomains } = groupSettings;

    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography className={classes.title} component="h1" variant="h5">
            Team Details
          </Typography>
          <div className={classes.buttons}>
            <Mutation<Data, MutationVariables> mutation={REMOVE_DOMAINS}>
              {mutation => (
                <ModalTrigger<'ConfirmDialog'>
                  modal="ConfirmDialog"
                  modalProps={{
                    title: 'Are you sure?',
                    onConfirm: () => {
                      mutation({
                        variables: {
                          domains: checked,
                          groupId: group._id,
                        },
                      });
                    },
                  }}
                >
                  {({ openModal }) => {
                    return (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={openModal}
                        className={classes.button}
                      >
                        Delete
                      </Button>
                    );
                  }}
                </ModalTrigger>
              )}
            </Mutation>
            <ModalTrigger
              modal="AddDomainsModal"
              modalProps={{ groupId: group._id }}
            >
              {({ openModal }) => (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={openModal}
                  className={classes.button}
                >
                  Add
                </Button>
              )}
            </ModalTrigger>
          </div>
          <Grid container spacing={24} xs={12} direction="column">
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="teamName"
                name="teamName"
                label="Team name"
                value={group.metadata.name}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <List>
                {allowedSharingDomains &&
                  allowedSharingDomains.map(value => (
                    <ListItem
                      key={value}
                      role={undefined}
                      dense
                      button
                      onClick={() => this.handleToggle(value)}
                    >
                      <Checkbox
                        checked={this.state.checked.includes(value)}
                        tabIndex={-1}
                        disableRipple
                      />
                      <ListItemText primary={`${value}`} />
                    </ListItem>
                  ))}
              </List>
            </Grid>
          </Grid>
        </Paper>
      </main>
    );
  }
}

const styles = (theme: Theme) => {
  return createStyles({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing.unit * 3,
      marginLeft: theme.spacing.unit,
    },
    title: {
      marginBottom: theme.spacing.unit * 3,
    },
  });
};

const EnhancedForm = withStyles(styles)(Form);

interface Props {
  groupId: string;
}

function DomainsForm(props: Props) {
  return (
    <Query<{ group: OrganisationQueryResult }, { groupId: string }>
      query={ORGANISATION_QUERY}
      fetchPolicy="cache-and-network"
      variables={{ groupId: props.groupId }}
    >
      {({ data, loading, error }) => {
        if (error) return null;

        if (loading && (!data || !data.group)) return null;
        return <EnhancedForm group={data!.group} />;
      }}
    </Query>
  );
}

export default DomainsForm;
