import * as React from 'react';
import { createStyled } from 'shared/hocs';
import { Theme, createStyles } from '@material-ui/core';

const Styled = createStyled((theme: Theme) =>
  createStyles({
    searchArea: {
      display: 'flex',
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 5,
      '& div:first-child': {
        marginRight: 10,
      },
    },
  }),
);

const ListViewSearchArea: React.SFC<{}> = ({ children }) => (
  <Styled>
    {({ classes }) => <div className={classes.searchArea}>{children}</div>}
  </Styled>
);

export default ListViewSearchArea;
